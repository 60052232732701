import { Link, NavLink, useNavigate } from "react-router-dom";
import React, { useState, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import UserSignup from "../pages/auth/UserSignup";
import { AuthContext } from "../context/AuthContext";
import MasterHeader from "./MasterHeader";
import SignUpPopUp from "./SignUpPopUp";
import { GoogleOAuthProvider } from '@react-oauth/google';

const Header = () => {
  const {
    setLanguage,
    language,
    user,
    Logout,
    isUserSignupModalOpen,
    setIsUserSignupModalOpen,
    setOpenLoginModal,
  } = useContext(AuthContext);
  const translations = require(`../lang/common/common_${language}.json`);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMemberDropdownOpen, setIsMemberDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const toggleProfileDropdown = () =>
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  const [showPopUp, setShowPopUp] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toggleLangDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [timeoutId, setTimeoutId] = useState(null);
  const dropdownRef = useRef(null);

  const handleOpenPopUp = () => {
    setShowPopUp(true); // Set showPopUp state to true when button is clicked
  };

  const openModal = () => {
    setIsUserSignupModalOpen(true);
  };

  const closeModal = () => {
    setIsUserSignupModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Clear timeout if the mouse re-enters the dropdown area
  const handleMouseEnterDropdown = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsDropdownOpen(true);
  };

  // Clear timeout if the mouse re-enters the parent menu
  const handleMouseEnterMenu = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsDropdownOpen(true);
  };

  // Handle mouse leave on dropdown and menu
  const handleMouseLeave = () => {
    const id = setTimeout(() => setIsDropdownOpen(false), 200);
    setTimeoutId(id);
  };

  const toggleMemberDropdown = () => {
    setIsMemberDropdownOpen(!isMemberDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleLanguageChange = (language) => {
    setLanguage(language);
  };

  return (
    <>
      {user?.role === "member" || user?.role === "merchant" ? (
        <MasterHeader />
      ) : (
        <header
          style={{ zIndex: "999" }}
          className={`fixed top-0 left-0 w-full z-10 py-6 px-5 md:px-5 lg:px-5 bg-white text-black drop-shadow`}
        >
          <div className="container flex items-center justify-between mx-auto ">
            <h1 className="text-2xl font-semibold">
              <Link to="/">
                <img
                  src="/assets/logo.png"
                  alt="Reward Club"
                  className="w-auto h-12"
                />
              </Link>
            </h1>

            <nav className="hidden md:flex navbar-links">
              {!user && (
                <>
                  <ul className="flex items-center space-x-10">
                    <li>
                      <NavLink to="/home">{translations.header.home}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/discount">
                        {translations.header.discount}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/partner">
                        {translations.header.becomePartner}
                      </NavLink>
                    </li>
                    {/* Dropdown */}
                    <li className="relative">
                      <button
                        onMouseEnter={handleMouseEnterMenu}
                        onMouseLeave={handleMouseLeave}
                        className="hover:text-gray-400 focus:outline-none navbar-links-button"
                      >
                        {translations.header.giveaways}
                        <i className={`fa fa-chevron-down ml-1 h-3 w-3 absolute text-[12px] top-[6px] ${isDropdownOpen ? "transform rotate-180" : ""}`}></i>
                      </button>
                      {isDropdownOpen && (
                        <div
                          ref={dropdownRef}
                          className="absolute left-0 z-10 w-56 mt-2 bg-white rounded-lg shadow-lg top-full navbar-dropdown-container"
                          onMouseEnter={handleMouseEnterDropdown}
                          onMouseLeave={handleMouseLeave}
                        >
                          <ul className="py-2">
                            <li>
                              <NavLink
                                to="/upcoming-members-giveaways"
                                className="block p-3 text-gray-800"
                              >
                                {translations.header.upcomingMembersGiveaways}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/member-draw-giveaways"
                                className="block p-3 text-gray-800"
                              >
                                {translations.header.memberDrawWinners}
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                    </li>
                    {!user && (
                      <li>
                        <NavLink to="/about">
                          {translations.header.aboutUs}
                        </NavLink>
                      </li>
                    )}
                    <li>
                        <NavLink target="_blank" to="https://store.rewardclub.my/">
                          {translations.header.store}
                        </NavLink>
                    </li>
                    <li>
                      {user ? (
                        <>
                          <button
                            onClick={Logout}
                            className="px-4 py-2 text-white rounded bg-primary header-signup-button"
                          >
                            {translations.header.logout}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={openModal}
                            className="px-4 py-2 text-white rounded bg-primary header-signup-button"
                          >
                            {translations.header.signUp}
                          </button>
                        </>
                      )}
                    </li>
                  </ul>
                </>
              )}

              <ul className="flex items-center space-x-4 user-icons-navbar">
                {/* <li>
                  <i
                    className={`fa fa-search ml-4 text-black text-decoration-none`}
                  ></i>
                </li> */}
                <div className="relative inline-block text-left">
                  <div className="ml-4">
                    <button
                      onClick={toggleLangDropdown}
                      type="button"
                      className="h-[42px] w-[42px] flex justify-center items-center text-gray-900 text-sm"
                    >
                      <img
                        src={`/assets/flags/${language}.svg`}
                        alt=""
                        className="inline w-4 h-4"
                      />
                    </button>
                  </div>

                  {isOpen && (
                    <div
                      className="absolute right-0 mt-2 origin-top-right bg-white rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <div className="py-1" role="none">
                        <button
                          onClick={() => {
                            handleLanguageChange("en");
                            toggleLangDropdown();
                          }}
                          className={`block px-4 py-2 text-sm ${language === "en"
                            ? "bg-blue-800 text-white"
                            : "hover:bg-gray-100 text-gray-700 hover:text-gray-900"
                            } w-full text-left`}
                          role="menuitem"
                        >
                          <img
                            src="/assets/flags/en.svg"
                            alt=""
                            className="inline w-4 h-4 mr-4"
                          />
                          {translations.header.English}
                        </button>
                        <button
                          onClick={() => {
                            handleLanguageChange("ms");
                            toggleLangDropdown();
                          }}
                          className={`block px-4 py-2 text-sm ${language === "ms"
                            ? "bg-blue-800 text-white"
                            : "hover:bg-gray-100 text-gray-700 hover:text-gray-900"
                            } w-full text-left`}
                          role="menuitem"
                        >
                          <img
                            src="/assets/flags/ms.svg"
                            alt=""
                            className="inline w-4 h-4 mr-4"
                          />
                          {translations.header.Malay}
                        </button>
                        <button
                          onClick={() => {
                            handleLanguageChange("zh");
                            toggleLangDropdown();
                          }}
                          className={`block px-4 py-2 text-sm ${language === "zh"
                            ? "bg-blue-800 text-white"
                            : "hover:bg-gray-100 text-gray-700 hover:text-gray-900"
                            } w-full text-left`}
                          role="menuitem"
                        >
                          <img
                            src="/assets/flags/zh.svg"
                            alt=""
                            className="inline w-4 h-4 mr-4"
                          />
                          {translations.header.Chinese}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <i
                  onClick={() => setOpenLoginModal(true)}
                  className={`fa fa-user ml-4 text-black cursor-pointer text-decoration-none`}
                ></i>
              </ul>
            </nav>

            <div className="md:hidden">
              <button onClick={toggleMobileMenu} className="focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            </div>
            {isMobileMenuOpen && (
              <div className="fixed top-0 left-0 bg-black h-screen w-full z-[999] flex justify-center items-center">
                <div className="text-left md:hidden">
                  <button
                    onClick={toggleMobileMenu}
                    className="absolute focus:outline-none top-4 right-4"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <ul className="px-3 py-2 text-white">
                    {/* <li><Link to="/home">Home</Link></li>
                            <hr className="w-full my-2 border-gray-400" /> Increased width */}
                    <li className="text-white hover:text-gray-400">
                      {translations.header.mobileHeaderText}
                    </li>
                    <li className="my-2">&nbsp;</li>
                    {/* Blank space */}
                    <li>
                      <NavLink to="/home" onClick={closeMobileMenu}>
                        {translations.header.home}
                      </NavLink>
                    </li>
                    <hr className="w-full my-2 border-gray-400" />
                    <li>
                      <NavLink to="/discount" onClick={closeMobileMenu}>
                        {translations.header.discount}
                      </NavLink>
                    </li>
                    <hr className="w-full my-2 border-gray-400" />
                    {/* Increased width */}
                    <li>
                      <NavLink to="/partner" onClick={closeMobileMenu}>
                        {translations.header.becomePartner}
                      </NavLink>
                    </li>
                    <hr className="w-full my-2 border-gray-400" />
                    {/* Increased width */}
                    <li>
                      <NavLink to="/about" onClick={closeMobileMenu}>
                        {translations.header.aboutUs}
                      </NavLink>
                    </li>
                    <hr className="w-full my-2 border-gray-400" />
                    <li
                      className="flex items-center justify-between cursor-pointer"
                      onClick={toggleDropdown}
                    >
                      <button className="hover:text-gray-400 focus:outline-none">
                        {translations.header.giveaways}
                      </button>
                      <i
                        className={`fa fa-chevron-down ml-1 h-3 text-[12px] w-3 text-white ${isDropdownOpen ? "transform rotate-180" : ""
                          }`}
                      ></i>
                    </li>
                    <hr className="w-full my-2 border-gray-400" />
                    {isDropdownOpen && (
                      <li>
                        <NavLink
                          to="/upcoming-members-giveaways"
                          onClick={closeMobileMenu}
                        >
                          &nbsp;&nbsp;&nbsp;{translations.header.upcomingMembersGiveaways}
                        </NavLink>
                      </li>
                    )}
                    {isDropdownOpen && (
                      <hr className="w-full my-2 border-gray-400" />
                    )}
                    {isDropdownOpen && (
                      <li>
                        <NavLink
                          to="/member-draw-giveaways"
                          onClick={closeMobileMenu}
                        >
                          &nbsp;&nbsp;&nbsp;{translations.header.memberDrawWinners}
                        </NavLink>
                      </li>
                    )}
                    {isDropdownOpen && (
                      <hr className="w-full my-2 border-gray-400" />
                    )}
                    <li>
                        <NavLink target="_blank" to="https://store.rewardclub.my/">
                          {translations.header.store}
                        </NavLink>
                    </li>
                    <hr className="w-full my-2 border-gray-400" />
                    <li
                      onClick={() => {
                        setOpenLoginModal(true);
                        closeMobileMenu();
                      }}
                    >
                      <a href="#">
                        {translations.header.memberLogin}
                      </a>
                    </li>
                    <hr className="w-full my-2 border-gray-400" />
                    {/* Increased width */}
                    <li className="my-2">&nbsp;</li>
                    {/* Blank space */}
                    {/* Add social icons */}
                    <div className="flex items-center justify-end space-x-4">
                      <a href="#">
                        <FontAwesomeIcon
                          icon={faFacebook}
                          className="w-6 mr-2 text-white"
                        />
                      </a>
                      <a href="#">
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          className="w-6 mr-2 text-white"
                        />
                      </a>
                      <a href="#">
                        <FontAwesomeIcon
                          icon={faYoutube}
                          className="w-6 mr-2 text-white"
                        />
                      </a>
                      <a href="#">
                        <FontAwesomeIcon
                          icon={faInstagram}
                          className="w-6 mr-2 text-white"
                        />
                      </a>
                    </div>
                    {/* End of social icons */}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </header>
      )}
      {isUserSignupModalOpen && (
        <GoogleOAuthProvider clientId="1066471456951-jvb9nma0ab3rglfv4v7vciam8qd8ker0.apps.googleusercontent.com">
          <UserSignup
            isUserSignupModalOpen={isUserSignupModalOpen}
            setIsUserSignupModalOpen={setIsUserSignupModalOpen}
          />
        </GoogleOAuthProvider>
      )}
      {showPopUp && (
        <SignUpPopUp
          showPopUp={showPopUp}
          onClose={() => setShowPopUp(false)}
        /> // Pass onClose function to close the pop-up
      )}
    </>
  );
};

export default Header;
