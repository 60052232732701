import React, { useState } from 'react';
import SignUpPopUp from './SignUpPopUp';

const SignUpPlanPricing = () => {

    const [showPopUp, setShowPopUp] = useState(false);

    const handleSelectButtonClick = () => {
        setShowPopUp(true);
    };

    const handleClosePopUp = () => {
        setShowPopUp(false);
    };
    return (
        <div className="flex flex-col bg-white w-full  h-100 md:p-10 ">
            <div>
                {/* 
                <div className=" flex justify-center">
                    <h2 className="section-header">Choose Your Access Level</h2>
                </div> */}

                <div className="flex justify-center mt-5">
                    <div className="md:grid md:grid-cols-2 lg:grid-cols-3 flex-col gap-4 mt-10">
                        <div className="col-span-1 p-2 h-90  hover:bg-gray-300 hover:shadow-lg hover:cursor-pointer">
                            <div
                                className="bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden ">
                                <div className="border-t-4 border-blue-500 px-6 py-4">
                                    <h2 className="color-primary text-2xl font-bold mb-4 mt-4">ENTRY MEMBERSHIP</h2>
                                    <div className="flex justify-center">
                                        <div className="border-b border-blue-500 mb-4 w-full"></div>
                                    </div>
                                    <p className=" font-bold text-6xl">$19.99</p>
                                    <p className=" mt-4">Billed Monthly (cancel anytime)</p>
                                </div>
                                <div className="px-6 py-4">
                                    <ul className="list-insidetext-left">
                                        <span className='flex flex-row items-center'>
                                            <i className="fa-solid fa-check color-primary mt-2 mr-2"></i><li
                                                className="list-check text-sm">1 week database discount access</li>
                                        </span>
                                        <span className='flex flex-row items-center'>
                                            <i className="fa-solid fa-check color-primary  mt-2 mr-2"></i> <li
                                                className="list-check text-sm">REWARD CLUB event invites</li>
                                        </span>
                                        <span className='flex flex-row items-center'>
                                            <i className="fa-solid fa-check color-primary  mt-2 mr-2"></i> <li
                                                className="list-check text-sm">10% off REWARD CLUB merch</li>
                                        </span>
                                    </ul>
                                </div>
                                <div className="px  -6 py-4 mb-5 text-center">
                                    <button
                                        onClick={handleSelectButtonClick}
                                        className="bg-primary hover:bg-primary-dark text-white py-2 px-4 rounded">
                                        SIGN UP
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Column 3 */}
                        <div className="col-span-1 pl-2 pr-2 pb-4 h-90 hover:bg-gray-300 hover:shadow-lg hover:cursor-pointer">
                            <div
                                className="bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden ">
                                <div className="border-t-4 border-blue-500 px-6 py-4">
                                    <h2 className="color-primary text-2xl font-bold mb-4 mt-4">PREMIUM MEMBERSHIP</h2>
                                    <div className="flex justify-center">
                                        <div className="border-b border-blue-500 mb-4 w-full"></div>
                                    </div>
                                    <p className=" font-bold text-6xl">$49.99</p>
                                    <p className=" mt-4">Billed Monthly (cancel anytime)</p>
                                </div>
                                <div className="px-6 py-4">
                                    <ul className="list-insidetext-left">
                                        <span className='flex flex-row items-center'>
                                            <i className="fa-solid fa-check color-primary mt-2 mr-2"></i><li
                                                className="list-check text-sm">1 week database discount access</li>
                                        </span>
                                        <span className='flex flex-row items-center'>
                                            <i className="fa-solid fa-check color-primary  mt-2 mr-2"></i> <li
                                                className="list-check text-sm">REWARD CLUB event invites</li>
                                        </span>
                                        <span className='flex flex-row items-center'>
                                            <i className="fa-solid fa-check color-primary  mt-2 mr-2"></i> <li
                                                className="list-check text-sm">10% off REWARD CLUB merch</li>
                                        </span>
                                    </ul>
                                </div>
                                <div className="px-6 py-4 mb-5 text-center">
                                    <button
                                        onClick={handleSelectButtonClick}
                                        className="bg-primary hover:bg-primary-dark text-white py-2 px-4 rounded">
                                        SIGN UP
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Column 4 */}
                        <div className="col-span-1 p-2 h-90 hover:bg-gray-300 hover:shadow-lg hover:cursor-pointer">
                            <div
                                className="bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden ">
                                <div className="border-t-4 border-blue-500 px-6 py-4">
                                    <h2 className="color-primary text-2xl font-bold mb-4 mt-4">ELITE MEMBERSHIP</h2>
                                    <div className="flex justify-center">
                                        <div className="border-b border-blue-500 mb-4 w-full"></div>
                                    </div>
                                    <p className=" font-bold text-6xl">$99.99</p>
                                    <p className=" mt-4">Billed Monthly (cancel anytime)</p>
                                </div>
                                <div className="px-6 py-4">
                                    <ul className="list-insidetext-left">
                                        <span className='flex flex-row items-center'>
                                            <i className="fa-solid fa-check color-primary mt-2 mr-2"></i><li
                                                className="list-check text-sm">1 week database discount access</li>
                                        </span>
                                        <span className='flex flex-row items-center'>
                                            <i className="fa-solid fa-check color-primary  mt-2 mr-2"></i> <li
                                                className="list-check text-sm">REWARD CLUB event invites</li>
                                        </span>
                                        <span className='flex flex-row items-center'>
                                            <i className="fa-solid fa-check color-primary  mt-2 mr-2"></i> <li
                                                className="list-check text-sm">10% off REWARD CLUB merch</li>
                                        </span>
                                    </ul>
                                </div>
                                <div className="px-6 py-4 mb-5 text-center">
                                    <button
                                        onClick={handleSelectButtonClick}
                                        className="bg-primary hover:bg-primary-dark text-white py-2 px-4 rounded">
                                        SIGN UP
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Column 5 */}
                    </div>
                </div>
            </div>
            {showPopUp && (
                <SignUpPopUp showPopUp={showPopUp} onClose={handleClosePopUp} />
            )}
        </div>
    );
};

export default SignUpPlanPricing;