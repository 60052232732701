import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const QSlider = () => {

    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000},
            items: 1,
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 1,
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 1,
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
        },
    };

    return (

        <>
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                showDots={false}
                slidesToSlide={1}
                containerClass="carousel-container"
            >
                {Array(8).fill(null)?.map((_, index) => (
                    <div key={index} className="bg-gray-200 h-[50vh] flex items-center justify-center">
                        <div className="max-w-4xl mx-auto text-center">
                            <p className="text-lg font-bold text-gray-800 p-10">
                                "Long time loyalty member here and can say I have loved being apart of the REWARD CLUB Community. Loved being able to meet the guys at the event they put on and can't wait for more! Absolute legends!"
                            </p>
                        </div>
                    </div>
                ))}
            </Carousel>
        </>

    );
};

export default QSlider;
