// import MembershipTermsAndConditions from "./MembershipTermsAndConditions"
import PrivacyPolicyEn from '../../lang/privacy_policy/privacy_policy_en';
import PrivacyPolicyMs from '../../lang/privacy_policy/privacy_policy_ms';
import PrivacyPolicyZh from '../../lang/privacy_policy/privacy_policy_zh';
import MemberTermsAndConditionsEn from '../../lang/member_terms_and_conditions/member_condition_en';
import MemberTermsAndConditionsMs from '../../lang/member_terms_and_conditions/member_condition_ms';
import MemberTermsAndConditionsZh from '../../lang/member_terms_and_conditions/member_condition_zh';
import {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";

const PrivacyPolicy = () => {

  const {language} = useContext(AuthContext);
  let PrivacyPolicyComponent;
  let MemberTermsAndConditions;

  switch (language) {
    case 'en':
      PrivacyPolicyComponent = PrivacyPolicyEn;
      MemberTermsAndConditions = MemberTermsAndConditionsEn;
      break;
    case 'ms':
      PrivacyPolicyComponent = PrivacyPolicyMs;
      MemberTermsAndConditions = MemberTermsAndConditionsMs;
      break;
    case 'zh':
      PrivacyPolicyComponent = PrivacyPolicyZh;
      MemberTermsAndConditions = MemberTermsAndConditionsZh;
      break;
    default:
      PrivacyPolicyComponent = PrivacyPolicyEn; // Default to English
      MemberTermsAndConditions = MemberTermsAndConditionsEn;
  }

  return (
    <>
      <PrivacyPolicyComponent/>
      <MemberTermsAndConditions/>
    </>
  );
}

export default PrivacyPolicy