import {ErrorMessage, Field, Form, Formik} from "formik";
import {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";
import { useParams } from "react-router-dom";

const Partner = () => {
  const { id } = useParams()

  const {language, RegisterMerchant} = useContext(AuthContext);
  const translations = require(`../../lang/common/common_${language}.json`);
  const formSchema = [
    {label: "Full Name:", name: "name", type: "text"},
    {label: "Business Name:", name: "business_name", type: "text"},
    {label: "Business License Number:", name: "business_licence_number", type: "text"},
    {label: "Business License Document:", name: "business_licence_doc", type: "file"},
    {label: "Email Address:", name: "email", type: "email"},
    {label: "Password:", name: "password", type: "password"},
    {label: "Phone Number:", name: "phone", type: "phone"},
    {label: "Business Address", type: "heading"},
    {label: "Street Address:", name: "street_address", type: "text"},
    {label: "City:", name: "city", type: "text"},
    {label: "State / Province:", name: "state", type: "text"},
    {label: "Country:", name: "country", type: "text"},
    {label: "Zip Code / Postal Code:", name: "zipcode", type: "text"},
  ];

  const initialValues = {
    name: "",
    email: "",
    password: "",
    phone: "",
    street_address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    cardNumber: "",
  };

  const handleSubmit = async (values, {setSubmitting}) => {
    console.log(values);
    if(id){
      values['referral_code'] = id;
    }

    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    await RegisterMerchant(formData);
    setSubmitting(false);
  };


  return (
    <>
      <div className="h-auto w-full bg-white pt-20">
        <div className="flex bg-cover bg-center h-auto py-2 md:py-20 px-5 md:px-10">
          <div className="container mx-auto flex flex-col md:flex-row justify-center">
            <div className="w-full px-5 md:px-20">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                {({isSubmitting}) => (
                  <Form className="w-full">
                    <h2 className="member-form-heading px-4 py-5 mb-3">Contact Details</h2>
                    {formSchema?.map((field) => (
                      <div key={field.label} className="mb-4">
                        {field.type === "heading" ? (
                          <h2 className="member-form-heading px-4 py-5 mb-3">{field.label}</h2>
                        ) : (
                          <>
                            <label
                              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4 text-left"
                              htmlFor={field.name}>
                              {field.label}
                            </label>
                            <Field
                              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              type={field.type}
                              name={field.name}
                              id={field.name}
                            />
                            <ErrorMessage name={field.name} component="div"
                                          className="color-primary text-xs italic"/>
                          </>
                        )}
                      </div>
                    ))}
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="bg-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-3"
                    >
                      Register Today
                    </button>
                    <p className="mt-4">Signing up agrees to the REWARD CLUB partner program Terms &
                      Conditions.</p>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="">

              <div className="p-8 flex justify-center">
                <img src="/assets/logo.png" alt="logo" className="h-16"/>
              </div>

              <div className="p-8">
                <h1 className="text-4xl font-bold mb-4 text-left">{translations.home.partner.includedTitle}</h1>
                <div className="py-4">
                  <ul className="list-inside text-left">
                    {Object.keys(translations.home.partner.included)?.map((key) => (
                      <li className='flex flex-row p-2 pl-0' key={key}>
                        <i className="fa-solid fa-check color-primary mt-2 mr-2"></i>
                        <span><span className="font-bold">{translations.home.partner.included[key]}</span></span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="p-8">
                <h1 className="text-4xl font-bold mb-4 text-left">{translations.home.partner.bonusesTitle}</h1>
                <div className="py-4">
                  <ul className="list-inside text-left">
                    {Object.keys(translations.home.partner.bonuses)?.map((key) => (
                      <li className='flex flex-row p-2 pl-0' key={key}>
                        <i className="fa-solid fa-check mt-2 mr-2"></i>
                        <span><span className="font-bold">{translations.home.partner.bonuses[key]}</span></span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Partner