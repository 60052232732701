const TermsAndConditions = () => {

    return (
        <>
            <div className="terms-conditions-page terms-condition-bg-main mt-20">
                <div className="flex bg-cover bg-center terms-condition-bg-inner">
                    <div className="container mx-auto terms-condition-bg-inner-style">
                        <div className="mb-6">
                            <div className="flex justify-center">
                                <h2 className="section-header mb-10">條款和條件</h2>
                            </div>
                            <p className='terms-conditions-p-text'>
                                這些條款和條件規定了獎勵俱樂部向您提供免費贈品的條件和條款，並管理您對我們網站和通過該網站提供的 RCM 會員平台（軟件）的使用，以及通過該網站提供的信息、內容、功能和服務（與軟件一起，統稱為服務）。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className="font-bold terms-conditions-p-heading">
                                您對本網站和/或服務的使用，或購買我們會員之一，表示您同意這些條款和條件，並構成您對此的約束接受，包括 RCM 不時進行的任何修改。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                如果您不同意這些條款和條件，則您不得使用本網站或服務。 RCM 可能隨時通過在網站上張貼修改後的條款和條件來修改這些條款和條件，您同意您將受到對這些條款和條件的任何更改的約束。 RCM 可能隨時對網站和/或服務進行更改。 您了解 RCM 可能基於任何原因或無原因隨時終止或限制您對網站和/或服務的使用，有或無須通知。

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>1.隱私政策</h2>
                            <p className='terms-conditions-p-text'>
                                通過點擊購買會員資格並使用我們的平台或參加我們的贈品活動，您表示您已閱讀並同意我們的隱私政策，除了這些條款和條件外。 RCM 可能隨時修改隱私政策，新版本將發佈在網站上。 如果您在任何時候不同意隱私政策的任何部分，您必須立即停止使用網站和/或服務。

                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>1.服務</h2>
                            <p className='terms-conditions-p-text'>
                                RCM 網站是一個會員獎勵平台，為其會員提供馬來西亞領先品牌的折扣優惠。會員可以使用會員儀表板，利用提供的折扣並根據其所選套餐管理所有付款，並根據需要更新詳細信息。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                RCM 保留在其唯一決定權下隨時添加、更改、修改、暫停或停止服務的任何部分的權利。您對網站和/或服務的使用，包括通過網站提供的新服務的可用性，應受這些條款和條件的約束。此外，RCM 可能在其唯一決定權下，隨時限制任何服務的部分或限制您對整個網站的訪問，並且毋需通知或承擔責任。

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>2.支持</h2>
                            <p className='terms-conditions-p-text'>
                                RCM 支持所有註冊和官方商業合作夥伴的 RCM 網絡。

                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>3.退款、退貨和換貨</h2>
                            <p className='terms-conditions-p-text'>
                                通過購買我們獎勵平台的會員資格或一次性套餐，您正在支持 RCM 品牌。重要的是要注意，在促銷期間或贈品活動期間購買的會員資格和一次性套餐不予退款。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                我們的商品和服務附帶的保證不得根據馬來西亞消費者法律排除。對於主要故障，您有權利：

                            </p>
                        </div>


                        <div className="mb-6">
                            <ul className="list-text">
                                <li>取消與我們的服務合同；和</li>
                                <li>未使用部分的退款或其價值減少的補償。</li>
                                <li>您還有權選擇主要故障的商品進行退款或更換。</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                如果商品或服務的故障未達到主要故障程度，您有權在合理時間內將故障糾正。如果未能進行糾正，您有權對商品進行退款並取消服務合同。退款或糾正不得因意見改變或未正確理解而給予。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                要使用網站和服務的某些部分，您將被引導註冊網站並創建用戶檔案或帳戶（帳戶）。合格性僅限於年滿18歲或以上的人士。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                作為註冊過程的一部分，您可能會被要求提交您的姓名、地址、電子郵件地址和/或類似信息並選擇密碼。您同意，您向 RCM 提供的所有用於創建帳戶（註冊信息）的信息將是真實、準確、及時和完整的，如果您未能提供此類信息，將構成對這些條款和條件的違反，並可能導致立即終止您的帳戶。您應（i）及時更新您的註冊信息，以保持其真實、準確、及時和完整；並（ii）保密您的密碼。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                在創建帳戶時，您不得（a）選擇或使用另一個人的電子郵件地址或用戶名，目的是冒充該人，（b）未經授權使用受其他任何人權利制約的名稱，（c）使用粗俗、冒犯性或其他不適當的電子郵件地址或用戶名，或（d）允許任何其他方使用您的帳戶和/或密碼，除非本條款另有規定。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                您不得分享或轉移任何帳戶。您不得向任何人透露您的密碼。您同意立即通過向我們發送電子郵件的方式通知 RCM，以報告您的帳戶的任何已知或懷疑的未經授權使用情況或任何已知或懷疑的安全漏洞，包括密碼的遺失、被盜或未經授權的披露。您理解並同意，即使是在您未參與的情況下，您對您使用的任何帳戶下發生的所有活動負責。RCM 不承擔因未能保持密碼的保密性而導致的任何損失或損害。

                            </p>
                        </div>


                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                RCM 的員工、董事、經理及其各自的直系家屬（即父母、配偶、同居伴侶、兄弟姐妹和子女）或與 RCM 的員工、董事或經理同住的任何其他人均不得贏得提供的贈品。

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>4. 帳戶終止。</h2>
                            <p className='terms-conditions-p-text'>
                                您理解並同意您對您的帳戶沒有所有權，RCM 可能隨時停止提供服務。此外，RCM 可以在任何時候取消您的帳戶，刪除您通過網站提供的所有註冊信息和任何其他信息（集體稱為用戶內容）與您的帳戶相關聯，而無需事先通知，原因可以是任何原因，包括但不限於您違反了這些條款和條件。RCM 不會對從網站和/或服務中刪除的任何用戶內容造成的任何損害或損失負責。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                要取消您的帳戶，只需與我們聯繫並要求取消。一旦發出請求，將在 24 小時內向您發送一個特定的取消鏈接。您需要點擊此鏈接並按照提示完成取消過程。忠誠度會員不予退款。如果最近已經支付了款項，請在計費期結束時取消您的會員資格。

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>5. 一次性會員套餐和忠誠度會員</h2>
                            <p className='terms-conditions-p-text'>
                                客戶可以選擇選擇一次性套餐或成為每月忠誠度會員以訪問 RCM 平台。

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>6. 忠誠度會員終止</h2>
                            <p className='terms-conditions-p-text'>
                                當您取消忠誠度會員資格（必須通過電子郵件進行），然後按要求提供的鏈接時，您將被從所有未來付款中移除，並且放棄未來所有促銷贈品的累積分數。忠誠度會員不予退款。如果最近已經支付了款項，請在計費期結束時取消您的會員資格。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                要從我們的郵件或短信列表中移除，請使用相應的鏈接，因為它們是通過第三方發送的。僅終止您的忠誠度會員資格將無法從這些列表中移除您。

                            </p>
                        </div>


                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                如果您的帳戶付款失敗，系統將自動進行 4 次嘗試進行扣款。系統將從最初的付款失敗開始，每 5 天（寬限期為 20 天）嘗試向您的帳戶扣款。在第 4 次也是最後一次嘗試中，如果資金不足，您的訂閱將被終止，並且您的忠誠度紀錄將遺失。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                如果您取消訂閱，Stripe 不允許重新啟用訂閱，並且該訂閱的所有積累歷史將丟失。要繼續訂閱，請按照提供的通知電子郵件中的說明，或登錄會員控制面板並更新您的銀行詳細信息。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                如果您的帳戶被取消，您可以在取消後的 30 天內創建新的訂閱以同步進入歷史。只有最近的有效訂閱將被同步，如果有多個取消訂閱，則之前的進入歷史將不會包含在未來進入的累積中，因為取消日期超過當前有效訂閱的 30 天。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                請注意，系統僅允許進行 4 次嘗試，其中包括用戶進行的任何手動嘗試來更改帳戶。

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>7. 使用網站和服務。</h2>
                            <p className='terms-conditions-p-text'>
                                您同意，在使用網站或服務時，不會違反任何適用的法律或法規。在不限制前述情況的情況下，您同意不得：（i）通過網站提供任何侵犯任何版權、商標、專利、商業秘密或其他任何方的權利（包括隱私權或公眾形象權）的材料或信息；（ii）發起、協助或參與任何類型的攻擊，包括但不限於對網站和/或服務發起的拒絕服務攻擊，或以其他方式企圖干擾網站和/或服務或任何其他人對網站和/或服務的使用；或（iii）嘗試未經授權訪問網站、服務、其他用戶註冊的帳戶，或連接到網站和/或服務的計算機系統或網絡。
                                此外，您不得使用網站來開發、生成、傳輸或存儲以下信息：（a）誹謗、有害、滲透或令人討厭的；（b）以任何方式阻礙或干擾他人對網站和/或服務的正常使用；（c）執行任何未經適用法律允許的未經請求的商業通信；（d）構成騷擾或侵犯隱私權或威脅其他人或群體；（e）對兒童造成任何損害；（f）違反任何適用的法律、法規或條例；（g）對 RCM 和/或網站或服務進行任何虛假、誤導或欺騙性的陳述或表示；或（h）構成釣魚、網路釣魚或冒充任何其他人，或竊取或假扮任何人的身份（無論是真實身份還是在線暱稱或別名）。

                            </p>
                        </div>


                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                您同意不得（i）获取或尝试获取网站的任何信息，包括但不限于其他帐户持有人的电子邮件信息或其他软件数据；（ii）拦截、检查或以其他方式观察网站或软件使用的任何专有通信协议，无论是通过使用网络分析器、数据包嗅探器还是其他设备；（iii）使用任何类型的机器人、蜘蛛、病毒、时钟、定时器、计数器、蠕虫、软件锁、停机设备、特洛伊木马路由、陷阱门、定时炸弹或任何其他旨在提供秘密或未经授权访问的手段，或者扭曲、删除、损坏或拆解网站或软件的代码、指令或第三方软件。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                您在使用网站或服务时向我们披露、提交或提供的所有评论、反馈、建议、想法和其他提交内容，例如您针对我们改进网站或服务的建议（统称为“评论”），将成为我们的专属财产。这样的披露、提交或提供任何评论将构成您对我们在评论中所有专利、版权、商标和所有其他知识产权和其他权利的全部世界范围内的权利、所有权和利益的转让，并对基于道德权利、不正当竞争、暗示合同违约、保密违约以及与提交相关的任何其他法律理论的任何索赔的放弃。因此，我们将独占所有这些权利、所有权和利益，并且在使用任何评论方面不受任何限制，无论是商业上的还是其他方面的。如果您不希望将此类权利分配给我们，您不应向我们提交任何评论。我们无义务：（i）保密地保留任何评论；（ii）向您或任何第三方支付任何评论的补偿；或（iii）回应任何评论。您应对您发布的任何评论内容负全部责任。通过发布评论或任何其他内容，您保证并声明您拥有这些内容的权利，或者以其他方式被授权发布、分发、显示、执行、传输或以其他方式分发这些评论和内容，并授予我们同样的权利。您在此无条件放弃任何基于道德权利和类似理论的索赔，如果有的话。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                您的用户内容，以及您的所有评论和其他在线通信内容（包括但不限于聊天文本、语音通信、IP 地址和您的个人信息）可能会被访问和监控，以提供服务，并且可能会被披露：（i）当我们有合理信念认为我们有义务根据法律程序（例如，法院命令、搜查令或传票）披露信息时；（ii）为满足任何适用的法律或法规；（iii）我们相信网站或服务被用于犯罪行为，包括报告此类犯罪活动或与其他公司和组织交换信息，以用于防止欺诈和减少信用卡盗窃；（iv）当我们有合理信念认为存在威胁到您、其他人或公众健康和/或安全的紧急情况时；以及（v）为了保护 RCM 的权利或财产，包括执行这些条款和条件。通过接受这些条款和条件，您在此无条件同意进行此类监控、访问和披露。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                在访问网站时，您同意遵守法律，并尊重他人的知识产权。您对网站的使用始终受版权所有权和知识产权使用的法律约束和规定。您同意不会上传、下载、显示、执行、传输或以其他方式分发任何违反任何第三方版权、商标或其他知识产权或专有权利的信息或内容。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                您将对任何违反任何相关法律的行为以及由您提供或传输的任何内容引起的任何侵权行为负全部责任，以及由您的帐户提供或传输的任何侵权行为。证明任何内容不违反任何法律或第三方权利的责任完全由您承担。
                                该网站和服务由 RCM 在马来西亚运营。选择从马来西亚以外的地点访问网站和/或服务的用户可以自行决定，并负责遵守适用的当地法律。

                            </p>
                        </div>



                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>8. 第三方产品、服务和合作伙伴提供的折扣。</h2>
                            <p className='terms-conditions-p-text'>
                                RCM 可能在网站上或通过网站提供第三方服务、内容、信息和产品。第三方产品、服务和折扣仅供我们付费会员使用，RCM 对任何第三方产品、服务和折扣不作任何陈述或保证，并对任何第三方产品、服务或折扣不承担责任和不承担责任。第三方产品、服务和折扣受到提供其的第三方的适用条款和政策约束。

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>9. 超链接。</h2>
                            <p className='terms-conditions-p-text'>
                                来自网站的链接。网站可能包含指向其他方经营的网站的链接。RCM 提供这些链接到其他网站的链接是为了方便起见，使用这些网站是您自己的风险。这些链接的网站不受 RCM 的控制，RCM 对其他网站上可用的内容不负责任。此类链接不意味着 RCM 对任何其他网站上的信息或材料的认可，RCM 不承担与您访问和使用此类链接网站相关的所有责任。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                链接到网站。未经我们事先书面同意，您不得在其他网站上放置指向本网站的链接，并且在不限制前述内容的情况下，您必须遵守 RCM 的链接政策，如下所示：（i）链接的外观、位置和其他方面不得损害或稀释与 RCM 和/或其许可方的名称和商标相关的商誉；（ii）链接的外观、位置和其他属性不得产生您的组织或实体受到 RCM 赞助、关联或与之相关的虚假外观；（iii）当用户选择时，链接必须在全屏上显示网站，并且不能在链接站点上的框架中显示；（iv）RCM 保留随时撤销其对链接的同意的权利，恕不另行通知，并且在其自行决定中行使该权利。

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>RCM 知识产权和知识产权侵权。</h2>
                            <p className='terms-conditions-p-text'>
                                网站的界面、内容、排列和布局，包括但不限于 RCM 商标和标识、设计、文字、艺术品、图形、图片、按钮、用户界面、信息和其他内容以及前述内容的任何编译（RCM 知识产权）均为 RCM 的财产，除非另有说明，受马来西亚和国际法保护，严禁复制、模仿、传播或模拟，未经 RCM 的事先书面许可，不得以任何方式复制、修改、传播、展示、分发或传输。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                您理解并承认，通过访问网站，您不会通过暗示或其他方式获得任何对网站上显示的任何材料或信息的许可或权利。您同意不以未经这些条款和条件明确允许的任何方式显示或使用网站上的任何 RCM 知识产权或第三方内容。

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>11. 会员的声明和保证。</h2>
                            <p className='terms-conditions-p-text'>
                                参与者声明并保证，不会联系 RCM 的任何关联公司、雇员、赞助商或合作伙伴，以操纵比赛结果。

                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>12. 赠品免责声明。</h2>
                            <p className='terms-conditions-p-text'>
                                成为 RCM 的会员或客户的参与者同意参加比赛，并同意以下事项：

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                任何与比赛和促销礼品有关的争议、索赔和诉因均应作为个别解决，不得以任何形式进行集体诉讼；

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                任何索赔、判决和奖励应仅限于参加比赛所产生的实际支出，但在任何情况下，RCM 都不对任何法律费用负责；

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                参与者特此放弃主张要求惩罚性、附带性或间接性损害的所有权利，以及增加或以其他方式增加任何损害赔偿的权利，除了为参加比赛而产生的实际费用之外的其他任何损害赔偿。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                在任何情况下，RCM、他们的许可人、母公司、附属公司和关联公司、他们的广告或促销代理商或他们各自的官员、董事、雇员、代表和代理商将不对任何损害或损失承担责任，包括直接、间接、附带、后果性或惩罚性损害，由于访问或使用本网站、电子或计算机故障，或参与者参加此次比赛而产生的，即使 RCM 被告知此类损害的可能性。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                RCM 保留权利，如果发现任何参与者篡改或滥用本次比赛的任何方面，将其取消资格的权利，由 RCM 自行决定。

                            </p>
                        </div>


                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                如果比赛受到未经授权的人为干预、篡改或其他超出 RCM 合理控制范围的原因影响，导致比赛的管理、安全、公平或正常运行受损或受到影响，RCM 保留暂停、修改或终止比赛的权利。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                任何参与者故意损坏网站以破坏本次比赛的正常运作的行为都是违反刑事和民事法律的，如果发生此类企图，RCM 保留根据法律允许的最大范围向任何此类参与者索赔的权利。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                不对计算机系统、服务器、软件、互联网服务提供商或电子邮件系统的任何问题或技术故障，任何条目因技术问题或不完整、延迟、丢失、损坏、不清晰或错误导致的收到问题负责，或任何电子通信的技术故障或不完整、延迟、丢失、损坏、不清晰或错误的组合负责。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                在法律禁止或限制的地方，比赛无效。

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                比赛的管理、安全、公平或正常运作，RCM 保留暂停、修改或终止比赛的权利。

                            </p>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsAndConditions