const TermsAndConditions = () => {

    return (
        <>
            <div className="terms-conditions-page terms-condition-bg-main mt-20">
                <div className="flex bg-cover bg-center terms-condition-bg-inner">
                    <div className="container mx-auto terms-condition-bg-inner-style">
                        <div className="mb-6">
                            <div className="flex justify-center">
                                <h2 className="section-header mb-10">TERMA & SYARAT</h2>
                            </div>
                            <p className='terms-conditions-p-text'>
                                Terma dan syarat ini menetapkan terma dan syarat di bawah mana Reward Club memberi anda akses kepada peraduan, dan mengatur penggunaan laman web kami, dan platform Ahli RCM yang disediakan melalui Laman (Perisian) dan maklumat, kandungan, ciri-ciri dan perkhidmatan yang disediakan melalui Laman (bersama-sama dengan Perisian, secara kolektif, Perkhidmatan).

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className="font-bold terms-conditions-p-heading">
                                PENGGUNAAN ANDA TERHADAP LAMAN WEB DAN/ATAU PERKHIDMATAN, ATAU PEMBELIAN SALAH SATU KEAHLIAN KAMI, MENYIMBOLKAN SETUJUAN ANDA TERHADAP TERMA DAN SYARAT INI DAN MENJADIKAN TERIMAAN IKATAN ANDA TERHADAP INI, TERMASUK SEBARANG MODIFIKASI YANG DILAKUKAN OLEH RCM DARI SEMASA KE SEMASA.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Sekiranya anda tidak bersetuju dengan terma dan syarat ini, anda tidak boleh menggunakan Laman atau Perkhidmatan tersebut.
                                RCM boleh mengubah terma dan syarat ini pada bila-bila masa dengan memaparkan Terma dan syarat yang dipinda di Laman, dan anda bersetuju bahawa anda akan terikat dengan sebarang perubahan kepada terma dan syarat ini. RCM boleh membuat perubahan kepada Laman dan/atau Perkhidmatan pada bila-bila masa. Anda memahami bahawa RCM boleh menghentikan atau mengehadkan penggunaan anda terhadap Laman dan/atau Perkhidmatan atas sebab apa pun atau tanpa sebab dengan atau tanpa notis.
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>1.Polisi Privasi</h2>
                            <p className='terms-conditions-p-text'>
                                Dengan mengklik untuk membeli keahlian dan menggunakan platform kami atau menyertai salah satu peraduan kami, anda mewakili bahawa anda telah membaca dan bersetuju dengan Polisi Privasi kami selain daripada terma dan syarat ini. RCM boleh mengubah Polisi Privasi pada bila-bila masa, dan versi baru akan dipaparkan di Laman. Jika pada bila-bila masa anda tidak bersetuju dengan sebarang bahagian Polisi Privasi, anda harus segera berhenti menggunakan Laman dan/atau Perkhidmatan.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>1.Perkhidmatan.</h2>
                            <p className='terms-conditions-p-text'>
                                Laman RCM adalah platform ganjaran keahlian yang menawarkan kepada ahlinya diskaun di jenama utama di seluruh Malaysia. Ahli mendapat akses ke papan pemuka ahli di mana mereka boleh menggunakan diskaun yang ditawarkan dan menguruskan semua pembayaran mereka bergantung kepada pakej yang mereka miliki dan mengemaskini butiran mengikut keperluan.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                RCM berhak untuk menambah, mengubah, mengedit, menangguhkan atau menghentikan sebahagian mana pun daripada Perkhidmatan, atas budi bicara tunggalnya, pada bila-bila masa. Penggunaan anda terhadap Laman dan/atau Perkhidmatan, termasuk ketersediaan perkhidmatan baru melalui Laman, akan tertakluk kepada terma dan syarat ini. Selain itu, RCM boleh menetapkan had pada sebahagian mana pun daripada Perkhidmatan atau menyekat akses anda kepada bahagian atau keseluruhan Laman atas budi bicaranya sendiri tanpa notis atau liabiliti.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>2.Sokongan</h2>
                            <p className='terms-conditions-p-text'>
                                RCM menyokong semua Rakan Perniagaan yang berdaftar dan rasmi dalam Rangkaian RCM.

                            </p>
                        </div>



                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>3.Pemulangan, pemulangan, dan pertukaran</h2>
                            <p className='terms-conditions-p-text'>
                                Dengan membeli keahlian atau pakej satu kali untuk platform ganjaran kami, anda menyokong jenama RCM. Penting untuk diingat bahawa keahlian dan pakej satu kali yang dibeli semasa tempoh promosi atau peraduan tidak boleh dipulangkan.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Barang-barang dan perkhidmatan kami disertakan dengan jaminan yang tidak boleh dikecualikan di bawah Undang-Undang Pengguna Malaysia. Untuk kegagalan besar, anda berhak

                            </p>
                        </div>

                        <div className="mb-6">
                            <ul className="list-text">
                                <li>memansuhkan kontrak perkhidmatan anda dengan kami; dan</li>
                                <li>pemulangan untuk bahagian yang tidak digunakan atau pampasan untuk nilai yang dikurangkan.</li>
                                <li>Anda juga berhak untuk memilih pemulangan atau penggantian untuk kegagalan besar dengan barang.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Jika kegagalan dengan barang atau perkhidmatan tidak membawa kepada kegagalan besar, anda berhak untuk mengatasi kegagalan tersebut dalam masa yang munasabah. Jika ini tidak dilakukan, anda berhak untuk mendapatkan pemulangan untuk barang dan untuk memansuhkan kontrak perkhidmatan. Pemulangan atau penyelesaian tidak boleh diberikan atas sebab seperti perubahan fikiran atau kegagalan memahami dengan betul.

                            </p>
                        </div>


                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Untuk menggunakan bahagian-bahagian tertentu dalam Laman dan Perkhidmatan, anda akan diarahkan untuk mendaftar di Laman dan membuat profil pengguna atau akaun (Akaun). Kelayakan terhad kepada individu yang berumur sama atau lebih dari 18 tahun.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Sebahagian daripada proses pendaftaran, anda mungkin diminta untuk mengemukakan nama, alamat, alamat e-mel dan/atau maklumat yang serupa dan untuk memilih kata laluan. Anda bersetuju bahawa semua maklumat yang anda berikan kepada RCM untuk tujuan membuat akaun (Maklumat Pendaftaran) akan menjadi benar, tepat, semasa, dan lengkap dan kegagalan anda untuk menyediakan maklumat tersebut akan merupakan suatu pelanggaran terhadap terma dan syarat ini dan boleh mengakibatkan pembatalan segera Akaun anda. Anda hendaklah (i) dengan segera mengemas kini Maklumat Pendaftaran anda untuk menjadikannya benar, tepat, semasa, dan lengkap; dan (ii) mengekalkan kerahsiaan kata laluan anda.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Dalam membuat Akaun, anda tidak boleh (a) memilih atau menggunakan alamat e-mel atau nama pengguna orang lain dengan niat untuk menyerupai orang tersebut, (b) menggunakan nama yang tertakluk kepada hak mana-mana individu lain tanpa kebenaran, (c) menggunakan alamat e-mel atau nama pengguna yang lucah, menyakitkan hati, atau sebaliknya tidak sesuai atau (d) membenarkan mana-mana pihak lain untuk menggunakan Akaun dan/atau kata laluan anda kecuali seperti yang dinyatakan di sini.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Anda tidak boleh berkongsi atau memindahkan mana-mana Akaun. Anda tidak boleh mendedahkan kata laluan anda kepada sesiapa pun. Anda bersetuju untuk dengan segera memberitahu RCM dengan menghantar e-mel kepada kami tentang apa-apa penggunaan yang tidak dibenarkan yang diketahui atau disyaki pada Akaun anda atau apa-apa pelanggaran keselamatan yang diketahui atau disyaki, termasuk kehilangan, pencurian atau penyebaran kata laluan anda tanpa kebenaran. Anda memahami dan bersetuju bahawa anda akan bertanggungjawab untuk semua aktiviti yang berlaku di bawah mana-mana Akaun yang dibuat untuk kegunaan anda, walaupun aktiviti tersebut tidak dilakukan oleh anda. RCM tidak bertanggungjawab atas sebarang kerugian atau kerosakan yang timbul daripada kegagalan anda untuk mengekalkan kerahsiaan kata laluan anda.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Pekerja, pengarah, pengurus RCM dan ahli keluarga terdekat mereka (iaitu, ibu bapa, pasangan, rakan hidup, adik-beradik dan anak-anak) atau mana-mana individu lain yang tinggal di rumah yang sama dengan pekerja, pengarah atau pengurus RCM tidak boleh memenangi peraduan yang ditawarkan.

                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>4. Pemutusan Akaun.</h2>
                            <p className='terms-conditions-p-text'>
                                Anda memahami dan bersetuju bahawa anda tidak mempunyai hak milik dalam Akaun anda dan RCM boleh menghentikan penawaran Perkhidmatan pada bila-bila masa. Selanjutnya, RCM boleh membatalkan Akaun anda, memadamkan semua Maklumat Pendaftaran anda dan mana-mana maklumat lain yang anda berikan melalui Laman (secara kolektif, Kandungan Pengguna) yang berkaitan dengan Akaun anda pada bila-bila masa, tanpa notis, atas sebab atau tanpa sebab termasuk, tanpa had, pelanggaran anda terhadap terma dan syarat ini. RCM tidak akan bertanggungjawab atas sebarang kerosakan atau kerugian yang berpunca daripada penghapusan mana-mana Kandungan Pengguna dari Laman dan/atau Perkhidmatan.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Untuk membatalkan akaun anda, hanya hubungi kami dan minta pembatalan. Setelah permintaan dibuat, pautan pembatalan tertentu akan dihantar kepada anda dalam tempoh 24 jam. Anda perlu mengklik pautan ini dan mengikuti arahan untuk menyelesaikan proses pembatalan. Tiada bayaran balik bagi keahlian kesetiaan. Jika pembayaran telah dibuat baru-baru ini, sila batalkan keahlian anda pada akhir tempoh yang dibayar.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>5. Pakej Keahlian Sekali Guna atau Keahlian Kesetiaan Bulanan</h2>
                            <p className='terms-conditions-p-text'>
                                Pelanggan mempunyai pilihan untuk memilih Pakej Sekali Guna atau menjadi Ahli Kesetiaan Bulanan untuk mengakses Platform RCM.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>6. Pemutusan Keahlian Kesetiaan</h2>
                            <p className='terms-conditions-p-text'>
                                Apabila anda membatalkan keahlian kesetiaan anda yang mesti dilakukan melalui e-mel dan kemudian mengikuti pautan yang disediakan atas permintaan, anda akan dikeluarkan dari semua pembayaran masa depan dan kehilangan entri bonus yang terkumpul dari semua peraduan promosi masa depan. Tiada bayaran balik bagi keahlian kesetiaan. Jika pembayaran telah dibuat baru-baru ini, sila batalkan keahlian anda pada akhir tempoh yang dibayar.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Untuk dikeluarkan dari senarai e-mel atau mesej teks kami, sila gunakan pautan yang berkaitan kerana mereka dihantar melalui pihak ketiga. Memutuskan keahlian kesetiaan anda sahaja tidak akan mengeluarkan anda dari senarai ini.

                            </p>
                        </div>


                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Jika akaun anda gagal membuat pembayaran, akan ada 4 percubaan automatik untuk membuat bilannya. Sistem akan cuba memuatkan akaun anda setiap 5 hari (tempoh penangguhan 20 hari) dari kegagalan bayaran asal. Pada percubaan ke-4 dan terakhir, jika dana tidak mencukupi, langganan anda akan diberhentikan, dan rentetan kesetiaan anda akan hilang.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Jika anda membatalkan langganan anda, Stripe tidak membenarkan pengaktifan semula, dan semua sejarah yang terkumpul untuk langganan itu akan hilang. Untuk meneruskan langganan anda, sila ikuti arahan dalam e-mel pemberitahuan yang disediakan atau log masuk ke papan pemuka ahli dan kemaskini butiran perbankan anda.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Jika akaun anda dibatalkan, anda boleh menyelaraskan sejarah entri dengan membuat langganan baru dalam tempoh 30 hari daripada pembatalan. Hanya langganan yang paling baru aktif akan diselaraskan, dan jika terdapat beberapa pembatalan, sejarah entri sebelumnya tidak akan dimasukkan dalam pengumpulan entri pada masa akan datang kerana tarikh pembatalan melebihi 30 hari dari langganan aktif semasa.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Sila ambil perhatian bahawa sistem hanya membenarkan 4 percubaan, termasuk sebarang percubaan manual yang dibuat oleh pengguna untuk menukar akaun.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>7. Penggunaan Laman dan Perkhidmatan.</h2>
                            <p className='terms-conditions-p-text'>
                                Anda bersetuju bahawa anda tidak akan, dalam hubungannya dengan penggunaan anda terhadap Laman atau Perkhidmatan, melanggar mana-mana undang-undang atau peraturan yang berkenaan. Tanpa menghadkan perkara tersebut, anda bersetuju untuk tidak (i) menjadikan sesuatu bahan atau maklumat yang melanggar hak cipta, cap dagang, paten, rahsia dagang, atau hak lain mana-mana pihak (termasuk hak privasi atau ketenaran) tersedia melalui Laman; (ii) menjalankan, membantu, atau terlibat dalam sebarang jenis serangan termasuk, tanpa had, serangan penafian perkhidmatan, terhadap Laman atau Perkhidmatan atau sebaliknya cuba mengganggu Laman atau Perkhidmatan atau penggunaan Laman atau Perkhidmatan orang lain; atau (iii) cuba mendapatkan akses yang tidak sah ke Laman, Perkhidmatan, Akaun yang didaftarkan kepada pengguna lain, atau sistem komputer atau rangkaian yang bersambung dengan Laman atau Perkhidmatan.
                                Selain itu, anda tidak boleh menggunakan Laman untuk membangunkan, menghasilkan, menghantar atau menyimpan maklumat yang: (a) memfitnah, berbahaya, menyalahi undang-undang, atau benci; (b) dalam apa cara pun menghalang atau sebaliknya mengganggu prestasi normal penggunaan Laman atau Perkhidmatan orang lain, (c) menjalankan komunikasi perniagaan yang tidak diminta yang tidak dibenarkan oleh undang-undang yang berkenaan; (d) merupakan pelecehan atau pelanggaran privasi atau mengancam orang lain atau kumpulan orang; (e) merosakkan kanak-kanak dalam apa cara pun; (f) melanggar mana-mana undang-undang, peraturan atau ordinan yang berkenaan; (g) membuat sebarang kenyataan atau representasi palsu, menyesatkan atau menipu mengenai RCM atau Laman atau Perkhidmatan atau (h) merupakan phishing, pharming atau mengimpersonasi mana-mana orang lain, atau mencuri atau mengambil identiti mana-mana orang (sama ada identiti sebenar atau nama samaran atau alias dalam talian).

                            </p>
                        </div>


                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Anda bersetuju bahawa anda tidak akan (i) memperoleh atau cuba memperoleh sebarang maklumat dari Laman termasuk, tanpa had, maklumat e-mel pemegang akaun lain atau data Perisian lain; (ii) merampas, menyiasat atau mengawasi mana-mana protokol komunikasi hak milik yang digunakan oleh Laman atau Perisian, sama ada melalui penggunaan analisis rangkaian, penangkap paket atau peranti lain; (iii) menggunakan sebarang jenis bot, labah-labah, virus, jam, penjumlah, cacing, kunci perisian, peranti drop dead, Trojan-horse routing, trap door, bom masa atau mana-mana kod, arahan atau perisian pihak ketiga yang direka untuk menyediakan cara akses secara senyap atau tidak dibenarkan ke, atau merosakkan, menghapuskan, merosakkan atau membongkar Laman atau Perisian.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Semua komen, maklum balas, cadangan, idea, dan sumbangan lain yang anda dedahkan, hantar atau tawarkan kepada kami berkaitan dengan penggunaan anda terhadap Laman atau Perkhidmatan, seperti cadangan anda mengenai penambahbaikan yang kami lakukan kepada Laman atau Perkhidmatan (secara kolektif, Komen) akan menjadi hak milik eksklusif kami. Pendedahan, penghantaran atau tawaran sebarang Komen akan merupakan pemberian kepada kami semua hak, judul, dan kepentingan di seluruh dunia dalam semua paten, hak cipta, cap dagang, dan semua hak harta intelek dan hak lain apa pun dalam dan kepada Komen dan pengabaian sebarang tuntutan berdasarkan hak moral, persaingan yang tidak adil, pelanggaran kontrak tersirat, pelanggaran kerahsiaan, dan mana-mana teori undang-undang lain yang berkaitan dengan sumbangan. Oleh itu, kami akan memiliki secara eksklusif semua hak, judul dan kepentingan tersebut dan tidak akan terhad dalam apa cara pun dalam penggunaan, sama ada secara komersial atau tidak, mana-mana Komen. Anda tidak perlu mengemukakan sebarang Komen kepada kami jika anda tidak ingin memberikan hak tersebut kepada kami. Kami tidak berkewajiban: (i) untuk mengekalkan apa-apa Komen secara rahsia; (ii) untuk membayar kepada anda atau mana-mana pihak ketiga apa-apa pampasan untuk mana-mana Komen; atau (iii) untuk memberi respons kepada mana-mana Komen. Anda adalah dan akan tetap bertanggungjawab sepenuhnya atas kandungan mana-mana Komen yang anda buat. Dengan mengemukakan Komen atau sebarang kandungan lain, anda menjamin dan mewakili bahawa anda memiliki hak untuk melakukannya atau diberi kebenaran untuk mengemukakan, mengedar, memaparkan, melakukan, menghantar, atau sebaliknya mengedarkan Komen dan kandungan tersebut dan memberi kami hak untuk melakukan perkara yang sama. Anda dengan ini secara tidak boleh ditarik balik mengabaikan sebarang tuntutan berdasarkan hak moral dan teori yang serupa, jika ada.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Kandungan Pengguna anda, dan kandungan semua Komen anda dan komunikasi dalam talian lain (termasuk tanpa had teks sembang, komunikasi suara, alamat IP dan maklumat peribadi anda) boleh diakses dan dipantau sebagaimana yang diperlukan untuk menyediakan Perkhidmatan dan boleh didedahkan: (i) apabila kami mempunyai keyakinan yang baik bahawa kami dikehendaki untuk mendedahkan maklumat tersebut sebagai tindak balas kepada proses undang-undang (contohnya, perintah mahkamah, waran cari atau subpoena); (ii) untuk memenuhi apa-apa undang-undang atau peraturan yang berkenaan (iii) di mana kami percaya bahawa Laman atau Perkhidmatan digunakan dalam pengkomitannya jenayah, termasuk untuk melaporkan aktiviti jenayah sedemikian atau untuk pertukaran maklumat dengan syarikat lain dan organisasi untuk tujuan perlindungan penipuan dan pengurangan kecurian kad kredit; (iv) apabila kami mempunyai keyakinan yang baik bahawa terdapat keadaan kecemasan yang membahayakan kesihatan dan/atau keselamatan anda, orang lain atau orang ramai secara umum; dan (v) untuk melindungi hak atau harta RCM, termasuk untuk menguatkuasakan terma dan syarat ini. Dengan memasuki terma dan syarat ini, anda dengan ini memberikan persetujuan tidak boleh ditarik balik kepada pemantauan, akses, dan pendedahan sedemikian.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Apabila mengakses Laman, anda bersetuju untuk mematuhi undang-undang dan menghormati hak milik intelek orang lain. Penggunaan anda terhadap Laman adalah pada setiap masa di bawah bidang kuasa dan tertakluk kepada undang-undang mengenai kepemilikan hak cipta dan penggunaan harta intelek. Anda bersetuju untuk tidak mengunggah, memuat turun, memaparkan, melakukan, menghantar, atau sebaliknya mengedarkan mana-mana maklumat atau kandungan yang melanggar mana-mana hak cipta, cap dagang, atau hak milik intelek atau hak milik lain pihak ketiga.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Anda adalah bertanggungjawab sepenuhnya atas sebarang pelanggaran undang-undang yang relevan dan bagi sebarang pelanggaran hak pihak ketiga yang disebabkan oleh mana-mana kandungan yang anda sediakan atau hantar, atau yang disediakan atau dihantar menggunakan akaun anda. Beban membuktikan bahawa mana-mana kandungan tidak melanggar mana-mana undang-undang atau hak pihak ketiga semata-mata bergantung kepada anda.
                                Laman dan Perkhidmatan dijalankan oleh RCM di Malaysia. Mereka yang memilih untuk mengakses Laman, dan/atau Perkhidmatan dari lokasi di luar Malaysia boleh berbuat demikian atas inisiatif sendiri dan bertanggungjawab untuk mematuhi undang-undang tempatan yang berkenaan.

                            </p>
                        </div>



                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>8. Produk, Perkhidmatan Pihak Ketiga + Diskaun dari Rakan Kita.</h2>
                            <p className='terms-conditions-p-text'>
                                Perkhidmatan, kandungan, maklumat, dan produk pihak ketiga mungkin disediakan oleh RCM di atau melalui Laman. Produk, perkhidmatan, dan diskaun Pihak Ketiga disediakan semata-mata untuk manfaat ahli yang membayar kami dan RCM tidak membuat pernyataan atau jaminan mengenai mana-mana Produk, perkhidmatan, dan diskaun Pihak Ketiga dan tidak bertanggungjawab dan tidak menanggung sebarang liabiliti bagi mana-mana Produk, perkhidmatan, atau diskaun Pihak Ketiga. Produk, perkhidmatan, dan diskaun Pihak Ketiga tertakluk kepada terma dan polisi yang berkenaan dari pihak ketiga yang menawarkannya.
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>9. Pautan Hiperteks.</h2>
                            <p className='terms-conditions-p-text'>
                                Pautan dari Laman. Laman mungkin mengandungi pautan ke laman web yang dikendalikan oleh pihak lain. RCM menyediakan pautan ini ke laman web lain sebagai kemudahan dan penggunaan laman-laman ini adalah atas risiko anda sendiri. Laman yang dipautkan tidak di bawah kawalan RCM, dan RCM tidak bertanggungjawab atas kandungan yang tersedia di laman-laman lain tersebut. Pautan seperti itu tidak menunjukkan sokongan RCM terhadap maklumat atau bahan di mana-mana laman lain dan RCM menafikan semua liabiliti berkaitan dengan akses anda dan penggunaan laman web yang dipautkan tersebut.
                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Pautan ke Laman. Anda tidak boleh meletakkan pautan ke Laman pada laman web lain tanpa kebenaran bertulis terlebih dahulu dan, tanpa menghadkan perkara tersebut, anda harus mematuhi dasar pautan RCM seperti berikut: (i) penampilan, kedudukan, dan aspek lain pautan tidak boleh merosakkan atau mengurangkan reputasi baik yang berkaitan dengan nama RCM dan/atau cap dagang dan jenama dagang pemegang lesen kami, (ii) penampilan, kedudukan, dan atribut lain pautan tidak boleh mencipta penampilan palsu bahawa organisasi atau entiti anda disponsor oleh, berafiliasi dengan, atau dikaitkan dengan RCM (iii) apabila dipilih oleh pengguna, pautan tersebut harus memaparkan Laman pada skrin penuh dan bukan dalam bingkai pada laman yang dipautkan, dan (iv) RCM berhak untuk menarik balik persetujuan terhadap pautan pada bila-bila masa dan atas budi bicaranya sendiri.
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>Harta Intelek RCM dan Pelanggaran Harta Intelek.
                            </h2>
                            <p className='terms-conditions-p-text'>
                                Antara muka, kandungan, susunan dan reka bentuk Laman termasuk, tetapi tidak terhad kepada, cap dagang dan logo RCM, reka bentuk, teks, karya seni, grafik, imej, butang, antara muka pengguna, maklumat dan kandungan lain, dan sebarang kompilasi yang berkaitan (Harta Intelek RCM) adalah hak milik RCM, kecuali dinyatakan sebaliknya, dan dilindungi daripada penyalinan, peniruan, penyiaran, atau simulasi di bawah undang-undang Malaysia dan antarabangsa dan tidak boleh direproduksi, diubahsuai, disiarkan, dipaparkan, diedarkan, atau dihantar tanpa kebenaran bertulis terlebih dahulu dari RCM.
                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Anda memahami dan mengakui bahawa, dengan mengunjungi Laman, anda tidak memperoleh atau mendapatkan secara tersirat atau sebaliknya, sebarang lesen atau hak untuk menggunakan atau membuat salinan tambahan mana-mana bahan atau maklumat yang dipaparkan di Laman. Anda bersetuju untuk tidak memaparkan atau menggunakan Harta Intelek RCM atau kandungan pihak ketiga yang terletak di Laman dengan cara yang tidak diperuntukkan secara khusus di bawah terma dan syarat ini.
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>11. Perwakilan dan Jaminan Para Ahli.</h2>
                            <p className='terms-conditions-p-text'>
                                Peserta mewakili dan menjamin bahawa ia tidak akan menghubungi mana-mana rakan kongsi, pekerja, penaja, atau rakan kongsi RCM untuk memanipulasi hasil Pertandingan.
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>12. Penafian Giveaway.</h2>
                            <p className='terms-conditions-p-text'>
                                Dengan menjadi ahli atau Pelanggan RCM, Peserta bersetuju untuk menyertai Pertandingan dan bersetuju bahawa:
                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Sebarang pertikaian, tuntutan, dan sebab tindakan yang timbul dari atau berkaitan dengan Pertandingan dan hadiah promosi hendaklah diselesaikan secara individu tanpa sebarang bentuk tindakan kelas;
                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Sebarang tuntutan, keputusan dan ganjaran hendaklah terhad kepada kos sebenar yang dikeluarkan untuk memasuki Pertandingan, tetapi dalam keadaan apa pun RCM tidak akan bertanggungjawab atas sebarang yuran guaman;
                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Peserta dengan ini menolak semua hak untuk menuntut ganti rugi punitif, kebetulan atau bertindakbalas dan sebarang hak untuk membesarkan atau meningkatkan ganti rugi dan sebarang ganti rugi lain, selain dari ganti rugi bagi kos sebenar yang dikeluarkan untuk memasuki pertandingan.
                            </p>
                        </div>


                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Dalam sebarang keadaan, RCM, pihak-pihak yang dilesensikan mereka, ibu bapa, rakan kongsi, anak syarikat dan syarikat-syarikat yang berkaitan, agensi-agensi pengiklanan atau promosi mereka atau pegawai-pegawai, pengarah-pengarah, pekerja-pekerja, wakil-wakil dan ejen-ejen mereka, tidak akan bertanggungjawab atau bertanggungjawab atas sebarang kerosakan atau kerugian apa jua, termasuk kerosakan langsung, tidak langsung, kebetulan, berturut-turut atau punitif yang timbul daripada akses kepada, atau penggunaan, laman web ini, gangguan elektronik atau komputer, atau penyertaan peserta dalam pertandingan ini, walaupun RCM telah dimaklumkan mengenai kemungkinan kerosakan tersebut.
                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                RCM berhak untuk menolak peserta mana-mana yang ditemui merosakkan atau sebaliknya menyalahgunakan sebarang aspek Pertandingan ini sepenuhnya ditentukan oleh RCM.
                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Sekiranya Pertandingan diganggu oleh campur tangan manusia yang tidak dibenarkan, pemalsuan atau sebab-sebab lain di luar kawalan munasabah RCM, yang merosakkan atau mengganggu pentadbiran, keselamatan, keadilan atau operasi yang betul Pertandingan, RCM berhak untuk menangguhkan, mengubahsuai atau menghentikan Pertandingan.
                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Sebarang cubaan oleh seorang Peserta untuk sengaja merosakkan laman web bagi merosakkan operasi sah Pertandingan ini merupakan pelanggaran undang-undang jenayah dan sivil, dan jika cubaan sedemikian dibuat, RCM berhak untuk menuntut ganti rugi daripada mana-mana Peserta sedemikian sehingga sepenuhnya dibenarkan oleh Undang-undang.
                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                RCM tidak bertanggungjawab atas sebarang masalah atau gangguan teknikal sistem komputer, pelayan, perisian, pembekal perkhidmatan internet, atau sistem e-mel, kegagalan mana-mana penyertaan diterima disebabkan masalah teknikal atau komunikasi elektronik yang tidak lengkap, lewat, hilang, rosak, tidak sah atau tersalah hala atau sebarang gabungan di antaranya.
                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Pertandingan ini tidak sah di mana dihalang atau terhad oleh undang-undang.
                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                pentadbiran, keselamatan, keadilan atau operasi yang betul Pertandingan, RCM berhak untuk menangguhkan, mengubahsuai atau menghentikan Pertandingan.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsAndConditions