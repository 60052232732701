

const PrivacyPolicy = () => {

    return (
        <>
            <div className="privacy-policy-page privacy-policy-bg-main md:mt-16 sm:mt-16 lg:mt-20">
                <div className="flex bg-cover bg-center privacy-policy-page privacy-policy-bg-inner">
                    <div className="container mx-auto privacy-policy-bg-inner-style" >

                        <div className="mb-6">
                            <div className=" flex justify-center">
                                <h2 className="section-header mb-10">隐私政策</h2>
                            </div>
                            <p className='privacy-policy-p-text'>
                                总部位于马来西亚吉隆坡的REWARD CLUB.MY致力于保护您的隐私。我们已准备了这份隐私政策，以向您描述我们从网站用户那里收集的个人信息的做法。
                            </p>

                            <p className='privacy-policy-p-text'>
                                通过使用本服务，用户同意我们收集和使用他们的个人信息。您也向我们声明，您具有使用这些服务所需的一切授权，包括使用它们处理个人信息。我们收集并使用您向我们提供的信息，包括从您使用服务中获取的信息。此外，我们可能会将我们收集的信息用于内部目的，以开发、调整、增强和改进我们的服务，并与本隐私政策一致的广告和营销活动。
                            </p>

                        </div>

                        <div className="mb-6">
                            <p className='privacy-policy-p-text'>
                                请仔细阅读我们的隐私政策，以清楚了解我们的网站如何收集、使用、保护或处理用户的个人信息。
                            </p>

                            <p className='privacy-policy-p-text'>

                                本隐私政策旨在告知用户我们的网站如何处理有关用户的个人信息。如果用户不同意本隐私政策的任何部分，则我们无法向用户提供其服务，并且用户应停止访问我们的服务。

                            </p>

                            <p className='privacy-policy-p-text'>
                                通过使用本服务，您承认、同意并同意我们可以收集、处理和使用您向我们提供的信息，并且此类信息仅可由我们或根据保密协议行事的第三方使用，以开发、调整、增强和改进本服务。

                            </p>

                            <p className='privacy-policy-p-text'>
                                尽管我们可能会尝试在修改本隐私政策时通知您，但您有责任定期审查可能对政策进行的任何更改。我们可以自行决定随时修改或修订本政策，您同意受到此类修改或修订的约束。
                            </p>

                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>定义</h2>
                            <ul className='list-text'>
                                <li>“收集”指在合法和公正的情况下，通过获取、获取或获取信息，以便个人可识别或已识别。</li>
                                <li>“明示同意后果”包括这样一个事实，即如果您同意我们向海外收件人披露您的个人信息，并且该海外收件人违反隐私法处理您的个人信息，则我们将不负责任，您将无法根据隐私法寻求救济。</li>
                                <li>“明确告知”是指我们已向您提供了明确的声明（无论是口头还是书面形式）关于明示同意后果。</li>
                                <li>“选择退出”是指个人明确要求不再接收进一步的直接营销通信。</li>
                                <li>“个人信息”指非敏感信息，包括关于个人的信息或意见（无论是信息或意见是否构成数据库的一部分），无论真实与否，无论是否记录在实质形式中，可以确定个人的身份，或者可以合理地从信息或意见中确定个人的身份。这包括但不限于个人的名字、姓氏、电子邮件地址、电话号码、密码和地址。</li>
                                <li>“主要目的”是收集任何个人信息的主要原因。</li>
                                <li>“合理预期”是指合理的个人预期他们的个人信息可能被用于或披露给相关目的。</li>
                                <li>“次要目的”是指除主要目的外的使用或披露目的。</li>
                                <li>“敏感信息”按照《1988年隐私法》第6(1)条的含义来定义。</li>
                                <li>“使用”是指我们公司处理个人信息的方式。</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>我们收集的信息</h2>
                            <ul className='list-text'>
                                <li>当您注册时，您向我们提供关于自己的信息 - 您的姓名、电子邮件地址、电话号码等。如果您通过电子邮件与我们联系，我们可能会保留您电子邮件消息的内容、您的电子邮件地址和我们的回复。我们还收集关于您使用我们服务的一般信息。</li>
                                <li>通过在我们的网站上输入您的电子邮件和电话号码，您可能会收到我们的营销材料。这包括但不限于促销优惠、通讯和有关新产品或服务的信息。我们可能会使用您向我们提供的信息通过电子邮件或电话与您联系，即使您没有提交任何表单。如果您不希望收到我们的营销材料，请通过发送电子邮件给我们或从我们的电子邮件中退订来告诉我们。</li>
                                <li>在与论坛或互动区中的其他用户进行互动时，您会分享您的联系信息，您分享的消息不是私人的，任何网站成员或用户都可以查看。</li>
                                <li>您在网站上发布的广告和其他相关详细信息以及您在其中分享的信息都将被我们收集。</li>
                                <li>当您使用我们的服务时，我们会自动收集以下信息：</li>
                                <li>日志信息：我们记录您对我们网站的使用情况，包括您的浏览器类型和语言、访问时间、查看的页面、您的IP地址以及您在导航到我们的网站之前访问的网站。</li>
                                <li>设备信息：我们可能会收集有关您用于访问我们服务的设备的信息，包括硬件型号、操作系统和版本、唯一设备标识符、电话号码、国际移动设备识别码（IMEI）和移动网络信息。</li>
                                <li>位置信息：在您的同意下，我们可能会收集有关您设备位置的信息，以便促进您使用我们服务的某些功能，确定您的设备行进速度，添加基于位置的过滤器（如当地天气）以及其他任何目的。</li>
                                <li>通过Cookie和其他跟踪技术收集的信息：我们使用各种技术来收集信息，这可能包括向您发送Cookie。 "Cookie"是一个小型数据文件，传输到您计算机的硬盘驱动器上，允许网站以个人身份回应您，收集和记住有关您的偏好的信息，以便根据您的需求、喜好和不喜欢来定制其操作。总的来说，Cookie是安全的，因为它们只将您的计算机标识为定制您的Web体验的个体。接受Cookie并不会使我们访问您的计算机或您选择共享的任何个人信息，除非您选择共享的信息。其他服务器无法读取它们，也无法用于传递病毒。大多数浏览器会自动接受Cookie，但您通常可以调整（Microsoft Internet Explorer、Firefox或Google Chrome）来通知您请求放置Cookie、拒绝某些Cookie或完全拒绝Cookie。如果您完全关闭Cookie，可能会有一些网站功能对您不可用，并且一些网页可能无法正常显示。为了支持我们网站的个性化功能（如您的国家和语言代码和浏览功能），我们必须向您计算机的硬盘驱动器发送Cookie和/或使用基于Cookie的身份验证来识别您作为注册网站用户。</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>在网站的各种平台上发布时分享的信息</h2>
                            <ul className='list-text'>
                                <li>在论坛/互动区与其他用户互动时，您会分享您的联系信息；您分享的消息不是私密的，任何网站成员或用户都可以查看。</li>
                                <li>您在网站上发布的广告和相关材料以及您在其中分享的信息都将被我们收集。</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>在交易期间共享的信息</h2>
                            <ul className='list-text'>
                                <li>您同意通过我们的网站与第三方进行交易，并与他们共享您的个人信息以便轻松完成交易。您特此明确同意，我们不涉及也不对您的数据隐私或安全性的任何违规行为负责或承担责任。如有违规行为，应由您与第三方之间的争议解决，我们不承担责任或收到通知。

                                </li>
                                <li>我们强烈建议您在与交易伙伴共享个人信息时要小心谨慎。请不要通过我们的网站或通过我们网站认识的任何个人向任何人透露您的银行和账户详细信息。
                                </li>
                                <li>我们不会在我们的网站上收集信用卡/借记卡或其他银行详细信息。您将被引导通过我们网站集成的相应支付网关进行登录付款，并在其中完成交易。请注意，我们不会在记录中存储任何与银行相关的信息，我们的员工也不会持有或暴露这些信息。

                                </li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>我们如何使用您的信息</h2>
                            <ul className='list-text'>
                                <li>我们使用收集的个人信息来满足您对服务的请求，改进我们的服务并与您联系。</li>
                                <li>通过在任何表单中输入并向我们提供您的电子邮件地址和/或手机号码，您同意我们使用该电子邮件地址和手机号码向您发送我们网站和服务相关的通知，包括法律规定的通知，而不是通过邮件发送通信。您还同意，我们可以根据适用的隐私设置，向您提供的电子邮件地址或移动设备发送有关我们网站活动的通知。我们可能会使用您的电子邮件地址向您发送其他消息，例如新闻通讯、我们功能的变更或其他信息。如果您不想收到可选的电子邮件消息，您可以修改设置以选择退出。</li>
                                <li>我们的设置还可能允许您调整通信偏好设置。如果您不希望收到我们的促销电子邮件消息，您可以按照这些电子邮件中的退订说明选择退出。如果您选择退出，您仍将收到有关您的帐户和我们的服务的非促销电子邮件。</li>
                                <li>在终止或停用您的服务后，我们可能（但没有义务）保留您的信息以进行档案目的。我们不会公开披露您的任何个人信息，除非在本隐私政策中另有说明。</li>
                                <li>根据我们的唯一决定权，出于任何原因或无原因，我们保留删除任何内容或消息的权利，如果我们认为这样做是必要的：(a) 符合法律，遵守送达给我们的法律程序，或调查，防止或采取行动以针对涉嫌或实际非法活动；（b）执行本政策，采取防止责任，调查和捍卫我们自己针对任何第三方索赔或指控的措施，协助政府执法机构，或保护我们网站的安全性或完整性；或（c）行使或保护本网站、我们的用户或他人的权利、财产或个人安全。</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>我们如何分享您的信息</h2>
                            <ul className='list-text'>
                                <li>作为一项政策，我们不会出售或出租有关您的信息，也不会以与本隐私政策不一致的方式披露有关您的信息，除非法律或政府法规要求。我们配合执法调查，以及其他第三方，执行与知识产权、欺诈和其他个人权利相关的法律。

                                </li>
                                <li>未经您的同意，我们不会将收集到的有关您的个人信息与任何第三方分享，用于其自己的营销目的。我们与第三方签订合同，禁止他们分享您的个人信息。

                                </li>
                                <li>如果您不同意我们的隐私政策或服务条款，请停止使用我们的服务；您继续使用本服务将表示您同意并接受我们的隐私政策和使用条款。

                                </li>
                                <li className="font-bold">我们可以（并且您授权我们）向执法部门、其他政府官员、任何诉讼或任何其他我们认为根据自己的唯一判断在调查欺诈、知识产权侵权或其他非法活动时必要或适当的第三方披露您的任何信息，该活动可能使我们或您承担责任。

                                </li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>确保信息准确和及时更新</h2>
                            <ul className='list-text'>
                                <li>我们采取合理的预防措施，确保我们收集、使用和披露的个人信息是完整、相关和及时更新的。然而，该信息的准确性在很大程度上取决于您提供的信息。因此，我们建议您：

                                </li>
                                <li>如果您的个人信息有任何错误，请告诉我们；</li>
                                <li>及时告知我们您个人信息的变化，例如您的姓名或地址。

                                </li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>我们如何保护您的信息</h2>
                            <ul className='list-text'>
                                <li>我们非常关注保护您个人数据的机密性。我们采用行政、物理和电子措施来保护您的信息免受未经授权的访问。

                                </li>
                                <li>通过使用本网站或服务或向我们提供个人信息，您同意我们可以与您通过电子方式沟通，讨论与您使用本网站或服务相关的安全、隐私和管理问题。

                                </li>
                                <li>我们采用商业上合理的物理、管理和技术保障措施来维护您的个人信息的完整性和安全性。然而，我们无法保证您传输给我们的任何信息的安全性，您自行承担此风险。一旦我们收到您的信息传输，我们会采取商业上合理的努力来确保我们系统的安全性。然而，请注意，这并不能保证这些信息不会被任何我们的物理、技术或管理保障措施的违规行为访问、披露、更改或销毁。如果我们得知安全系统存在违规行为，我们可能会尝试通过电子方式通知您，以便您采取适当的保护措施。

                                </li>
                                <li>尽管本政策中有相反规定，但如果我们相信合理需要遵守法律、法规或法律要求；保护任何人的安全；解决欺诈、安全或技术问题；或保护我们的权利或财产，我们可能会保留或披露您的信息。然而，本政策中的任何内容均不意味着限制您可能对第三方（包括政府）要求披露您信息的任何法律防御或异议。

                                </li>
                                <li>我们强烈建议您不要与本网站上的任何其他用户分享关于您的银行账户详细信息或其他机密信息，在这种情况下，您应当对此负个人责任。在这种情况下，我们不承担任何责任或责任。

                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>关于您信息的选择</h2>
                            <ul className='list-text'>
                                <li>您在我们的服务中有几种选择可用于信息的使用：
                                </li>
                                <li>电子邮件和电话号码沟通：我们定期向您发送免费的新闻简报和电子邮件，直接促进我们网站或服务的使用。当您收到我们的新闻简报或推广性通信时，您可以表明希望停止接收来自我们的进一步通信，您将有机会通过按照您收到的电子邮件中提供的退订说明进行“取消订阅”或直接与我们联系。尽管您表明了电子邮件偏好，我们可能会向您发送与服务相关的通信，包括我们的使用条款或隐私政策的任何更新通知。
                                </li>
                                <li>更改或删除您的个人数据：您可以通过访问网站并按照其中的说明或通过电子邮件与我们联系来更改您的任何个人信息。您可以要求我们删除您的个人数据，我们将尽商业上的合理努力来遵守您的要求，但请注意，我们可能会被要求保留此类信息并不删除它（或在一定时间内保留此信息，此时我们将仅在满足此类要求后才遵守您的删除请求）。当我们删除任何信息时，它将从活动数据库中删除，但可能仍然保存在我们的档案中。我们也可能出于防止欺诈或类似目的而保留您的信息。
                                </li>
                                <li>当然，您可以拒绝通过网站提交个人数据，在这种情况下，我们可能无法向您提供某些服务。您可以通过编辑您的帐户设置或直接与我们联系来查看和更正我们保留的有关您的信息。
                                </li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>儿童隐私</h2>
                            <p className='privacy-policy-p-text'>
                                保护年幼儿童的隐私尤为重要。因此，我们不会有意地收集或索取任何未满18岁的人的个人信息，也不会允许这样的人注册。如果您未满18岁，请不要尝试注册服务或向我们发送任何关于自己的信息，包括您的姓名、地址、电话号码或电子邮件地址。18岁以下的人不得提供任何个人数据以访问本服务。如果我们得知我们已经收集了未满18岁的儿童的个人数据，我们将尽快删除该信息。如果您认为我们可能持有或关于18岁以下儿童的任何信息，请联系我们。
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>合并与收购</h2>
                            <p className='privacy-policy-p-text'>
                                在合并或收购的情况下，我们保留将存储在我们处的所有信息，包括个人数据，转移给新形成的实体或公司的权利。任何网站政策和地位的变化将通过电子邮件通知您。
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>链接到第三方网站</h2>
                            <p className='privacy-policy-p-text'>
                                我们的网站包含指向其他网站的链接。我们提供链接到某个网站并不意味着我们对该第三方的认可、授权或附属关系。我们不对第三方网站行使控制权。这些其他网站可能在您的计算机上放置自己的 cookie 或其他文件，收集数据或索取个人信息。其他网站遵循与您提交给它们的个人信息使用或披露有关的不同规则。我们鼓励您阅读您访问的其他网站的隐私政策或声明。
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>通知程序</h2>
                            <p className='privacy-policy-p-text'>
                                我们的政策是通过电子邮件通知、书面或纸质通知，或通过在网站上醒目张贴通知的方式向您发送通知，无论是法律要求的通知还是营销或其他业务相关的通知，这些通知由我们自行决定的。我们保留决定向您提供通知的形式和方式的权利，但您可以根据本隐私政策中的描述选择退出某些通知方式。
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>不跟踪</h2>
                            <ul className='list-text'>
                                <li>“不跟踪”（“DNT”）是用户可以在某些网络浏览器中设置的隐私偏好。DNT是用户告知网站和服务，他们不希望在时间和跨网站或在线服务上收集关于他们网页访问的某些信息的一种方式。</li>
                                <li>我们致力于为您提供有意义的选择，以便针对第三方目的收集的信息，并且这就是为什么我们提供退出链接。然而，我们不认可或不响应浏览器发起的DNT信号，因为互联网行业目前仍在努力定义DNT的确切含义，遵守DNT意味着什么，以及对DNT做出响应的常见方法。</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>选择退出信息共享</h2>
                            <ul className='list-text'>
                                <li>我们理解并尊重并非所有用户都希望允许我们分享他们的信息。如果您不希望我们分享您的信息，请通过 [联系方式] 与我们联系，我们将尽快将您的信息删除。在联系我们时，请清楚说明您的请求，包括您的姓名、邮寄地址、电子邮件地址和电话号码。</li>
                                <li>然而，在以下情况下，我们仍可能被要求分享您的个人信息：</li>
                                <li>如果我们正在响应法院命令或法律程序，或者我们需要确立或行使我们的法律权利或对抗法律索赔。</li>
                                <li>如果我们认为有必要分享信息以调查、预防或采取行动针对非法活动、涉嫌欺诈、涉及潜在威胁任何人的生命安全、违反我们的使用条款或法律另有规定的情况。</li>
                                <li>如果我们认为有必要限制或阻止任何用户使用我们的任何网站，包括但不限于通过“黑客”或破坏其中的任何部分。</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>网络钓鱼或虚假邮件</h2>
                            <p className='privacy-policy-p-text'>
                                如果您收到一封未经请求的电子邮件，看起来是来自我们或我们的成员之一，要求提供个人数据（例如您的信用卡、登录信息或密码），或要求您通过点击链接验证或确认您的账户或其他个人信息，那么该电子邮件很可能是由试图非法获取您信息的人发送的，有时被称为“网络钓鱼者”或“欺诈者”。我们不会在电子邮件中要求提供此类信息。请不要提供信息或点击链接。如果您收到此类电子邮件，请使用网站上提供的联系方式与我们联系。
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>我们的隐私政策变更</h2>
                            <p className='privacy-policy-p-text'>
                                我们可能不时更新本隐私政策和信息安全程序。如果将来这些隐私和/或信息安全程序发生重大变化，我们将在网站上明显发布新的更改，以通知您，并提供您按照上述规定选择退出的能力。继续使用我们的网站和服务，表示您已经注意到此类更改并同意受到此类更改的条款和条件的约束。
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>隐私政策违规</h2>
                            <p className='privacy-policy-p-text'>
                                如果发现某个帐户或内容违反了我们的隐私政策，我们保留终止或暂停任何帐户，或从本网站的任何个人资料或公共领域中删除某些内容的权利。我们诚挚地请求您尊重他人的隐私和保密关注。任何违规或争议的司法管辖权应根据本网站的使用条款确定。
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>无保留</h2>
                            <p className='privacy-policy-p-text'>
                                我们不接受任何对我们的隐私政策的保留或任何类型的有限接受。您明确同意接受本政策中规定的每一条款和条件，没有任何例外。
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>无冲突</h2>
                            <p className='privacy-policy-p-text'>
                                该政策构成用户条款的一部分。我们已尽最大努力避免本政策与我们网站上其他条款、协议或指南产生任何不一致或冲突。如果存在冲突，我们请求您联系我们以获得最终的规定和解释。
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>联系我们</h2>
                            <p className='privacy-policy-p-text'>
                                如果您对本隐私政策、与我们网站相关的做法或您与我们的交易有任何疑问，请联系我们。
                            </p>
                        </div>


                        <div className="">
                            <p className='privacy-policy-p-text'>
                                © 2024 REWARD CLUB.MY. 保留所有权利。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy