import { Navigate } from "react-router-dom";

const PublicRestricGuard = (props) => {
  const user = localStorage.getItem("rewardClubUser");
  const isLoggedIn = JSON.parse(user);
  if (isLoggedIn && isLoggedIn.role === "member") {
    return <Navigate to="/member/mySubscription" />;
  } else if (isLoggedIn && isLoggedIn.role === "merchant") {
    return <Navigate to="/merchant/myDiscount" />;
  } else {
    return props.children;
  }
};

export default PublicRestricGuard;
