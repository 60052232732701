import Home from "../pages/Home";
import SignUp from "../pages/auth/SignUp";
import AboutUs from "../pages/cms/AboutUs";
import NotFound from "../pages/extra/NotFound";
import Partner from "../pages/auth/Partner";
import Discount from "../pages/discount/Discount";
import { Routes, Route } from "react-router-dom";
import SignIN from "../pages/auth/SignIn";
import DailyMembersGiveAways from "../pages/giveaways/DailyMembersGiveAways";
import MemberDrawWinners from "../pages/giveaways/MemberDrawWinners";
import Winners from "../pages/giveaways/Winners";
import DiscountDetails from "../pages/discount/DiscountDetails";
import TermsAndConditions from "../pages/cms/TermsAndConditions";
import MembershipTermsAndConditions from "../pages/cms/MembershipTermsAndConditions";
import PrivacyPolicy from "../pages/cms/PrivacyPolicy";
import Faqs from "../pages/cms/Faqs";
import GiveAways from "../pages/giveaways/GiveAways";
import UserSignup from "../pages/auth/UserSignup";
import UserSignMeUp from "../pages/auth/UserSignMeUp";
import MemberDashboard from "../pages/member/MemberDashboard";
import MerchantDashboard from "../pages/merchant/MerchantDashboard";
import EditMemberProfile from "../pages/member/EditMemberProfile";
import EditMerchantProfile from "../pages/merchant/EditMerchantProfile";
import UpcommingGiveaways from "../pages/member/UpcommingGiveaways";
import MyJoinGiveaways from "../pages/member/MyJoinGiveaways";
import MySubScription from "../pages/member/MySubScription";
import MyDiscounts from "../pages/merchant/MyDiscounts";
import MasterHeader from "../components/MasterHeader";
import EditDiscount from "../pages/merchant/discount/EditDescount";
import MemberGuard from "../components/guards/MemberGuard";
import MerchantGuard from "../components/guards/MerchantGuard";
import PublicRestricGuard from "../components/guards/PublicRestricGuard";
import PaymentSuccess from "../pages/callback/PaymentSuccess";
import PaymentFail from "../pages/callback/PaymentFail.jsx";
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function AppRouter() {
  return (
    <Routes>
      {/* Member Routes */}
      <Route
        path="/member/dashboard"
        element={
          <MemberGuard>
            <MemberDashboard />
          </MemberGuard>
        }
      />
      <Route
        path="/member/upcommingGiveaways"
        element={
          <MemberGuard>
            <UpcommingGiveaways />
          </MemberGuard>
        }
      />
      <Route
        path="/member/myJoinedGiveaways"
        element={
          <MemberGuard>
            <MyJoinGiveaways />
          </MemberGuard>
        }
      />
      <Route
        path="/member/mySubscription"
        element={
          <MemberGuard>
            <MySubScription />
          </MemberGuard>
        }
      />
      <Route
        path="/member/profile"
        element={
          <MemberGuard>
            <EditMemberProfile />
          </MemberGuard>
        }
      />

      {/* Mercahnt Routes */}

      <Route
        path="merchant/master-dashboard"
        element={
          <MerchantGuard>
            <MasterHeader />
          </MerchantGuard>
        }
      />
      <Route
        path="merchant/dashboard"
        element={
          <MerchantGuard>
            <MerchantDashboard />
          </MerchantGuard>
        }
      />
      {/* <Route
        path="merchant/myDiscount"
        element={
          <MerchantGuard>
            <MyDiscounts />
          </MerchantGuard>
        }
      /> */}
      <Route
        path="merchant/profile"
        element={
          <MerchantGuard>
            <EditMerchantProfile />
          </MerchantGuard>
        }
      />
      <Route
        path="edit-discount/:id"
        element={
          <MerchantGuard>
            <EditDiscount />
          </MerchantGuard>
        }
      />
      <Route
        path="/merchant/myJoinedGiveaways"
        element={
          <MerchantGuard>
            <MyJoinGiveaways />
          </MerchantGuard>
        }
      />
      <Route
        path="/merchant/mySubscription"
        element={
          <MerchantGuard>
            <MySubScription />
          </MerchantGuard>
        }
      />
      {/* Public Routes */}
      <Route path="/" element={<PublicRestricGuard><Home /></PublicRestricGuard>} />
      <Route path="signup" element={<SignUp />} />
      <Route path="user-signup" element={<GoogleOAuthProvider clientId="1066471456951-jvb9nma0ab3rglfv4v7vciam8qd8ker0.apps.googleusercontent.com"><UserSignup /></GoogleOAuthProvider>} />
      <Route path="user-signmeup" element={<UserSignMeUp />} />
      <Route path="login" element={<SignIN />} />
      <Route path="home" element={<PublicRestricGuard><Home /></PublicRestricGuard>} />
      <Route path="about" element={<AboutUs />} />
      <Route path="discount" element={<Discount />} />
      <Route path="discount-details/:id" element={<DiscountDetails />} />
      {/* <Route path="giveaways" element={<GiveAways />} /> */}
      <Route
        path="upcoming-members-giveaways"
        element={<DailyMembersGiveAways />}
      />
      <Route path="member-draw-giveaways" element={<MemberDrawWinners />} />
      <Route path="winners-giveaways" element={<Winners />} />
      <Route path="partner" element={<Partner />} />
      <Route path="partner/:id" element={<Partner />} />
      <Route path="terms-conditions" element={<TermsAndConditions />} />
      <Route
        path="membership-terms-conditions"
        element={<MembershipTermsAndConditions />}
      />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="faqs" element={<Faqs />} />
      <Route path="payment/success" element={<PaymentSuccess />} />
      <Route path="payment/error" element={<PaymentFail />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
