import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { MemberContext } from "../../context/MemberContext";
import CountdownTimer from "../../components/Timer";
import moment from "moment";

const NearestGiveaway = () => {
  const { language } = useContext(AuthContext);
  const { giveaways } = useContext(MemberContext);
  const translations = require(`../../lang/common/common_${language}.json`);

  const [nearestGiveaway, setNearestGiveaway] = useState(null);

  useEffect(() => {
    const now = new Date();
    let nearestTimeDiff = Infinity;

    giveaways.forEach((giveaway) => {
      const giveawayDate = moment(giveaway.giveaway_date, "DD/MM/YYYY hh:mm A").toDate();
      const timeDiff = Math.abs(giveawayDate - now);

      if (timeDiff < nearestTimeDiff && giveaway.is_winners_declared === false) {
        setNearestGiveaway(giveaway);
        nearestTimeDiff = timeDiff;
      }
    });
  }, [giveaways]);
  
  return (
    <div className="flex items-center justify-center bg-white min-h-[80vh]">
      <div className="p-4">
        <div className="flex flex-col h-auto md:flex-row items-center bg-white justify-center text-left">
          <div className="md:w-1/2 relative">
            <img
              src={nearestGiveaway?.image || "/assets/giveaway/placeholder.png"}
              alt="Promo"
              className="w-full h-50 scale-[0.8]"
            />
          </div>
          <div className="md:w-1/2 relative z-10 p-6 md:p-6 lg:p-16">
            <div className="mt-6 md:mt-6 lg:mt-6 flex items-center justify-center flex-col gap-8">
              <div className="bg-primary text-white p-4 text-center">
                <h2 className="text-2xl font-bold ">
                  {translations.home.upcomingGiveaways.content.upcomingTitle}
                </h2>
              </div>
              {nearestGiveaway?.value && (
                <p className="text-black text-xl mb-2 font-bold">
                  {translations.home.upcomingGiveaways.content.cashAmount$}
                  {nearestGiveaway.value}{" "}
                  {translations.home.upcomingGiveaways.content.cashAmount}
                </p>
              )}
              {nearestGiveaway?.value && (
                <p className="text-4xl text-center color-primary font-bold">
                  {translations.home.upcomingGiveaways.content.valuedAt}
                  {nearestGiveaway.value}
                </p>
              )}
              {nearestGiveaway?.giveaway_date && (
                <CountdownTimer date={nearestGiveaway?.giveaway_date} />
              )}
            </div>
          </div>
        </div>
        {/* <div className="flex justify-center">
          <button className="bg-primary hover:bg-primary-dark text-white py-5 px-12 rounded mt-10 mb-4 font-bold justify-center">
            {translations.home.upcomingGiveaways.content.watchDrawButton}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default NearestGiveaway;
