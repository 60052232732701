import React, { useContext, useState } from 'react';
import SignUpPopUp from "./SignUpPopUp";
import { AuthContext } from '../context/AuthContext';
import { MemberContext } from '../context/MemberContext';

const Articles = () => {
    const { language, selectedPlan, setSelectedPlan, setIsUserSignupModalOpen } = useContext(AuthContext);
    const { articles } = useContext(MemberContext);
    const translations = require(`../lang/common/common_${language}.json`);

    return (
        <div className="bg-gray-100  px-3 py-10 md:px-10">
            <div className="h-full">
                <div className="flex justify-center">
                    <h2 className="section-header">{translations.home.latestArticles.sectionHeader}</h2>
                </div>
                <div className="flex justify-center mt-5">
                    <div className='text-s font-bold'>{translations.home.latestArticles.readMoreText}</div>
                </div>

                <div className="mt-10">

                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 ml-6 mr-8">
                        {/* Article Cards */}
                        {articles?.map((art, index) => {
                            const titleKey = `title_${language}`;
                            const descKey = `description_${language}`;

                            return (
                                <div key={index} className="p-4 drop-shadow bg-white">
                                    <img
                                        className="h-50 w-full object-cover mb-2"
                                        src={art?.image || "https://via.placeholder.com/400x250"}
                                        style={{height:250, width:400}}
                                        alt="Article"
                                    />
                                    <h3 className="text-lg font-semibold mb-1 mt-3 color-primary">{art[titleKey]}</h3>
                                    <p className="text-sm text-gray-700">
                                        {art[descKey]}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {/*<div className='flex justify-center mt-5'>*/}
            {/*    <button*/}
            {/*        className="bg-primary hover:bg-primary hover:text-white text-white py-3 px-5 rounded">*/}
            {/*        View All Articles*/}
            {/*    </button>*/}
            {/*</div>*/}
        </div>  
    );
};

export default Articles;
