

const PrivacyPolicy = () => {

    return (
        <>
            <div className="privacy-policy-page privacy-policy-bg-main md:mt-16 sm:mt-16 lg:mt-20">
                <div className="flex bg-cover bg-center privacy-policy-page privacy-policy-bg-inner">
                    <div className="container mx-auto privacy-policy-bg-inner-style" >

                        <div className="mb-6">
                            <div className=" flex justify-center">
                                <h2 className="section-header mb-10">PRIVACY POLICY</h2>
                            </div>
                            <p className='privacy-policy-p-text'>
                                REWARD CLUB.MY having registered office in Kuala Lumpur, Malaysia, is committed to protecting your privacy. We have prepared this Privacy Policy to describe to you our practices regarding the Personal Information we collect from users of our website.
                            </p>

                            <p className='privacy-policy-p-text'>
                                By using the Services, users consent to the collection and use of their Personal Information by us. You also represent to us that you have any and all authorisations necessary to use these Services including using them to process Personal Information. We collect and use the information you provide to us, including information obtained from your use of the Services. Also, we may use the information that we collect for our internal purposes to develop, tune, enhance, and improve our Services, and for advertising and marketing consistent with this Privacy Policy
                            </p>

                        </div>

                        <div className="mb-6">
                            <p className='privacy-policy-p-text'>
                                Please read our privacy policy carefully to get a clear understanding of how our website collects, uses, protects or otherwise handles users’ Personal Information.

                            </p>

                            <p className='privacy-policy-p-text'>

                                This Privacy Policy is intended to inform users about how our website treats Personal Information that it processes about users. If users do not agree to any part of this Privacy Policy, then we cannot provide its Services to users and users should stop accessing our services.

                            </p>

                            <p className='privacy-policy-p-text'>
                                By using the Services, You acknowledge, consent and agree that we may collect, process, and use the information that you provide to us and that such information shall only be used by us or third parties acting under our direction, pursuant to confidentiality agreements, to develop, tune, enhance, and improve the Services.

                            </p>

                            <p className='privacy-policy-p-text'>
                                Although we may attempt to notify you when changes are made to this Privacy Policy, you are responsible for periodically reviewing any changes which may be made to the Policy. We may, in our sole discretion, modify or revise the Policy at any time, and you agree to be bound by such modifications or revisions.

                            </p>

                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>DEFINITIONS</h2>
                            <ul className='list-text'>
                                <li>“Collect” means gather, acquire or obtain by a lawful and fair means, information in circumstances where the individual is identifiable or identified.

                                </li>
                                <li>“Express Consent Consequences” includes the fact that that we will not be accountable under the Privacy Act and you will not be able to seek redress under the Privacy Act in the event that you provide consent to the disclosure of your Personal Information by us to an overseas recipient and the overseas recipient handles your Personal information in breach of the Privacy Act.

                                </li>
                                <li> “Expressly Informed” means the circumstance where we have provided you with a clear statement (either verbal or in writing) of the Express Consent Consequences.
                                    • “Opt Out” means an individual’s expressed request not to receive further Direct Marketing communications.

                                </li>
                                <li>“Opt Out” means an individual’s expressed request not to receive further Direct Marketing communications.
                                </li>
                                <li>“Personal Information” means information that is not Sensitive Information, including information or an opinion (whether information or an opinion forming part of a database), whether true or not and whether recorded in a material form or not, about an individual whose identity is apparent, or can reasonably be ascertained from the information or opinion. This includes, but is not limited to, an individual’s first name, last name, email address, phone number, password and address.
                                </li>
                                <li>“Primary Purpose” is the main reason for the Collection of any Personal Information.

                                </li>
                                <li> “Reasonable Expectation” means a reasonable individual’s expectation that their personal information might be Used or Disclosed for the relevant purpose.

                                </li>
                                <li>“Secondary Purpose” means a purpose of Use or Disclosure other than a Primary Purpose.

                                </li>
                                <li>“Sensitive Information” is given its meaning in section 6(1) of the Privacy Act 1988 (Cth).

                                </li>
                                <li>“Use” means the handling of Personal Information by our Company.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>INFORMATION WE
                                COLLECT</h2>
                            <ul className='list-text'>
                                <li>When you register with us, you provide us information about yourself – your name, email address, phone number etc. If you correspond with us by e-mail, we may retain the content of your e-mail messages, your e-mail address, and our responses. We also collect general information about your use of our services.

                                </li>
                                <li>By entering your email and phone number on our website, you may receive marketing material from us. This includes, but is not limited to, promotional offers, newsletters, and information about new products or services. We may use the information you provide us to contact you via email or phone, even if you do not submit any forms. If you do not wish to receive marketing material from us, please let us know by emailing us or by unsubscribing from our emails.

                                </li>
                                <li>While interacting with other users on the Forum or Interactive Area, you share your contact information, the messages which you share are not private and can be viewed by any member or user of the website.

                                </li>
                                <li>The advertisement and other related details that you post on the website and the information which you share therein are collected by us.

                                </li>
                                <li>INFORMATION WE COLLECT AUTOMATICALLY WHEN YOU USE OUR SERVICES
                                </li>
                                <li>When you access or use our Services, we automatically collect information about you, including:

                                </li>
                                <li>Log Information: We log information about your use of our website, including your browser type and language, access times, pages viewed, your IP address and the website you visited before navigating to our website.

                                </li>
                                <li>Device Information: We may collect information about the device you use to access our Services, including the hardware model, operating system and version, unique device identifier, phone number, International Mobile Equipment Identity (“IMEI”) and mobile network information.

                                </li>
                                <li> Location Information: With your consent, we may collect information about the location of your device to facilitate your use of certain features of our Services, determine the speed at which your device is traveling, add location-based filters (such as local weather), and for any other purposes.

                                </li>
                                <li>Information Collected by Cookies and Other Tracking Technologies: We use various technologies to collect information, and this may include sending cookies to you. A “cookie” is a small data file transferred to your computer’s hard drive that allows a Website to respond to you as an individual, gathering and remembering information about your preferences in order to tailor its operation to your needs, likes and dislikes. Overall, cookies are safe, as they only identify your computer to customize your Web experience. Accepting a cookie does not provide us access to your computer or any Personal Information about you, other than the information you choose to share. Other servers cannot read them, nor can they be used to deliver a virus. Most browsers automatically accept cookies, but you can usually adjust yours (Microsoft Internet Explorer, Firefox or Google Chrome) to notify you of cookie placement requests, refuse certain cookies, or decline cookies completely. If you turn off cookies completely, there may be some website features that will not be available to you, and some Web pages may not display properly. To support the personalized features of our website (such as your country and language codes and browsing functions) we must send a cookie to your computer’s hard drive and/or use cookie-based authentication to identify you as a registered website user.

                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>INFORMATION SHARED WHILE POSTING ON
                                VARIOUS PLATFORMS OF THE WEBSITE
                            </h2>
                            <ul className='list-text'>
                                <li>While interacting with other users on the Forum/interactive area, you share your contact information; the messages which you share are not private and can be viewed by any member or user of the website.

                                </li>
                                <li>The advertisements and related material that you post on the website and the information which you share therein are collected by us.

                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>INFORMATION SHARED
                                DURING TRANSACTION
                            </h2>
                            <ul className='list-text'>
                                <li>You agree that you will enter into transactions with third parties through our website and will share your Personal Information with them for easy completion of the transaction. You hereby expressly agree that we shall not be involved or held liable for any breach of the privacy or security of that data. The said breach, if any, shall be a matter of dispute between you and the third party and we shall not be held liable or be issued a notice for the same.

                                </li>
                                <li>We STRONGLY recommend that you should be careful and vigilant while disclosing your Personal Information with your transaction partner. Please do not disclose your bank and account details to anyone through our website or to any individual whom you have met through our website.
                                </li>
                                <li>We do not take credit card/debit card or other bank details on our website. For payment you will be directed to sign in through the respective payment gateways integrated on our website and the transaction would be completed therein. It is to be noted that we will not be storing any Bank related information on our records and none of our staffs will hold or be exposed to this information.

                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>HOW WE USE YOUR INFORMATION
                            </h2>
                            <ul className='list-text'>
                                <li>We use the personal information we collect to fulfil your requests for services, improve our services and contact you.

                                </li>
                                <li>By typing into any form and providing us your e-mail address and or mobile number, you consent to us using the e-mail address and mobile number to send you our website and services related notices, including any notices required by law, in lieu of communication by postal mail. You also agree that we may send notifications of activity on our website to the e-mail address or mobile device you give us, in accordance with any applicable privacy settings. We may use your e-mail address to send you other messages, such as newsletters, changes to our features, or other information. If you do not want to receive optional e-mail messages, you may modify your settings to opt out.

                                </li>
                                <li>Our settings may also allow you to adjust your communications preferences. If you do not wish to receive promotional email messages from us, you may opt out by following the unsubscribe instructions in those emails. If you opt out, you will still receive non-promotional emails from us about your account and our Services.

                                </li>
                                <li>Following termination or deactivation of your services, we may (but are under no obligation to) retain your information for archival purposes. We will not publicly disclose any of your Personal Information other than as described in this Privacy Policy.

                                </li>
                                <li>At our sole discretion, for any reason or no reason at all, we reserve the right to remove any content or messages, if we believe that such action is necessary (a) to conform to the law, comply with legal process served on us, or investigate, prevent, or take action regarding suspected or actual illegal activities; (b) to enforce this policy, to take precautions against liability, to investigate and defend ourselves against any third-party claims or allegations, to assist government enforcement agencies, or to protect the security or integrity of our website; or (c) to exercise or protect the rights, property, or personal safety of the website, our users, or others.

                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>HOW WE SHARE YOUR INFORMATION</h2>
                            <ul className='list-text'>
                                <li>As a matter of policy, we will not sell or rent information about you and we will not disclose information about you in a manner inconsistent with this Privacy Policy except as required by law or government regulation. We co-operate with law enforcement inquiries, as well as other third parties, to enforce laws such as those regarding intellectual property rights, fraud and other personal rights.

                                </li>
                                <li>We will not share the personal information we collect about you with any third party for their own marketing purposes without your consent. We have contract with third parties prohibiting them from sharing your Personal Information.

                                </li>
                                <li> If you do not agree with our Privacy Policy or Terms of Service, please discontinue use of our Service; your continued usage of the Service will signify your assent to and acceptance of our Privacy Policy and Terms of Use.

                                </li>
                                <li className="font-bold">WE CAN (AND YOU AUTHORISE US TO) DISCLOSE ANY INFORMATION ABOUT YOU TO LAW ENFORCEMENT, OTHER GOVERNMENT OFFICIALS, ANY LAWSUIT OR ANY OTHER THIRD PARTY THAT WE, IN OUR SOLE DISCRETION, BELIEVE NECESSARY OR APPROPRIATE IN CONNECTION WITH AN INVESTIGATION OF FRAUD, INTELLECTUAL PROPERTY INFRINGEMENT, OR OTHER ACTIVITY THAT IS ILLEGAL OR MAY EXPOSE US, OR YOU, TO LIABILITY.

                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>ENSURING INFORMATION IS
                                ACCURATE AND UP TO DATE</h2>
                            <ul className='list-text'>
                                <li>We take reasonable precautions to ensure that the Personal Information we Collect, Use and Disclose is complete, relevant and up-to-date. However, the accuracy of that information depends to a laRCMe extent on the information you provide. That’s why we recommend that you:

                                </li>
                                <li>Let us know if there are any errors in your Personal Information; and</li>
                                <li>Keep us up-to-date with changes to your Personal Information such as your name or address.

                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>HOW WE PROTECT YOUR
                                INFORMATION</h2>
                            <ul className='list-text'>
                                <li>We are very concerned about safeguarding the confidentiality of your personal data. We employ administrative, physical and electronic measures designed to protect your information from unauthorised access.

                                </li>
                                <li>By using this website or the Services or providing Personal Information to us, you agree that we can communicate with you electronically regarding security, privacy, and administrative issues relating to your use of this website or Services.

                                </li>
                                <li>We use commercially reasonable physical, managerial, and technical safeguards to preserve the integrity and security of your Personal Information. We cannot, however, ensure or warrant the security of any information you transmit to us and you do so at your own risk. Once we receive your transmission of information, we make commercially reasonable efforts to ensure the security of our systems. However, please note that this is not a guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. If we learn of a security systems breach, then we may attempt to notify you electronically so that you can take appropriate protective steps.

                                </li>
                                <li>Notwithstanding anything to the contrary in this Policy, we may preserve or disclose your information if we believe that it is reasonably necessary to comply with a law, regulation or legal request; to protect the safety of any person; to address fraud, security or technical issues; or to protect our rights or property. However, nothing in this Policy is intended to limit any legal defences or objections that you may have to a third party, including a government’s, request to disclose your information.

                                </li>
                                <li>We strictly advise you not to share any personal information regarding your bank account details or other confidential information with any other user on the website and in case you do so, you shall be personally responsible for the same. We shall in no such case be held responsible or liable for the same.

                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>YOUR CHOICES ABOUT
                                YOUR INFORMATION</h2>
                            <ul className='list-text'>
                                <li>You have several choices regarding use of information on our Services:
                                </li>
                                <li>Email and Phone Number Communications: We will periodically send you free newsletters and e- mails that directly promote the use of our website, or Services. When you receive newsletters or promotional communications from us, you may indicate a preference to stop receiving further communications from us and you will have the opportunity to “opt-out” by following the unsubscribe instructions provided in the e-mail you receive or by contacting us directly. Despite your indicated e-mail preferences, we may send you service related communications, including notices of any updates to our Terms of Use or Privacy Policy.

                                </li>
                                <li>Changing or Deleting Your Personal Data: You may change any of your personal information by visiting the website and following the directions therein or by emailing us. You may request deletion of your personal data by us, and we will use commercially reasonable efforts to honour your request, but please note that we may be required to keep such information and not delete it (or to keep this information for a certain time, in which case we will comply with your deletion request only after we have fulfilled such requirements). When we delete any information, it will be deleted from the active database, but may remain in our archives. We may also retain your information for fraud prevention or similar purposes.

                                </li>
                                <li>You may, of course, decline to submit personal data through the website, in which case, we may not be able to provide certain services to you. You can review and correct the information about you that we keep on file by editing your account settings or by contacting us directly.

                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>CHILDREN’S
                                PRIVACY</h2>
                            <p className='privacy-policy-p-text'>
                                Protecting the privacy of young children is especially important. Thus, we do not knowingly collect or solicit personal information from anyone under the age of 18 or knowingly allow such persons to register. If you are under 18, please do not attempt to register for the Service or send any information about yourself to us, including your name, address, phone number, or email address. No one under age 18 may provide any personal data for accessing the Service. In the event that we learn that we have collected personal data from a child under age 18, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under the age of 18, please contact us.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>MERGERS AND
                                ACQUISITIONS</h2>
                            <p className='privacy-policy-p-text'>
                                In case of a merger or acquisition, we reserve the right to transfer all the information, including personal data, stored with us to the new entity or company thus formed. Any change in the website’s policies and standing will be notified to you through email.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>LINKS TO THIRD PARTY
                                WEBSITES</h2>
                            <p className='privacy-policy-p-text'>
                                Our website contains links to other websites. The fact that we link to a website is not an endorsement, authorisation or representation of our affiliation with that third party. We do not exercise control over third party websites. These other websites may place their own cookies or other files on your computer, collect data or solicit personal information from you. Other sites follow different rules regarding the use or disclosure of the personal information you submit to them. We encourage you to read the privacy policies or statements of the other websites you visit.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>NOTIFICATION
                                PROCEDURES</h2>
                            <p className='privacy-policy-p-text'>
                                It is our policy to provide notifications, whether such notifications are required by law or are for marketing or other business related purposes, to you via e-mail notice, written or hard copy notice, or through conspicuous posting of such notice on the website, as determined by us in our sole discretion. We reserve the right to determine the form and means of providing notifications to you, provided that you may opt out of certain means of notification as described in this Privacy Policy.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>DO NOT TRACK</h2>
                            <ul className='list-text'>
                                <li>Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. DNT is a way for users to inform website and services that they do not want certain information about their webpage visits collected over time and across websites or online services.

                                </li>
                                <li>We are committed to providing you with meaningful choices about the information collected on our website for third party purposes, and that is why we provide the opt-out links. However, we do not recognise or respond to browser-initiated DNT signals, as the Internet industry is currently still working toward defining exactly what DNT means, what it means to comply with DNT, and a common approach to responding to DNT.

                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>OPTING OUT OF
                                INFORMATION SHARING</h2>
                            <ul className='list-text'>
                                <li>We understand and respect that not all users may want to allow us to share their information. If you do not want us to share your information, please contact us through and we will remove your information as soon as reasonably practicable. When contacting us, please clearly state your request, including your name, mailing address, email address and phone number.

                                </li>
                                <li>However, under the following circumstances, we may still be required to share your personal information:

                                </li>
                                <li>if we are responding to court orders or legal process, or if we need to establish or exercise our legal rights or defend against legal claims.

                                </li>
                                <li>If we believe it is necessary to share information in order to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Terms of Use or as otherwise required by law.

                                </li>
                                <li>If we believe it is necessary to restrict or inhibit any user from using any of our Site, including, without limitation, by means of “hacking” or defacing any portion thereof.
                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>PHISHING OR FALSE
                                EMAILS</h2>
                            <p className='privacy-policy-p-text'>
                                If you receive an unsolicited email that appears to be from us or one of our members
                                that requests personal data (such as your credit card, login, or password), or that asks
                                you to verify or confirm your account or other personal information by clicking on a
                                link, that email was likely to have been sent by someone trying to unlawfully obtain
                                your information, sometimes referred to as a “phisher” or “spoofer.” We do not ask for
                                this type of information in an email. Do not provide the information or click on the
                                link. Please contact us on the contact details provided on the website if you get an
                                email like this.
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>CHANGES TO OUR
                                PRIVACY POLICY</h2>
                            <p className='privacy-policy-p-text'>
                                We may update this Privacy Policy and information security procedures from time to time.
                                If these privacy and/or information security procedures materially change at any time in
                                the future, we will post the new changes conspicuously on the website to notify you and
                                provide you with the ability to opt out in accordance with the provisions set forth
                                above.
                                Continued use of our website and Service, following notice of such changes shall
                                indicate your acknowledgement of such changes and agreement to be bound by the terms and
                                conditions of such changes.
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>BREACH OF PRIVACY
                                POLICY</h2>
                            <p className='privacy-policy-p-text'>
                                We reserve the right to terminate or suspend any account or delete certain contents from
                                any profile or public domain within the ambit of this website if the said account or
                                content is found to be in violation of our privacy policy. We sincerely request you to
                                respect privacy and secrecy concerns of others. The jurisdiction of any breach or
                                dispute shall be determined in accordance with the terms of use of the website.
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>NO RESERVATIONS</h2>
                            <p className='privacy-policy-p-text'>
                                We do not accept any reservation or any type of limited acceptance of our privacy
                                policy. You expressly agree to each and every term and condition as stipulated in this
                                policy without any exception whatsoever.
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>NO CONFLICT</h2>
                            <p className='privacy-policy-p-text'>
                                The policy constitutes a part of the user terms. We have taken utmost care to avoid any
                                inconsistency or conflict of this policy with any other terms, agreements or guidelines
                                available on our website. In case there exists a conflict, we request you to kindly
                                contact us for the final provision and interpretation.
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>CONTACT US</h2>
                            <p className='privacy-policy-p-text'>
                                If you have any questions about this Privacy Policy, our practices relating to the
                                website, or your dealings with us, please contact us at.
                            </p>
                        </div>

                        <div className="">
                            <p className='privacy-policy-p-text'>
                                © 2024 REWARD CLUB.MY. All Rights Reserved
                            </p>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy