import React, { useEffect, useState } from "react";
import { getRequest } from "../utils/helper";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";

const VoucherModal = (props) => {
  const { disId, isModalOpen, setIsModalOpen, language } = props;
  const { user } = useContext(AuthContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${user?.token}`,
      Accept: "application/json",
      ContentType: "application/json",
    };
    getRequest(`/discounts/${disId}`, undefined, headers).then((response) => {
      setData(response.data);
    });
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goToStore = async (url, coupon_code) => {
    await navigator.clipboard.writeText(coupon_code);
    window.open(url, "_blank");
  };

  return (
    <div className="flex justify-center items-center h-screen">
      {isModalOpen && data && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="relative bg-white p-2 rounded-lg shadow-xl">
              <div className="absolute top-0 right-0 pt-2 pr-4">
                <button
                  className="text-gray-400 hover:text-gray-800"
                  onClick={closeModal}
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div style={{ display: "flex" }}>
                <div className="flex flex-col md:flex-row w-full justify-between bg-white mb-2">
                  <div className="mt-4 flex flex-col justify-center" style={{ flex: "10", }} >
                    <p className="" style={{ alignSelf: "center", color: "red" }} >
                      {data[`title_${language}`]}
                    </p>
                    <img
                      src={ data?.user?.image || "/assets/discount/placeholder.png" }
                      alt="Logo"
                      className="w-20 h-auto self-center md:self-start"
                      style={{ paddingTop: "30px" }}
                    />
                  </div>
                  <div className="flex flex-col items-center justify-center" style={{ flex: "70" }}>
                    <p className="text-black text-lg mb-2">
                      {data[`short_description_${language}`]}
                    </p>
                    <p className="text-black text-lg mb-2">
                      {data[`long_description_${language}`]}
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ backgroundColor: "red", height: "2px", width: "100%" }}></div>
              <div style={{ backgroundColor: "white", height: "70vh" }}>
                {data.coupon_code ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "white",
                        maxWidth: "70vw", maxHeight: "70vh",
                        border: "1px solid black",
                        borderRadius: "15px",
                        margin: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {data.promo_pic || data.qr_pic ? (
                        <img
                          src={data.promo_pic || data.qr_pic}
                          alt={data.promo_pic ? "promo" : "qr"}
                          className="object-contain m-2"
                          style={{ width:"400px", height: "300px" }}
                          title={data.promo_pic ? "promo" : "qr"}
                        />
                      ) : (
                        <h4
                          style={{
                            padding: "8px",
                            fontWeight: "bold",
                            width: "",
                          }}
                        >
                          {data?.coupon_code || ""}
                        </h4>
                      )}
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <button
                        className="bg-red-500 text-white px-10 py-1 rounded-2xl border-none justify-center"
                        onClick={() => goToStore(data.url, data.coupon_code)}
                      >
                        Copy Code & Go to Store
                      </button>
                    </div>

                    <div className="mt-2" style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <p>Go to Store by click on above button and paste the code at checkout.</p>
                    </div>
                  </div>
                ) : null}

                <div className="mx-auto mt-4">
                  <div className="flex justify-center items-center">
                    {data[`terms_${language}`]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VoucherModal;
