import React, { createContext, useContext, useEffect, useState } from "react";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../utils/helper";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const MerchantContext = createContext();

export const MerchantProvider = ({ children }) => {
  const [merchant, setMerchant] = useState(null);
  const { user } = useContext(AuthContext);
  const [merchantDiscountData, setMerchantDiscountData] = useState([]);
  const [singleDiscountData, setSingleDiscountData] = useState({});
  const navigate = useNavigate();
  // Function to fetch merchant discount data
  const getMerchantDiscount = async (token) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      const params = {};
      const response = await getRequest("/merchant-discounts", params, headers);
      setMerchantDiscountData(response.data); // Store the fetched FAQs data in state
    } catch (error) {
      console.error("Error fetching FAQs data:", error);
    }
  };

  // Function to create discount
  const createDiscount = async (values) => {
    try {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      const params = values;
      const response = await postRequest(
        "/merchant-discounts",
        params,
        headers
      );
      if (response?.data) {
        const createdDiscount = response.data; // Assuming the response contains the created discount
        setMerchantDiscountData((prevData) => [...prevData, createdDiscount]); // Update the discount list locally
        return createdDiscount; // Return the created discount
      }
    } catch (error) {
      console.error("Error creating discount:", error);
      throw error; // Rethrow the error to handle it in the component
    }
  };

  // Function to fetch single discount data
  const getSingleDiscount = async (id) => {
    try {
      const headers = {
        Authorization: `Bea rer ${user?.token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      const params = {};
      const response = await getRequest(
        `/merchant-discounts/${id}`,
        params,
        headers
      );
      setSingleDiscountData(response?.data);
    } catch (error) {
      console.error("Error fetching FAQs data:", error);
    }
  };

  // Function to update discount data
  const updateDiscount = async (id, updateData) => {
    try {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      const response = await postRequest(
        `/merchant-discounts-update/${id}`,
        updateData,
        headers
      );

      response.message === 'success' ? toast.success("Discount updated successfully") : toast.error("Discount update failed");
      getMerchantDiscount(user?.token);
    } catch (error) {
      toast.error("Discount update failed");
      console.error("Error updating discount data:", error);
      throw error; // Rethrow the error to handle it in the component
    }
  };

  // Function to delete discount data
  const deleteDiscount = async (id) => {
    try {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      const response = await deleteRequest(
        `/merchant-discounts/${id}`,
        headers
      );
      console.log("Delete response:", response);
      await getMerchantDiscount(user?.token);
    } catch (error) {
      console.error("Error deleting discount data:", error);
    }
  };

  useEffect(() => {
    // getMerchantDiscount();
  }, []);

  return (
    <MerchantContext.Provider
      value={{
        merchant,
        setMerchant,
        getMerchantDiscount,
        createDiscount,
        // getSingleDiscount,
        updateDiscount,
        deleteDiscount,
        merchantDiscountData,
        singleDiscountData,
        setSingleDiscountData,
      }}
    >
      {children}
    </MerchantContext.Provider>
  );
};
