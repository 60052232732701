import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

// Utility function to check if two objects are equal
const isEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const EditMemberProfile = () => {
  const { isProfileUpdating, ProfileMember, user } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);

  const formSchema = [
    { label: "Full Name:", name: "name", type: "text" },
    { label: "Email Address:", name: "email", type: "email" },
    { label: "Password:", name: "password", type: "text", placeHolder: "Leave blank if do not want to change!" },
    { label: "Phone Number:", name: "phone", type: "text" },
    { label: "Birth Date:", name: "birthdate", type: "text" },
    { label: "Location:", name: "location", type: "text" },
  ];

  const initialValues = {
    name: user?.name || "",
    email: user?.email || "",
    password: "",
    phone: user?.phone || "",
    birthdate: user?.birthdate || "",
    location: user?.location || "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    if (!isEqual(values, initialValues)) {
      setVisible(false);
      const formData = { ...values };
      await ProfileMember(formData);
    }
    setSubmitting(false);
  };

  return (
    <>
      <div
        className="mb-16 mt-[6rem] border max-w-2xl mx-4 sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto bg-white shadow-xl rounded-lg text-gray-900"
        style={{
          opacity: isProfileUpdating ? 0.5 : 1,
          pointerEvents: isProfileUpdating ? "none" : "auto",
        }}
      >
        <div className="mx-auto w-32 h-32 relative -mt-16 border-4 outline outline-[#e5e7eb] border-white rounded-full overflow-hidden">
          <img
            className="object-cover object-center h-32"
            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
              user?.name || ""
            )}`}
            alt=""
          />
        </div>
        <div className="text-center mt-4 mb-8">
          <h2 className="font-semibold">{user?.name}</h2>
          <p className="text-blue-400 underline cursor-pointer">
            {user?.email}
          </p>
        </div>

        <div className="p-4 border-t mx-8 mt-2">
          {/* Details */}
          <div className="flex items-start gap-4 mb-4">
            <div>
              <i className="fas fa-phone"></i>
            </div>
            <div>
              <div className="font-bold">Phone</div>
              <div>{user?.phone}</div>
            </div>
          </div>
          <div className="flex gap-4 mb-4">
            <div>
              <i className="fas fa-calendar"></i>
            </div>
            <div>
              <div className="font-bold">Date of Birth</div>
              <div>{user?.birthdate}</div>
            </div>
          </div>
          <div className="flex gap-4 mb-4">
            <div>
              <i className="fas fa-location-dot"></i>
            </div>
            <div>
              <div className="font-bold">Location</div>
              <div>{user?.location}</div>
            </div>
          </div>
          <div className="text-center mt-12">
            <button
              onClick={() => setVisible(true)}
              className="bg-primary hover:bg-primary-dark text-white px-10 py-3 rounded-2xl border-none"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      {visible && (
        <div className="fixed z-20 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="absolute bottom-0 right-0 max-w-[400px] w-full bg-white rounded-lg overflow-auto shadow-xl">
              <div className="flex justify-between items-center bg-[#000080] text-white font-bold py-4 px-6">
                <div>Profile Details</div>
                <button
                  className="text-gray-400 hover:text-gray-800"
                  onClick={() => setVisible(false)}
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex justify-center items-center px-6 py-4">
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                  {({ isSubmitting, values }) => (
                    <Form
                      className="flex flex-col w-full max-w-lg"
                      style={{
                        rowGap: "10px",
                      }}
                    >
                      {formSchema?.map((field) => (
                        <div key={field.label}>
                          {field.type === "heading" ? (
                            <h2 className="member-form-heading px-4 py-2 mb-2">
                              {field.label}
                            </h2>
                          ) : (
                            <>
                              <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 text-left"
                                htmlFor={field.name}
                              >
                                {field.label}
                              </label>
                              <Field
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                type={field.type}
                                name={field.name}
                                id={field.name}
                                placeHolder={field?.placeHolder}
                              />
                              <ErrorMessage
                                name={field.name}
                                component="div"
                                className="color-primary text-xs italic"
                              />
                            </>
                          )}
                        </div>
                      ))}
                      <div className="flex gap-x-6 justify-between">
                        <button
                          onClick={() => setVisible(false)}
                          type="button"
                          className="bg-red-500 w-full hover:bg-red-800 text-white py-2 px-12 rounded mt-4 font-bold justify-center"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="bg-primary w-full hover:bg-primary-dark text-white py-2 px-12 rounded mt-4 font-bold justify-center"
                          style={{
                            opacity:
                              isSubmitting ||
                                isEqual(values, initialValues) ||
                                isProfileUpdating
                                ? 0.5
                                : 1,
                            pointerEvents:
                              isSubmitting ||
                                isEqual(values, initialValues) ||
                                isProfileUpdating
                                ? "none"
                                : "auto",
                          }}
                          disabled={
                            isSubmitting ||
                            isEqual(values, initialValues) ||
                            isProfileUpdating
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditMemberProfile;
