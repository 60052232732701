import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const SignIn = () => {
  const {
    setLoginInfo,
    LoginMember,
    openLoginModal,
    setOpenLoginModal,
  } = useContext(AuthContext);
  const formSchema = [
    { label: "Email Address:", name: "email", type: "email" },
    { label: "Password:", name: "password", type: "password" },
  ];

  const closeModal = () => {
    setOpenLoginModal(false);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoginInfo(values);
    await LoginMember(values);
    setSubmitting(false);
  };

  return (
    <>
      {openLoginModal && (
        <div className="fixed z-20 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="relative bg-white p-2 rounded-lg shadow-xl">
              <div className="absolute top-0 right-0 pt-2 pr-4">
                <button
                  className="text-gray-400 hover:text-gray-800"
                  onClick={closeModal}
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="p-8 font-style">
                <div className="text-center">
                  <div className="mb-8 flex justify-center">
                    <img src="/assets/logo.png" alt="logo" className="h-12" />
                  </div>
                </div>

                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                  {({ isSubmitting }) => (
                    <Form className="w-full max-w-lg">
                      {formSchema?.map((field) => (
                        <div key={field.label} className="mb-2">
                          {field.type === "heading" ? (
                            <h2 className="member-form-heading px-4 py-2 mb-2">
                              {field.label}
                            </h2>
                          ) : (
                            <>
                              <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 text-left"
                                htmlFor={field.name}
                              >
                                {field.label}
                              </label>
                              <Field
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                type={field.type}
                                name={field.name}
                                id={field.name}
                              />
                              <ErrorMessage
                                name={field.name}
                                component="div"
                                className="color-primary text-xs italic"
                              />
                            </>
                          )}
                        </div>
                      ))}
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className="bg-primary hover:bg-primary-dark text-white py-2 px-12 rounded mt-4 mb-4 font-bold justify-center"
                          disabled={isSubmitting}
                        >
                          SIGN ME UP
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignIn;
