import MyDiscounts from "./MyDiscounts";

const MerchantDashboard = () => {
    return (
        <>
            <MyDiscounts />
        </>
    );
};

export default MerchantDashboard;
