import axios from "axios";
const apiUrl = 'https://admin.rewardclub.my/api';
// const apiUrl = 'https://reward-club.spexiontechnologies.com/api'; // OLD DOMAIN
// const apiUrl = process.env.REACT_APP_API_URL;
// const apiUrl = 'http://localhost:8000/api';

export const getRequest = async (url, params, headers) => {
  try {
    const response = await axios.get(apiUrl+url, { params, headers });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return error;
    // throw error;
  }
};

export const postRequest = async (url, data, headers) => {
  try {
    const response = await axios.post(apiUrl + url, data, { headers });
    return response.data;
  } catch (error) {
    // Handle error here (e.g., show error message)
    if (error.response) {
      throw error.response.data; // Throw the error response data
    } else {
      throw error; // Throw the original error
    }
  }
}

export const putRequest = async (url, data, headers) => {
  try {
    const response = await axios.put(apiUrl + url, data, { headers });
    return response.data;
  } catch (error) {
    console.error('Error updating data:', error);
    return error;
  }
};

export const deleteRequest = async (url, headers) => {
  try {
    const response = await axios.delete(apiUrl + url, { headers });
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    return error;
  }
};