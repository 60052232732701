import React, { useContext, useState, useEffect } from "react";
import { MemberContext } from "../../context/MemberContext";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../../components/Loader";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { getRequest } from "../../utils/helper";
const MySubScription = () => {
  const { language, user, refreshUser } = useContext(AuthContext);
  const { subScribePlan, cancelPlan } = useContext(MemberContext);
  const translations = require(`../../lang/common/common_${language}.json`);

  const [clickedPlanId, setclickedPlanId] = useState();
  const [plans, setPlans] = useState();
  const [showPopUp, setShowPopUp] = useState(false);
  const [purchaseShowPopUp, setPurchaseShowPopUp] = useState(false);

  const [first, setfirst] = useState(true);

  const activePlan = user?.subscriptions.find(
    (s) => s.stripe_status === "active"
  );

  const onGracePeriod = user?.onGracePeriod;

  const getMerchantPlans = async () => {
    try {
      const headers = {};
      const param = { 'user_type' : user?.role }
      const response = await getRequest(
        "/plans",
        param,
        headers
      );
      setPlans(response.data);
    } catch (error) {
      console.error("Error fetching Plans data:", error);
    }
  };

  useEffect(() => {
    if (user && first) {
      refreshUser();
      setfirst(false);
      getMerchantPlans();
    }
  }, [user]);

  if (plans?.length === 0) {
    return <div className="flex h-[80vh] justify-center items-center">No data found!</div>;
  }

  return user && plans?.length > 0 ? (
    <div className="bg-min-height">
      <div className="text-2xl mt-10 mb-5 px-4 flex items-center gap-x-2">
        Current Plan:{" "}
        <span className="font-bold">
          {" "}
          {activePlan
            ? activePlan["plan"][`name_${language}`]
            : "No Active Plan"}{" "}
        </span>
        {onGracePeriod && (
          <span className="text-red-700 text-sm">
            ({onGracePeriod && `ends on ${activePlan?.ends_at}`})
          </span>
        )}
      </div>
      <div className="md:grid md:grid-cols-2 lg:grid-cols-5 gap-4 mb-10">
        {/* Plan Cards */}
        {plans?.map((plan, index) => {
          const nameKey = `name_${language}`;
          const priceKey = `price_${language}`;
          const featuresKey = `features_${language}`;
          const durationNumber = `duration_number_${language}`;
          const durationUnit = `duration_unit_${language}`;
          const featuresList = plan[featuresKey]
            .split(",")
            ?.map((feature) => feature.trim());

          return (
            <div key={index} className="col-span-1 p-2 h-full">
              <div
                className={`${
                  plan.id === activePlan?.plan?.id
                    ? "bg-[#00008029]"
                    : "bg-white"
                } border border-gray-200 rounded-lg shadow-md overflow-hidden plan-card`}
              >
                <div className="border-t-4 border-blue-500 px-6 py-4">
                  <h2 className="color-primary text-4xl font-bold mb-4 mt-4">
                    {plan[nameKey]}
                  </h2>
                  <div className="flex justify-center">
                    <div className="border-b border-blue-500 mb-4 w-full"></div>
                  </div>
                  <p className="font-bold text-4xl">${plan[priceKey]}</p>
                  <p className="mt-4">
                    {translations?.home?.planPricing?.paymentType}
                  </p>
                </div>
                <div className="px-6 py-4 flex-grow">
                  <ul className="list-inside text-left">
                    {featuresList?.map((feature, index) => (
                      <span key={index} className="flex flex-row items-center">
                        <i className="fa-solid fa-check color-primary mt-2 mr-2"></i>
                        <li className="list-check text-sm">
                          {index === 0
                            ? `${plan[durationNumber]} ${plan[durationUnit]} `
                            : ""}
                          {feature}
                        </li>
                      </span>
                    ))}
                  </ul>
                </div>
                <div className="px-6 py-4 mb-5 text-center">
                  {plan.id === activePlan?.plan?.id ? (
                    <button
                      disabled={onGracePeriod}
                      style={{
                        cursor: onGracePeriod ? "not-allowed" : "pointer",
                        opacity: onGracePeriod ? 0.5 : 1,
                      }}
                      onClick={() => {
                        setShowPopUp(true);
                      }}
                      className="bg-red-500 hover:bg-red-800 text-white py-2 px-4 rounded"
                    >
                      {language === "en"
                        ? "Cancel Plan"
                        : language === "ms"
                        ? "Batal Rancangan"
                        : "取消计划"}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setclickedPlanId(plan.stripe_price_id);
                        setPurchaseShowPopUp(true);
                      }}
                      className="bg-primary hover:bg-primary-dark text-white py-2 px-4 rounded"
                    >
                      {language === "en"
                        ? "Select Plan"
                        : language === "ms"
                        ? "Pilih Pelan"
                        : "选择计划"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ConfirmationDialog
        show={showPopUp}
        setShow={setShowPopUp}
        title="Are you sure you want to cancel the plan?"
        onConfirm={() => {
          cancelPlan();
        }}
        onCancel={() => {}}
        varient={"danger"}
      />
      <ConfirmationDialog
        show={purchaseShowPopUp}
        setShow={setPurchaseShowPopUp}
        title="Are you sure you want to purchase the plan?"
        onConfirm={() => {
          const formData = new FormData();
          formData.append("planId", clickedPlanId);
          subScribePlan(formData);
        }}
        onCancel={() => {}}
        varient={"success"}
      />
    </div>
  ) : (
    <div className="h-[90vh] flex justify-center items-center">
      <Loader />
    </div>
  );
};

export default MySubScription;
