import React, {useState} from 'react';

const FaqToggle = ({question, answer}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{marginBottom: '20px', backgroundColor: ""}}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2 className='text-2xl font-bold' style={{color: isOpen ? "#0094ff" : "black"}}>{question}</h2>
        <button onClick={toggle} style={{
          border: `1px solid ${isOpen ? "#0094ff" : "black"} `,
          borderRadius: '50%',
          background: 'white',
          width: '30px',
          height: '30px',
          cursor: 'pointer',
          outline: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <span style={{fontWeight: 'bold', color: isOpen ? "#0094ff" : "black"}}>{isOpen ? '-' : '+'}</span>
        </button>
      </div>
      {isOpen && <p style={{marginLeft: '5px', marginTop: "10px"}}>{answer}</p>}
      <div style={{backgroundColor: "lightgray", height: "1px", width: "100%", marginTop: "5px"}}></div>
    </div>
  );
};

export default FaqToggle;
