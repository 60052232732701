import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faComment } from "@fortawesome/fontawesome-free-solid";
import {
  faFacebook,
  faLinkedin,
  faYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import UserSignMeUp from "../pages/auth/UserSignMeUp";
import { AuthContext } from "../context/AuthContext";
import UserSignup from "../pages/auth/UserSignup";

const Footer = () => {
  const { user } = useContext(AuthContext);
  if (user) {
    return <LoggedInFooter />;
  } else {
    return <PublicFooter />;
  }
};

const PublicFooter = () => {
  const { language, setOpenLoginModal, isUserSignupModalOpen, setIsUserSignupModalOpen } = useContext(AuthContext);
  const [isUserSignMeupModalOpen, setIsUserSignMeupModalOpen] = useState(false);
  const translations = require(`../lang/common/common_${language}.json`);

  const openModal = () => {
    setIsUserSignupModalOpen(true);
    // setIsUserSignMeupModalOpen(true);
  };

  const closeModal = () => {
    setIsUserSignupModalOpen(false);
    // setIsUserSignMeupModalOpen(false);
  };

  const handleLoginOpen = () => {
    setIsUserSignupModalOpen(true);
    // setOpenLoginModal(true);
  };

  const handleNavLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const images = [
    "visa.png",
    "fpx.png",
    "master_card.png",
    "touch_ngo.png",
    "grab_pay.png",
  ];

  return (
    <>
      <footer className="py-10 text-gray-700 bg-gray-100 border border-gray-200">
        <div className="container grid grid-cols-1 gap-4 mx-auto md:grid-cols-4">
          {/* First Column */}
          <div className="justify-center text-center">
            <Link to="/" onClick={handleNavLinkClick}>
              <img src="/assets/logo.png" alt="Logo" className="h-16 mx-auto" />
            </Link>
            <p className="mt-4 mb-6 text-sm font-medium">
              {translations.footer.footerQuote}
            </p>
            <p className="mt-3 text-sm font-bold underline-design">
              {translations.footer.footerOfferText}
            </p>
            <div className="flex justify-center mt-2 g-2">
              {images?.map((image, index) => (
                <img
                  key={index}
                  src={`/assets/footer_payment_logo/${image}`}
                  alt={`${index + 1}`}
                  className="footer-payment-logos"
                />
              ))}
            </div>
            <div>
              <button
                className="px-12 py-5 mt-10 mb-4 font-bold text-white rounded bg-primary hover:bg-primary-dark"
                onClick={openModal}
              >
                {translations.footer.newMembertSignUpText}
              </button>
              {isUserSignMeupModalOpen && (
                <UserSignup
                  isUserSignupModalOpen={isUserSignupModalOpen}
                  setIsUserSignupModalOpen={setIsUserSignupModalOpen}
                />

                // <UserSignMeUp
                //   isUserSignMeupModalOpen={isUserSignMeupModalOpen}
                //   setIsUserSignMeupModalOpen={setIsUserSignMeupModalOpen}
                //   closeModal={closeModal}
                // />
              )}
            </div>
          </div>

          {/* Second Column */}
          <div className="text-center">
            <p className="mt-8 mb-4 text-sm font-bold uppercase underline-design md:mt-0">
              {translations.footer.supportCenter}
            </p>
            <div className="text-center">
              {/* <div className="items-center mb-2 font-medium">
                <FontAwesomeIcon icon={faComment} className="w-6 mr-2" />
                <span>{translations.footer.phone}</span>
              </div> */}
              <div className="items-center mb-2 font-medium">
                <FontAwesomeIcon icon={faEnvelope} className="w-6 mr-2" />
                <span>{translations.footer.email}</span>
              </div>
            </div>
          </div>

          {/* Third Column */}
          <div className="text-center">
            <p className="mt-8 mb-4 text-sm font-bold uppercase underline-design md:mt-0">
              {translations.footer.quickLinks}
            </p>
            <ul className="text-center footer-quick-links">
              <li>
                <NavLink
                  to="/privacy-policy"
                  className="font-medium footer-link"
                >
                  {translations.footer.privacyPolicy}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/terms-conditions"
                  className="font-medium footer-link"
                >
                  {translations.footer.membershipTC}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/terms-conditions"
                  className="font-medium footer-link"
                >
                  {translations.footer.termsConditions}
                </NavLink>
              </li>
              <li>
                <NavLink to="/faqs" className="font-medium footer-link">
                  {translations.footer.faqs}
                </NavLink>
              </li>
              <li>
                <NavLink
                  // to="/login"
                  className="font-medium footer-link"
                  onClick={openModal}
                >
                  {translations.footer.login}
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" className="font-medium footer-link">
                  {translations.footer.aboutUs}
                </NavLink>
              </li>
            </ul>
          </div>

          {/* Fourth Column */}
          <div className="text-center">
            <p className="mt-8 mb-4 text-sm font-bold uppercase underline-design md:mt-0">
              {translations.footer.socialLinks}
            </p>
            <div className="flex items-center justify-center">
              <div className="p-2 mr-4 bg-blue-500 rounded-full icon-wrapper hover:bg-blue-600">
                <a href="#" className="block">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="w-6 font-bold text-white"
                  />
                </a>
              </div>
              <div className="p-2 mr-4 bg-blue-800 rounded-full icon-wrapper hover:bg-blue-9N00">
                <a href="#" className="block">
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="w-6 font-bold text-white"
                  />
                </a>
              </div>
              <div className="p-2 mr-4 bg-red-700 rounded-full icon-wrapper hover:bg-red-800">
                <a href="#" className="block">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="w-6 font-bold text-white"
                  />
                </a>
              </div>
              <div className="p-2 bg-pink-500 rounded-full icon-wrapper hover:bg-pink-600">
                <a href="#" className="block">
                  <FontAwesomeIcon
                    icon={faTiktok}
                    className="w-6 font-bold text-white"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* New Row */}
      <div className="py-4 bg-gray-200 md:py-8">
        <div className="container flex flex-col items-center justify-between mx-auto md:flex-row">
          <div className="mb-4 font-medium text-center text-gray-600 md:text-left md:mb-0">
            &copy; {translations.footer.footerCopyright}
          </div>
          <div className="flex items-center justify-end space-x-4">
            <a href="#">
              <FontAwesomeIcon
                icon={faFacebook}
                className="w-6 mr-2 font-bold text-gray-600"
              />
            </a>
            <a href="#">
              <FontAwesomeIcon
                icon={faLinkedin}
                className="w-6 mr-2 font-bold text-gray-600"
              />
            </a>
            <a href="#">
              <FontAwesomeIcon
                icon={faYoutube}
                className="w-6 mr-2 font-bold text-gray-600"
              />
            </a>
            <a href="#">
              <FontAwesomeIcon
                icon={faTiktok}
                className="w-6 mr-2 font-bold text-gray-600"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const LoggedInFooter = () => {
  const { language } = useContext(AuthContext);
  const translations = require(`../lang/common/common_${language}.json`);
  const navigate = useNavigate();
  return (
    <div className="py-4 bg-gray-200 border-t-2 border-gray-300 md:py-8">
      <div className="container flex flex-col items-center justify-between mx-auto md:flex-row">
        <div className="mb-4 font-medium text-center text-gray-600 md:text-left md:mb-0">
          &copy; {translations.footer.footerCP}
        </div>
        <div className="items-center justify-end hidden space-x-4 font-medium text-gray-600 md:flex">
          <div
            className="cursor-pointer"
            onClick={() => navigate("/privacy-policy")}
          >
            {translations.footer.privacyPolicy} &#160; &#x2022;
          </div>
          <div
            className="cursor-pointer"
            onClick={() => navigate("/terms-conditions")}
          >
            {translations.footer.termsConditions} &#160; &#x2022;
          </div>
          <div className="cursor-pointer" onClick={() => navigate("/")}>
            {translations.footer.support} &#160; &#x2022;
          </div>
          <div className="cursor-pointer" onClick={() => navigate("/faqs")}>
            {translations.footer.faq}
          </div>
        </div>
        <div className="flex flex-col items-center font-medium text-gray-600 md:hidden">
          <div
            className="cursor-pointer"
            onClick={() => navigate("/privacy-policy")}
          >
            {translations.footer.privacyPolicy}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => navigate("/terms-conditions")}
          >
            {translations.footer.termsConditions}
          </div>
          <div className="cursor-pointer" onClick={() => navigate("/")}>
            {translations.footer.support}
          </div>
          <div className="cursor-pointer" onClick={() => navigate("/faqs")}>
            {translations.footer.faq}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
