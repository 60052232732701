import { useEffect, useState, useRef, useContext } from "react";
import { AuthContext } from "../context/AuthContext";


const RewardInfo = () => {

    const { language } = useContext(AuthContext);
    const translations = require(`../lang/common/common_${language}.json`);

    // const [moving, setMoving] = useState(false);
    // const [scrollDirection, setScrollDirection] = useState('');
    // const carRef = useRef(null);
    // const borderRef = useRef(null);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const car = carRef.current;
    //         const scrollY = window.scrollY;
    //         const windowHeight = window.innerHeight;

    //         // Adjust the position of the car based on scroll position
    //         // For example, you can set the top position to half of the window height
    //         // minus half of the car height to center it vertically
    //         car.style.top = `${scrollY + windowHeight / 2 - car.offsetHeight / 2}px`;
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);


    return (
        <>
            <div className='flex bg-black h-80vh flex-col p-5 md:p-20'>
                <div className="flex flex-col justify-start text-left mb-10 mt-10 container">
                    <p className="mb-5 color-primary hover:color-primary-dark text-2xl"><i
                        className="fa-solid fa-car color-primary"></i><span
                            className='text-xl md:text-2xl font-bold '> {translations.home.rewardInfo.intro.whatIsRewardClub}</span></p>
                    <h5 className="text-white text-2xl md:text-4xl font-bold">{translations.home.rewardInfo.intro.membershipPrivileges}</h5>
                </div>

                <div className="flex flex-col md:flex-row justify-left md:justify-between w-full">
                    <div className={`first-part w-full md:w-1/3 text-white md:text-center`}>
                        <div className='flex flex-col'>
                            <div className='flex my-3'>
                                <div className="flex justify-between">
                                    <div className="w-[75%] p-5 text-left md:text-right order-2 md:order-1">
                                        <p className="color-primary font-bold text-xl">{translations.home.rewardInfo.sections.communityOfEnthusiasts.title}</p>
                                        <p className="text-white">
                                            {translations.home.rewardInfo.sections.communityOfEnthusiasts.description}
                                        </p>
                                    </div>
                                    <div className="w-[25%] md:w-auto p-5 order-1 md:order-2 text-right">
                                        <div
                                            className="icon-container bg-white rounded-full flex items-center justify-center">
                                            <i className="fa-solid fa-users color-primary text-xl"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex my-3'>
                                <div className="flex justify-between">
                                    <div className="w-[75%] p-5 text-left md:text-right order-2 md:order-1">
                                        <p className="color-primary font-bold text-xl">{translations.home.rewardInfo.sections.expertSupport.title}</p>
                                        <p className="text-white">
                                            {translations.home.rewardInfo.sections.expertSupport.description}
                                        </p>
                                    </div>
                                    <div className="w-[25%] md:w-auto p-5 order-1 md:order-2">
                                        <div
                                            className="icon-container bg-white rounded-full flex items-center justify-center">
                                            <i className="fa-solid fa-user color-primary text-xl"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex my-3'>
                                <div className="flex justify-between">
                                    <div className="w-[75%] p-5 text-left md:text-right order-2 md:order-1">
                                        <p className="color-primary font-bold text-xl">{translations.home.rewardInfo.sections.aGrowingFleet.title}</p>
                                        <p className="text-white">
                                            {translations.home.rewardInfo.sections.aGrowingFleet.description}
                                        </p>
                                    </div>
                                    <div className="w-[25%] md:w-auto p-5 order-1 md:order-2">
                                        <div
                                            className="icon-container bg-white rounded-full flex items-center justify-center">
                                            <i className="fa-solid fa-car-side color-primary text-xl"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="middle-part w-full md:w-1/6 text-white relative">
                        <img
                            height={70}
                            width={50}
                            src="/assets/car_logo.png"
                            alt="Car"
                            className="z-10"
                            ref={carRef}
                            style={{ position: 'fixed', top: scrollDirection === 'up' ? '0' : 'auto', bottom: scrollDirection === 'down' ? '0' : 'auto', left: '50%', transform: 'translate(-50%, -50%)' }}
                        />
                        <div className="h-full border-l border-white border-dashed absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" ref={borderRef}></div>
                    </div> */}

                    <div className="middle-part w-full md:w-1/6 text-white ml-20 relative">
                        <div
                            className="h-full border-l border-white border-dashed absolute top-0 bottom-0 left-14"></div>
                    </div>

                    <div className="last-part w-full md:w-1/3 text-white md:text-center">
                        <div className='flex flex-col'>
                            <div className='flex my-3'>
                                <div className="flex">
                                    <div className="w-[25%] p-5">
                                        <div
                                            className="icon-container bg-white rounded-full flex items-center justify-center">
                                            <i className="fa-solid fa-tag color-primary text-xl"></i>
                                        </div>
                                    </div>
                                    <div className="w-[75%] text-left p-5">
                                        <p className="color-primary font-bold text-xl">{translations.home.rewardInfo.sections.exclusiveDiscounts.title}</p>
                                        <p className="text-white">
                                            {translations.home.rewardInfo.sections.exclusiveDiscounts.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex my-3'>
                                <div className="flex">
                                    <div className="w-[25%] p-5">
                                        <div
                                            className="icon-container bg-white rounded-full flex items-center justify-center">
                                            <i className="fa-solid fa-circle-pause color-primary text-xl"></i>
                                        </div>
                                    </div>
                                    <div className="w-[75%] text-left p-5">
                                        <p className="color-primary font-bold text-xl">{translations.home.rewardInfo.sections.flexibilityToOptOut.title}</p>
                                        <p className="text-white">
                                            {translations.home.rewardInfo.sections.flexibilityToOptOut.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex my-3'>
                                <div className="flex">
                                    <div className="w-[25%] p-5">
                                        <div
                                            className="icon-container bg-white rounded-full flex items-center justify-center">
                                            <i className="fa-solid fa-handshake-angle color-primary text-xl"></i>
                                        </div>
                                    </div>
                                    <div className="w-[75%] text-left p-5">
                                        <p className="color-primary font-bold text-xl">{translations.home.rewardInfo.sections.trustedPartnerships.title}</p>
                                        <p className="text-white">
                                            {translations.home.rewardInfo.sections.trustedPartnerships.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default RewardInfo

