import { useContext, useEffect, useState } from 'react';
import RewardInfo from '../components/RewardsInfo';
import QSlider from '../components/QSlider';
import LogoSlider from '../components/logoSlider';
import PlanPricing from "../components/PlanPricing";
import { AuthContext } from '../context/AuthContext';
import UserSignup from './auth/UserSignup';
import SignIn from './auth/SignIn';
import Articles from '../components/Articles';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import InstaFeeds from "../components/InstaFeed";

const Home = () => {

  const { language,  isUserSignupModalOpen, setIsUserSignupModalOpen } = useContext(AuthContext);
  const translations = require(`../lang/common/common_${language}.json`);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const openModal = () => {
    setIsUserSignupModalOpen(true);
  };

  const closeModal = () => {
    setIsUserSignupModalOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768); 
    };

    // Call handleResize initially and add event listener
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div style={{ height: "auto" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="flex justify-start bg-center bg-cover items-left"
            style={{
              backgroundImage: `linear-gradient(#000000 0%, rgba(10, 10, 10, 0.36) 100%)`,
              opacity: 0.95,
              height: "100vh",
              width: "100%"
            }}>
            <video autoPlay loop muted className="inset-0 object-cover w-full h-full"
              poster="/assets/videos/home_page_black_and_white.jpg">
              <source src="/assets/videos/home_page_black_and_white.mp4" type="video/mp4" />
            </video>
            <div className="absolute flex items-center w-full h-full bg-black bg-opacity-50" style={{ zIndex: 995 }}>
              <div className="container mx-auto text-left">
                <div className="flex flex-col justify-between md:flex-row">
                  <div
                    className={`left-part w-full md:w-1/2 ${isMobileScreen ? "text-center" : "text-left"} md:ml-10 mb-8 md:mb-0`}>
                    <h5 className="mb-4 text-4xl font-bold text-white lg:text-4xl ">
                      {translations.home.top.shoppingToolHeader}
                    </h5>
                    <p className="mt-4 mb-8 text-lg font-bold text-white">
                      {translations.home.top.shoppingToolDescription}
                    </p>
                    <button onClick={openModal}
                      className="px-12 py-5 mt-2 font-bold text-white rounded cursor-pointer bg-primary hover:bg-primary-dark">
                      {translations.home.top.signMeUpButton}
                    </button>

                    {/*<NavLink to="/signup" className="px-12 py-5 mt-4 font-bold text-white rounded bg-primary hover:bg-primary-dark">
                      Sign Me Up
                    </NavLink>*/}
                  </div>
                  <div className={`right-part w-full md:w-1/2 mt-4 md:mt-0`}>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Counter*/}
        <div className="flex items-center justify-center p-10 bg-primary" style={{ minHeight: 450 }}>
          <div className="container px-4 mx-auto text-center lg:px-0">
            <div className="flex flex-col justify-between md:flex-row">
              {Object.entries(translations.home.counter)?.map(([key, value]) => (
                <div key={key} className="mb-10 text-center class1 md:text-left md:mb-0">
                  <div className="flex items-center">
                    <span className="text-6xl font-bold text-white">{value.number}</span>
                    <span className="text-6xl font-bold text-white">+</span>
                  </div>
                  <div>
                    <p className="font-bold text-white">{value.label}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className="flex items-center justify-center p-10 bg-primary" style={{ minHeight: 450 }}>
                    <div className="container px-4 mx-auto text-center lg:px-0">
                        <div className="flex flex-col justify-between md:flex-row">
                            <div className="mb-10 text-center class1 md:text-left md:mb-0">
                                <div className="flex items-center">
                                    <AnimatedNumbers
                                        includeComma={false}
                                        className="font-bold text-white"
                                        transitions={(index) => ({
                                            type: "spring",
                                            duration: index + 0.7,
                                        })}
                                        animateToNumber={300}
                                        fontStyle={{
                                            fontSize: "3.5rem",
                                            color: "white",
                                        }}
                                    />
                                    <span className="text-6xl font-bold text-white">+</span>
                                </div>
                                <div>
                                    <p className="font-bold text-white">Business partners discounts</p>
                                </div>
                            </div>
                            <div className="mb-10 text-center class1 md:text-left md:mb-0">
                                <div className="flex items-center">
                                    <AnimatedNumbers
                                        includeComma={false}
                                        className="font-bold text-white"
                                        transitions={(index) => ({
                                            type: "spring",
                                            duration: index + 0.7,
                                        })}
                                        animateToNumber={1000}
                                        fontStyle={{
                                            fontSize: "3.5rem",
                                            color: "white",
                                        }}
                                    />
                                    <span className="text-6xl font-bold text-white">+</span>
                                </div>
                                <div>
                                    <p className="font-bold text-white">Stores you can redeem offers in
                                        person or online</p>
                                </div>
                            </div>
                            <div className="mb-10 text-center class3 md:text-left md:mb-0">
                                <div className="flex items-center">
                                    <AnimatedNumbers
                                        includeComma={false}
                                        className="font-bold text-white"
                                        transitions={(index) => ({
                                            type: "spring",
                                            duration: index + 0.7,
                                        })}
                                        animateToNumber={1000}
                                        fontStyle={{
                                            fontSize: "3.5rem",
                                            color: "white",
                                        }}
                                    />
                                    <span className="text-6xl font-bold text-white">+</span>
                                </div>
                                <div>
                                    <p className="font-bold text-white">In savings through our
                                        <span className='font-bold'> REWARDCLUB.MY</span> rates </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

        {/*Counter*/}

        {/*Featured On*/}
        <div className="p-10 bg-white">
          <div className="flex justify-center">
            <h2 className="section-header">{translations.home.findCoupons.header}</h2>
          </div>
          <div className="flex justify-center mt-5 mb-5">
            <div className='font-bold text-s'>{translations.home.findCoupons.subheader}</div>
          </div>

          <div className="flex flex-col lg:flex-row find-coupon">
            {translations.home.findCoupons.steps?.map((step, index) => (
              <div key={index} className="w-full px-12 py-4 lg:w-1/3">
                <img src={step.image} alt="Image" className="mx-auto mb-4" height={300} width={300} />
                <h2 className="mb-0 text-center md:mb-6">{step.title}</h2>
                <p className="text-center">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="p-10 bg-white">

                    <div className="flex justify-center ">
                        <h2 className="section-header">Find Coupons</h2>
                    </div>
                    <div className="flex justify-center mt-5 mb-5">
                        <div className='font-bold text-s'>With Reward Club, It's easy as one, two, three!</div>
                    </div>

                    <div className="flex flex-col lg:flex-row find-coupon">
                        <div className="w-full px-12 py-4 lg:w-1/3">
                            <img src="/assets/find_coupon/step1.png" alt="Image" className="mx-auto mb-4" height={300}
                                width={300} />
                            <h2 className="mb-0 text-center md:mb-6">Step 1. Start Your Savings Journey</h2>
                            <p className="text-center">Simply select the category or utilize the search function to
                                begin. The discount coupon/voucher are set by the partners accordingly and its our job
                                to bring the best offers to you.</p>
                        </div>
                        <div className="w-full px-12 py-4 lg:w-1/3">
                            <img src="/assets/find_coupon/step2.png" alt="Image" className="mx-auto mb-4" height={300}
                                width={300} />
                            <h2 className="text-center">Step 2. Comprehensive Selection of Discount Coupon/Voucher </h2>
                            <p className="text-center">Browse through the services needed and review the partners’
                                service rating. To name a few, we have partners from automotive segment, home repair or
                                services segments, health and fitness segments and many more. Click on the best offer
                                that interest you.
                            </p>
                        </div>
                        <div className="w-full px-12 py-4 lg:w-1/3">
                            <img src="/assets/find_coupon/step3.png" alt="Image" className="mx-auto mb-4" height={300}
                                width={300} />
                            <h2 className="mb-0 text-center md:mb-6">Step 3. Seamless Savings at Checkout</h2>
                            <p className="text-center">Once you’ve found the perfect coupon, simply click on the
                                coupon/voucher and redirect to the partners website or show the coupon/voucher to the
                                partners if its offline store. Finally, the longer you are a member of <span
                                    className="font-bold">REWARDCLUB.MY</span> the
                                more rewards you’ll win and the more you’ll save!
                            </p>
                        </div>
                    </div>


                </div> */}

        {/*Featured On*/}

        {/* Slider */}
        <div className="relative w-full p-10">
          {/* Background video with opacity */}
          <video autoPlay loop muted className="absolute inset-0 object-cover w-full h-full"
            style={{ opacity: "0.9" }}>
            <source src="/assets/videos/business_partners_dark_purple.mp4" type="video/mp4" />
          </video>

          {/* Content container */}
          <div className="container relative z-10 pt-20 mx-auto text-center">
            <div style={{ marginTop: '-4rem' }}>
              <div className="flex justify-center">
                <h2 className="mb-10 text-white section-header">{translations.home.ourPartner.title}</h2>
              </div>
              <div style={{ padding: "30px" }}>
                <LogoSlider />
              </div>
              {/* <button
                className="px-12 py-5 mt-10 mb-4 font-bold text-white rounded bg-primary hover:bg-primary-dark">{translations.home.ourPartner.viewAll}
              </button> */}
            </div>
          </div>
        </div>

        <div className="w-full h-auto">
          {/* <TextSlider /> */}
          <QSlider />
        </div>

        <div className="px-5 py-5 text-white automotive-container bg-white-500 md:px-20">
          <div className="flex flex-col md:flex-row">
            <div
              className="flex flex-col items-center justify-center flex-1 w-full p-6 mt-4 mb-4 mr-4 rounded-lg bg-primary hover:bg-primary-dark automotive-subclass md:w-1/4">
              <div className='p-10 text-left'>
                <i className="mb-5 fa-regular fa-star" style={{ fontSize: "30px" }}></i>
                <h3 className="mb-2 text-2xl font-bold">{translations.home.work.automotive.title}</h3>
                <p className="text-left">{translations.home.work.automotive.description}</p>
              </div>
            </div>

            <div
              className="flex flex-col items-center justify-center flex-1 w-full h-auto p-6 my-4 mr-4 rounded-lg bg-primary hover:bg-primary-dark automotive-subclass md:w-1/4">
              <div className='p-10 text-left'>
                <i className="mb-5 fa-regular fa-star" style={{ fontSize: "30px" }}></i>
                <h3 className="mb-2 text-2xl font-bold">{translations.home.work.allTradesAndServices.title}</h3>
                <p className="text-left">{translations.home.work.allTradesAndServices.description}</p>
              </div>
            </div>

            <div
              className="flex flex-col items-center justify-center flex-1 w-full h-auto p-6 my-4 rounded-lg bg-primary hover:bg-primary-dark automotive-subclass md:w-1/4">
              <div className='p-10 text-left'>
                <i className="mb-5 fa-solid fa-shirt" style={{ fontSize: "30px" }}></i>
                <h3 className="mb-2 text-2xl font-bold">{translations.home.work.merchandise.title}</h3>
                <p className="text-left">{translations.home.work.merchandise.description}</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="px-5 py-5 text-white automotive-container bg-white-500 md:px-20">
                    <div className="flex flex-col md:flex-row">
                        <div
                            className="flex flex-col items-center justify-center flex-1 w-full p-6 mt-4 mb-4 mr-4 rounded-lg bg-primary hover:bg-primary-dark automotive-subclass md:w-1/4">
                            <div className='p-10 text-left'>
                                <i className="mb-5 fa-regular fa-star" style={{ fontSize: "30px" }}></i>
                                <h3 className="mb-2 text-2xl font-bold">Automotive</h3>
                                <p className="text-left">Your one-stop-shop for exclusive offers/coupon/vouchers from
                                    Malaysia's leading performance & tuning workshops, paint shops, repair and service
                                    workshops, aftermarket parts & accessories, and everything else automotive</p>
                            </div>
                        </div>

                        <div
                            className="flex flex-col items-center justify-center flex-1 w-full h-auto p-6 my-4 mr-4 rounded-lg bg-primary hover:bg-primary-dark automotive-subclass md:w-1/4">
                            <div className='p-10 text-left'>
                                <i className="mb-5 fa-regular fa-star" style={{ fontSize: "30px" }}></i>
                                <h3 className="mb-2 text-2xl font-bold">All Trades & Services</h3>
                                <p className="text-left">We've teamed up with the best in the business. Get great deals
                                    on home improvement services, fitness and health deals, and many more!
                                </p>
                            </div>
                        </div>

                        <div
                            className="flex flex-col items-center justify-center flex-1 w-full h-auto p-6 my-4 rounded-lg bg-primary hover:bg-primary-dark automotive-subclass md:w-1/4">
                            <div className='p-10 text-left'>
                                <i className="mb-5 fa-solid fa-shirt" style={{ fontSize: "30px" }}></i>
                                <h3 className="mb-2 text-2xl font-bold">Merchandise</h3>
                                <p className="text-left">Exclusive merchandise offers and designs from both
                                    REWARDCLUB.MY and our partners. Great quality swag from your favourite brands.
                                    Including some exclusive designs from bags, t-shirts and more.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}

        <RewardInfo />

        {/*<div className="p-10 bg-white">*/}

        <div className="px-3 py-10 bg-gray-300 md:px-10">
          <div className="h-full">

            <div className="flex justify-center">
              <h2 className="section-header">{translations.home.instaData.sectionHeader}</h2>
            </div>
            <div className="flex justify-center mt-5">
              <div className='font-bold text-s'>{translations.home.instaData.followOnInstagram}</div>
            </div>
            <div className="mt-10">
              <div className="grid grid-cols-2 gap-4 ml-6 mr-8 md:grid-cols-4">
                <div>
                  <img className="h-auto rounded-lg max-w-1/2"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image.jpg"
                    alt="" />
                </div>
                <div>
                  <img className="h-auto rounded-lg max-w-1/2"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg"
                    alt="" />
                </div>
                <div>
                  <img className="h-auto rounded-lg max-w-1/2"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-2.jpg"
                    alt="" />
                </div>
                <div>
                  <img className="h-auto rounded-lg max-w-1/2"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-3.jpg"
                    alt="" />
                </div>
                <div>
                  <img className="h-auto rounded-lg max-w-1/2"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-4.jpg"
                    alt="" />
                </div>
                <div>
                  <img className="h-auto rounded-lg max-w-1/2"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-5.jpg"
                    alt="" />
                </div>
                <div>
                  <img className="h-auto rounded-lg max-w-1/2"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-6.jpg"
                    alt="" />
                </div>
                <div>
                  <img className="h-auto rounded-lg max-w-1/2"
                    src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-7.jpg"
                    alt="" />
                </div>
              </div>
            </div>
            {/* <div className='flex justify-center mt-5'>
              <button
                className="px-5 py-3 mt-5 text-white rounded bg-primary hover:bg-primary hover:text-white"
              >{translations.home.instaData.loadMoreButton}</button>
            </div> */}
          </div>
        </div>

        {/* <InstaFeeds token={process.env.REACT_APP_INS_TOKEN} limit={8}/> */}

        {/** Divyesh Insta */}
        {/*<InstaFeeds token="IGQWRPcG5oTE1vWGRWb1RKUjlfQnZAER2NzS0lINmxWemg3ZA2haMnQ3QkRZAbk1yZAk16cDNMTDA5ZAEVrOHpJOG9BanIwWFpSTzEwb1BCNjA4OHhFWUdNazJzZAkZAkMkhYczU0ck9PMVBSdE1vWExtVzl2eFM5XzY1MWcZD" limit={8}/>*/}

        <Articles />
 
        <PlanPricing />
      </div>
      {isUserSignupModalOpen && <GoogleOAuthProvider clientId="1066471456951-jvb9nma0ab3rglfv4v7vciam8qd8ker0.apps.googleusercontent.com"><UserSignup isUserSignupModalOpen={isUserSignupModalOpen}
                setIsUserSignupModalOpen={setIsUserSignupModalOpen} /></GoogleOAuthProvider>}
    </>
  )
}

export default Home