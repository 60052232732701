import React from 'react';

const MenuItem = ({ icon, text }) => (
    <div className="flex items-center py-2 w-full" style={{ width: '100%', whiteSpace: 'nowrap' }}>
        <i className={`mr-2 text-primary ${icon}`} />
        <span>{text}</span>
    </div>
);

const LeftMenu = (props) => {
    const { states, cities, towns, categories,
        selectedState, selectedCity, selectedTown, selectedCategory,
        setSelectedState, setSelectedCity, setSelectedTown, setSelectedCategory, 
        language } = props;

    return (
        <div className="container px-0 md:px-0 lg:px-0 w-full">
            <div className="main-content bg-white m-10 mb-0 md:mb-10 lg:mr-0 shadow-md mt-10">
                <div className="flex flex-wrap items-center justify-center" style={{ letterSpacing: '2px' }}>
                    <div className="bg-white p-10 md:py-20">
                        <div className="mb-10">
                            <h2 className="text-2xl font-semibold">Filters</h2>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-md font-semibold mb-2">State</h3>
                            <select 
                                className="w-full border rounded px-3 py-2 outline-none"
                                value={selectedState}
                                onChange={e => setSelectedState(e.target.value)}
                            >
                                <option value="">Select State</option>
                                {states?.map((state) => (
                                    <option key={state.id} value={state.id}>{state[`name_${language}`]}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-md font-semibold mb-2">City</h3>
                            <select
                                className="w-full border rounded px-3 py-2 outline-none"
                                value={selectedCity}
                                onChange={(e) => setSelectedCity(e.target.value)}
                                disabled={!selectedState}
                            >
                                <option value="">Select Cities</option>
                                {cities?.map((city) => (
                                    <option key={city.id} value={city.id}>{city[`name_${language}`]}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-md font-semibold mb-2">Town</h3>
                            <select
                                className="w-full border rounded px-3 py-2 outline-none"
                                value={selectedTown}
                                onChange={(e) => setSelectedTown(e.target.value)}
                                disabled={!selectedCity}
                            >
                                <option value="">Select Town</option>
                                {towns?.map((town) => (
                                    <option key={town.id} value={town.id}>{town[`name_${language}`]}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <h3 className="text-md font-semibold mb-2">Categories</h3>
                            <select
                                className="w-full border rounded px-3 py-2 outline-none"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                            >
                                <option value="">All Categories</option>
                                {categories?.map((category) => (
                                    <option key={category.id} value={category.id}>{category[`name_${language}`]}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeftMenu;

