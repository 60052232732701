import React from 'react';
import {NavLink} from 'react-router-dom';

const UserSignMeUp = ({isUserSignMeupModalOpen, setIsUserSignMeupModalOpen}) => {
  const closeModal = () => {
    setIsUserSignMeupModalOpen(false);
  };

  // Array of field objects
  const fields = [
    {name: 'fullName', label: 'Full Name', type: 'text', required: true},
    {name: 'identityType', label: 'Identity Type', type: 'select', required: true, options: ['New IC No', 'Option 2', 'Option 3']},
    {name: 'emailAddress', label: 'Email Address', type: 'email', required: false},
    {name: 'mobilePhoneNo', label: 'Mobile Phone No.', type: 'tel', required: true, example: 'eg. 01234567890'},
    {
      name: 'subscribeToEStatement',
      label: 'Subscribe to E-Statement?',
      type: 'radio',
      options: [
        {label: 'Yes', value: 'yes'},
        {label: 'No', value: 'no'}
      ],
      required: false
    },
    {name: 'divider', type: 'divider'},
    {
      name: 'acceptTerms',
      label: (
        <>
          I agree that i have read the <NavLink to="/terms-conditions" className='text-blue-500'>Terms and Conditions</NavLink> of the RewardClub
          programme, including the purpose of providing my personal data to RewardClub and i agree to be bound by this Agreement and all amendments,
          revisions, and additions which RewardClub may effect from time to time and i further consent to the usage and processing of my personal data by
          RewardClub in accordance with the Terms and Conditions stated herein.
        </>
      ),
      type: 'checkbox',
      required: true
    },
  ];

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto p-5">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="relative bg-gray-100 p-8 rounded-lg shadow-xl w-[800px]">
          <div className="absolute top-0 right-0 pt-2 pr-4">
            <button className="text-gray-400 hover:text-gray-800" onClick={closeModal}>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
          <form className='py-5 px-3'>
            <div className="space-y-4">
              {fields?.map((field) => (
                <div key={field.name}>
                  {field.type === 'divider' ? (
                    <hr className="border-gray-400"/>
                  ) : (
                    <>
                      {field.name === 'mobilePhoneNo' && (
                        <>
                          <div className="flex items-center">
                            <label htmlFor={field.name} className="w-1/3 text-left pr-4 font-bold">
                              {field.label} {field.required && <span className="text-red-500">*</span>}
                            </label>
                            <input type={field.type} id={field.name} name={field.name} className="w-2/3 border rounded px-2 py-1"
                                   required={field.required}/>
                          </div>
                          <div className="flex items-center justify-end text-xs text-gray-500 font-bold">{field.example}</div>
                        </>
                      )}
                      {field.name !== 'mobilePhoneNo' && (
                        <div className="flex items-center">
                          {field.type !== 'checkbox' && (
                            <label htmlFor={field.name} className="w-1/3 text-left pr-4 font-bold">
                              {field.label} {field.required && <span className="text-red-500">*</span>}
                            </label>
                          )}
                          {field.type === 'select' ? (
                            <select id={field.name} name={field.name} className="w-2/3 border rounded px-2 py-1" required={field.required}>
                              <option value=""></option>
                              {field.options?.map((option) => (
                                <option key={option} value={option}>{option}</option>
                              ))}
                            </select>
                          ) : field.type === 'radio' ? (
                            <div className="w-2/3 flex space-x-4">
                              {field.options?.map((option) => (
                                <div key={option.value} className="flex items-center">
                                  <input type="radio" id={option.value} name={field.name} value={option.value} className="mr-2"/>
                                  <label htmlFor={option.value}>{option.label}</label>
                                </div>
                              ))}
                            </div>
                          ) : field.type === 'checkbox' ? (
                            <div className="w-3/3 flex flex-row">
                                                            <span>
                                                                <input type="checkbox" id={field.name} name={field.name} required={field.required}/>
                                                            </span>
                              <span className='ml-2'>
                                                                <label htmlFor={field.name} className="">{field.label}</label>
                                                            </span>
                            </div>
                          ) : (
                            <input type={field.type} id={field.name} name={field.name} className="w-2/3 border rounded px-2 py-1"
                                   required={field.required}/>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-center mt-6">
              <button type="submit" className="bg-primary text-white py-2 px-6 rounded-3xl border border-primary mr-4">
                SUBMIT
              </button>
              <button type="reset" className="bg-gray-400 text-white py-2 px-6 rounded-3xl border border-primary">
                RESET
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserSignMeUp;
