import React from "react";

const ConfirmationDialog = ({
  show,
  setShow,
  title,
  onConfirm,
  onCancel,
  varient,
}) => {
  return (
    show && (
      <div className="overflow-y-auto bg-[#0000008c] fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
        <div className="flex justify-center items-center p-4 w-full h-screen">
          <div className="relative p-4 text-center bg-white rounded-lg shadow sm:p-5">
            <button
              type="button"
              className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={() => setShow(false)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>

            <p className="mb-4 px-8 text-gray-500">{title}</p>
            <div className="flex justify-center items-center space-x-4">
              <button
                type="button"
                className="py-2 px-3 text-sm font-medium text-gray-500 bg-[#d3d3d3] rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10"
                onClick={() => {
                  onCancel();
                  setShow(false);
                }}
              >
                No, cancel
              </button>
              <button
                type="submit"
                className={`py-2 px-3 text-sm font-medium text-center text-white ${
                  varient === "success"
                    ? "bg-green-600 hover:bg-green-700 focus:ring-green-300"
                    : "bg-red-600 hover:bg-red-700 focus:ring-red-300"
                }  rounded-lg  focus:ring-4 focus:outline-none `}
                onClick={() => {
                  onConfirm();
                  setShow(false);
                }}
              >
                Yes, I'm sure
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ConfirmationDialog;
