import { useContext, useEffect } from "react";
import { MemberContext } from "../../context/MemberContext";
import CountdownTimer from "../../components/Timer";

const MyJoinGiveaways = () => {
  const { myJoinedGiveaways, getjoinedGiveaways } = useContext(MemberContext);

  useEffect(() => {
    getjoinedGiveaways();
  }, []);

  return (
    <div className="min-h-screen flex flex-col relative">
      <div className="min-h-screen bg-white-200 flex-grow">
        <div className="p-5">
          <div className="flex justify-center">
            <h2 className="section-header">MY JOINED GIVEAWAYS</h2>
          </div>
        </div>

        <div className="mt-4 overflow-auto">
          <div className="flex flex-col items-center md:items-start md:flex-row md:flex-wrap p-4">
            {myJoinedGiveaways && myJoinedGiveaways.length > 0 ?
              myJoinedGiveaways?.map((card, index) => (
                <div key={index} className="p-4">
                  <Card
                    value={card.value}
                    imageUrl={card.image || "/assets/giveaway/placeholder.png"}
                    card={card}
                  />
                </div>
              )) : <div className="w-full text-center">You haven't joined any giveaway yet.</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

const Card = ({ value, imageUrl, card }) => (
  <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:border-gray-700 p-4">
    <div className="p-5 justify-center text-center">
      <p className="text-black text-xl font-bold">${value} CASH</p>
    </div>
    <div className="justify-center text-center">
      <p className="text-4xl color-primary font-bold">VALUED AT: ${value}</p>
    </div>
    <img className="rounded-t-lg" src={imageUrl} alt="" />
    {card.is_join_able &&
      <div className="p-5 justify-center">
        <CountdownTimer date={card?.giveaway_date} />
      </div>
    }
    {card.is_winners_declared ?
      <div className="p-5 justify-center">
        {card.winners?.map((winner, index) => (
          <p key={index} className="mb-2 text-base font-medium text-gray-700 dark:text-gray-400 text-center">
            Winner {winner.rank} Name: <span className="font-bold">{winner.user.name}</span>
          </p>
        ))}
      </div>
      : <div className="w-full text-center">Winners will be annouced soon!.</div>
    }
  </div>
);

export default MyJoinGiveaways;
