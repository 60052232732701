import MySubScription from "./MySubScription"

const MemberDashboard = () => {

  return (
    <>
      <MySubScription />
    </>
  )
}

export default MemberDashboard