const NotFound = () => {

  return (
    <>
      <div style={{height: "100vh", width: "100%", backgroundColor: "white", paddingTop: "5%"}}>
        <p className="flex justify-center items-center "> 404 page not found</p>
      </div>
    </>
  )
}

export default NotFound