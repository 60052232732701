import {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";
import TermsAndConditionsEn from '../../lang/terms_and_conditions/terms_condition_en';
import TermsAndConditionsMs from '../../lang/terms_and_conditions/terms_conditions_ms';
import TermsAndConditionsZh from '../../lang/terms_and_conditions/terms_conditions_zh';

const TermsAndConditions = () => {

  const {language} = useContext(AuthContext);
  let TermsAndCondtionsComponent

  switch (language) {
    case 'en':
      TermsAndCondtionsComponent = TermsAndConditionsEn;
      break;
    case 'ms':
      TermsAndCondtionsComponent = TermsAndConditionsMs;
      break;
    case 'zh':
      TermsAndCondtionsComponent = TermsAndConditionsZh;
      break;
    default:
      TermsAndCondtionsComponent = TermsAndConditionsEn; // Default to English
  }

  return (
    <>
      <TermsAndCondtionsComponent/>
    </>
  )
}

export default TermsAndConditions