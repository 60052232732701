import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { MerchantContext } from "../../../context/MerchantContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./MerchantDiscount.css"; // Import the CSS file
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import EditDiscountModal from "./EditDiscountModal";
import { toast } from "react-toastify";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
const MerchantDiscount = () => {
  const { getMerchantDiscount, merchantDiscountData, deleteDiscount } =
    useContext(MerchantContext);
  const { user, language } = useContext(AuthContext); // Assuming 'language' is available in AuthContext
  const token = user?.token;
  const translations = require(`../../../lang/common/common_${language}.json`);
  const [openId, setOpenId] = useState();
  const [open, setOpen] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [deleteId, setdeleteId] = useState()

  useEffect(() => {
    if (token) {
      getMerchantDiscount(token);
    }
  }, [token]);

  const getLocalizedField = (fieldName) => {
    return `${fieldName}_${language}`;
  };

  const handleEdit = (discount) => {
    // navigate(`/edit-discount/${discount.id}`, { state: { discount } });
    setOpenId(discount.id);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteDiscount(deleteId);
      setdeleteId()
      toast.success("Discount deleted successfully");
    } catch (error) {
      console.error("Error deleting discount:", error);
    }
  };

  return (
    <>
      <EditDiscountModal id={openId} open={open} onClose={() => {
        setOpen(false);
        setOpenId(null);
      }}/>
      <ConfirmationDialog
        show={showPopUp}
        setShow={setShowPopUp}
        title="Are you sure you want to delete the discount?"
        onConfirm={async() => {
          await handleDelete();
        }}
        onCancel={() => {
          setdeleteId()
        }}
        varient={"danger"}
      />
      <div className="h-auto p-4">
        <h2 className="text-xl font-semibold mb-4 text-primary">
          My Discounts
        </h2>
        <div className="overflow-x-auto">
          <Table className="text-gray-500 rounded-md border">
            <Thead className="text-gray-700 bg-gray-50 border-b">
              <Tr className="">
                <Th className="px-4 py-2">{translations.discountTable.Id}</Th>
                <Th className="px-4 py-2">
                  {translations.discountTable.Title}
                </Th>
                {/* <Th className="px-4 py-2">
                  {translations.discountTable["Short Description"]}
                </Th>
                <Th className="px-4 py-2">
                  {translations.discountTable["Long Description"]}
                </Th>
                <Th className="px-4 py-2">
                  {translations.discountTable.Terms}
                </Th> */}
                <Th className="px-4 py-2">
                  {translations.discountTable["Start Date"]}
                </Th>
                <Th className="px-4 py-2">
                  {translations.discountTable["End Date"]}
                </Th>
                <Th className="px-4 py-2">
                  {translations.discountTable["Coupon Code"]}
                </Th>
                {/* <Th className="px-4 py-2">
                  {translations.discountTable["QR Pic"]}
                </Th>
                <Th className="px-4 py-2">
                  {translations.discountTable["Promo Pic"]}
                </Th> */}
                <Th className="px-4 py-2">{translations.discountTable.URL}</Th>
                <Th className="px-4 py-2">
                  {translations.discountTable["Is Active"]}
                </Th>
                <Th className="px-4 py-2">
                  {translations.discountTable.Actions}
                </Th>
              </Tr>
            </Thead>
            <Tbody className="justify-center text-center">
              {merchantDiscountData?.map((discount, index) => (
                <Tr
                  key={index}
                  className={"odd:bg-white even:bg-gray-50  border-b "}
                >
                  <Td className="px-4 py-2">{discount.id}</Td>
                  <Td className="px-4 py-2">
                    {discount[getLocalizedField("title")]}
                  </Td>
                  {/* <Td className="px-4 py-2">
                    {discount[getLocalizedField("short_description")]}
                  </Td>
                  <Td className="px-4 py-2">
                    {discount[getLocalizedField("long_description")]}
                  </Td>
                  <Td className="px-4 py-2">
                    {discount[getLocalizedField("terms")]}
                  </Td> */}
                  <Td className="px-4 py-2">{discount.start_date}</Td>
                  <Td className="px-4 py-2">{discount.end_date}</Td>
                  <Td className="px-4 py-2">{discount.coupon_code}</Td>
                  {/* <Td className="px-4 py-2">{discount.qr_pic}</Td>
                  <Td className="px-4 py-2">{discount.promo_pic}</Td> */}
                  <Td className="px-4 py-2">{discount.url}</Td>
                  <Td className="px-4 py-2">{discount.is_active ? 'Yes' : 'No'}</Td>
                  <Td className="px-4 py-2">
                    <button
                      onClick={() => handleEdit(discount)}
                      className="color-primary hover:text-blue-900 mr-2"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                      onClick={() => {
                        setdeleteId(discount.id)
                        setShowPopUp(true)
                      } 
                    }
                      className="text-red-600 hover:text-red-900"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default MerchantDiscount;
