const Winners = () => {

  return (
    <>
      <div className="h-auto w-full bg-gray-200 pt-20">
        <div className="flex bg-cover bg-center">
          <div className="mx-auto md:px-0 lg:px-0">

            <div className="pt-4">
              <div className="flex flex-col items-center justify-center text-center relative">
                <div className="absolute inset-0 bg-cover bg-center opacity-20"
                     style={{backgroundImage: "url('https://placehold.co/2560x1708')"}}></div>
                <div className="relative z-10 p-6 md:p-6 lg:p-16">
                  <h1 className="text-black text-2xl md:text-3xl lg:text-4xl font-bold leading-tight mb-4">Previous
                    Winners</h1>
                  {/* <p className="text-black text-sm md:text-base lg:text-sm">Often imitated, never replicated….</p> */}
                </div>
              </div>
            </div>

            <div className="px-6 md:px-12 lg:px-24 py-6 md:py-10 bg-white">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="relative overflow-hidden bg-cover bg-no-repeat group">
                  <img
                    src="https://placehold.co/848x565"
                    className="w-full transition-transform duration-500 ease-in-out transform hover:scale-110"
                    alt="Image"
                  />
                  <div
                    className="absolute inset-0 flex flex-col justify-center items-start p-6 opacity-0 group-hover:opacity-100 transition duration-500 ease-in-out bg-gradient-to-t from-blue-500 to-transparent">
                    <h1 className="text-white text-2xl font-bold">$550,000 Cash – 14/04/24</h1>
                    <p className="text-white text-base">1st Prize $550,000 : Shelby H</p>
                    <p className="text-white text-base">2nd Prize $2,500 Credit : Cameron</p>
                    <p className="text-white text-base">3rd Prize $1,000 Credit : Attilio R</p>
                  </div>
                </div>
                <div className="relative overflow-hidden bg-cover bg-no-repeat group">
                  <img
                    src="https://placehold.co/848x565"
                    className="w-full transition-transform duration-500 ease-in-out transform hover:scale-110"
                    alt="Image"
                  />
                  <div
                    className="absolute inset-0 flex flex-col justify-center items-start p-6 opacity-0 group-hover:opacity-100 transition duration-500 ease-in-out bg-gradient-to-t from-blue-500 to-transparent">
                    <h1 className="text-white text-2xl font-bold">$550,000 Cash – 14/04/24</h1>
                    <p className="text-white text-base">1st Prize $550,000 : Shelby H</p>
                    <p className="text-white text-base">2nd Prize $2,500 Credit : Cameron</p>
                    <p className="text-white text-base">3rd Prize $1,000 Credit : Attilio R</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="px-24 py-12">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="relative  h-full">
                  <div className="overflow-hidden bg-cover bg-no-repeat h-full">
                    <img
                      src="https://placehold.co/416x278"
                      className="w-full transition duration-500 ease-in-out hover:scale-110"
                      alt="Image"
                      // height=
                    />
                  </div>
                  <div
                    className="absolute inset-0 flex flex-col justify-end items-start pointer-events-none lg:p-6 md:p-8">
                    <h1 className="text-white text-2xl font-bold"> $550,000 Cash – 14/04/24</h1>
                    <p className="text-white text-base">1st Prize $550,000 : Shelby H</p>
                    <p className="text-white text-base">2nd Prize $2,500 Credit : Cameron</p>
                    <p className="text-white text-base">3rd Prize $1,000 Credit : Attilio R</p>
                  </div>
                </div>
                {/* <!-- Repeat the above structure for the other three parts --> */}
                <div className="relative">
                  <div className="overflow-hidden bg-cover bg-no-repeat">
                    <img
                      src="https://placehold.co/416x278"
                      className="w-full transition duration-500 ease-in-out hover:scale-110"
                      alt="Image"
                    />
                  </div>
                  <div
                    className="absolute inset-0 flex flex-col justify-end items-start pointer-events-none lg:p-6 md:p-8">
                    <h1 className="text-white text-2xl font-bold"> $550,000 Cash – 14/04/24</h1>
                    <p className="text-white text-base">1st Prize $550,000 : Shelby H</p>
                    <p className="text-white text-base">2nd Prize $2,500 Credit : Cameron</p>
                    <p className="text-white text-base">3rd Prize $1,000 Credit : Attilio R</p>
                  </div>
                </div>
                {/* <!-- Repeat the above structure for the other two parts --> */}
                <div className="relative">
                  <div className="overflow-hidden bg-cover bg-no-repeat">
                    <img
                      src="https://placehold.co/416x278"
                      className="w-full transition duration-500 ease-in-out hover:scale-110"
                      alt="Image"
                    />
                  </div>
                  <div
                    className="absolute inset-0 flex flex-col justify-end items-start pointer-events-none lg:p-6 md:p-8">
                    <h1 className="text-white text-2xl font-bold"> $550,000 Cash – 14/04/24</h1>
                    <p className="text-white text-base">1st Prize $550,000 : Shelby H</p>
                    <p className="text-white text-base">2nd Prize $2,500 Credit : Cameron</p>
                    <p className="text-white text-base">3rd Prize $1,000 Credit : Attilio R</p>
                  </div>
                </div>

                <div className="relative">
                  <div className="overflow-hidden bg-cover bg-no-repeat">
                    <img
                      src="https://placehold.co/416x278"
                      className="w-full transition duration-500 ease-in-out hover:scale-110"
                      alt="Image"
                    />
                  </div>
                  <div
                    className="absolute inset-0 flex flex-col justify-end items-start pointer-events-none lg:p-6 md:p-8">
                    <h1 className="text-white text-2xl font-bold"> $550,000 Cash – 14/04/24</h1>
                    <p className="text-white text-base">1st Prize $550,000 : Shelby H</p>
                    <p className="text-white text-base">2nd Prize $2,500 Credit : Cameron</p>
                    <p className="text-white text-base">3rd Prize $1,000 Credit : Attilio R</p>
                  </div>
                </div>

              </div>
            </div>

            <div>
              <div className="bg-primary shadow-lg px-6 py-10 md:py-20 text-center">
                <h2 className="text-white text-2xl mb-4">You could be one of these lucky winners!</h2>
                <button type="button"
                        className="text-white border border-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5">Join
                  REWARD CLUB
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>

  )
}

export default Winners