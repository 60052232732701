import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import NearestGiveaway from "./NearestGiveaway";
import GiveawaysGrid from "./GiveawaysGrid";

const DailyMembersGiveAways = () => {
  const { language } = useContext(AuthContext);
  const translations = require(`../../lang/common/common_${language}.json`);

  return (
    <>
      <div className="min-h-screen flex flex-col relative">
        <div className="min-h-[100vh] bg-primary flex-grow p-4 md:p-36">
          <div className="flex justify-center">
            <h2 className="section-header-white p-4 mb-10">
              {translations.home.upcomingGiveaways.title}
            </h2>
          </div>
          <NearestGiveaway />
        </div>
        <div className="min-h-screen bg-white-200 flex-grow">
          <div className="p-5">
            <div className="flex justify-center">
              <h2 className="section-header">
                {translations.home.upcomingGiveaways.content.title}
              </h2>
            </div>

            <div className="flex justify-center mt-5">
              <div className="text-s font-bold">
                {translations.home.upcomingGiveaways.content.joinNow}
              </div>
            </div>
          </div>
          <GiveawaysGrid />
        </div>
      </div>
    </>
  );
};

export default DailyMembersGiveAways;
