import { useContext } from "react";
import FaqToggle from "./FaqToggle"
import { AuthContext } from "../../context/AuthContext";
import { MemberContext } from "../../context/MemberContext";

const Faqs = () => {

  const { language } = useContext(AuthContext);
  const { faqsData } = useContext(MemberContext);
  const translations = require(`../../lang/common/common_${language}.json`);

  return (
    <>
      <div style={{ height: "auto", width: "100%", backgroundColor: "white", paddingTop: "5%" }}>
        <div className="flex bg-cover bg-center" style={{ height: "auto", padding: "40px", minHeight: "100vh" }}>
          <div className="container mx-auto flex"
            style={{ color: "#333", display: "flex", flexDirection: "column", textAlign: "left", marginLeft: "15%", marginRight: "15%" }}>

            <div className="mb-6">
              <div className=" flex justify-center">
                <h2 className="section-header">{translations.home.faqs.faqsTitle}</h2>
              </div>

              <div className="mt-4">

                {faqsData?.map((faq, index) => {
                  const questionKey = `question_${language}`;
                  const answerKey = `answer_${language}`;

                  return (
                    <FaqToggle key={index} question={faq[questionKey]} answer={faq[answerKey]} />
                  );
                })}
                
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default Faqs



