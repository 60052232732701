import { useContext, useState } from "react";
import CreateDiscount from "./discount/CreateDiscount";
import MerchantDiscount from "./discount/MerchantDiscount";
import { AuthContext } from "../../context/AuthContext";

const MyDiscounts = () => {

    const { user } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [discountList, setDiscountList] = useState([]); // State to store the discount list
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <>
            {user?.is_approved === 1 ? (
                <div className="flex justify-center min-h-screen-full bg-min-height">
                    <div className="z-50 w-full mt-4 h-auto">
                        <div className="flex justify-end p-4">
                            <button
                                type="button"
                                className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 mb-2"
                                //  text-white py-2 px-4 rounded
                                onClick={handleOpenModal}
                            >
                                Create Discount
                            </button>
                        </div>
                        <MerchantDiscount discountList={discountList} /> {/* Pass discountList as a prop */}
                        <CreateDiscount
                            isModalOpen={isModalOpen}
                            handleCloseModal={handleCloseModal}
                            updateDiscountList={setDiscountList}
                            discountList={discountList}
                            isEditDiscount={false} // Pass the function to update discountList
                        />
                    </div>
                </div>
            ) : (
                <p>
                    <div className="flex justify-center min-h-screen-full bg-min-height">
                        <div className="justify-center mt-36">
                            <h2>You are not Approved Yet!</h2>
                        </div>
                    </div>
                </p>
            )}

        </>
    )
}

export default MyDiscounts