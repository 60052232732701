import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './logoSliderStyle.css';


const LogoSlider = () => {

    const settings = {
        // dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        csEase: 'linear',
        arrows: false,
        centerMode: true,
        focusOnSelect: false,

    }

    return (
        <>
            <Slider {...settings}>
                {[...Array(15)]?.map((_, index) => (
                    <div key={index}>
                        <img src={`/assets/home_slider/${index + 1}.png`} alt={`Image ${index + 1}`}
                             style={{maxHeight: 200, width: 'auto', padding: 0, margin: 0}}/>
                    </div>
                ))}
            </Slider>
        </>
    )
}

export default LogoSlider