import SignUpPlanPricing from "../../components/SignUpPlanPrice";


const SignUp = () => {

  const img = [

    {id: 1, path: "assets/signup/1.jpg", name: ""},
    {id: 2, path: "assets/signup/2.png", name: ""},
    {id: 3, path: "assets/signup/3.jpg", name: ""},
    {id: 4, path: "assets/signup/4.jpg", name: ""},
    {id: 5, path: "assets/signup/5.png", name: ""},
    {id: 6, path: "assets/signup/6.jpg", name: ""},
    {id: 7, path: "assets/signup/7.png", name: ""},
    {id: 8, path: "assets/signup/8.png", name: ""},
    {id: 9, path: "assets/signup/9.png", name: ""},
    {id: 10, path: "assets/signup/10.png", name: ""},
  ]


  return (
    <>
      <div className="h-auto min-h-screen">

        <div className="flex flex-col md:pl-8 md:pr-8 pt-40">

          <div className="flex flex-col md:pl-40 md:pr-40" style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
            {img?.map((image, index) => (
              <div key={image.id}>
                <img src={image.path} alt="Promo" className="w-full h-50"/>
                {index === 4 && (
                  <div className="text-center m-4 h-auto">
                    <div className="flex justify-center m-5">
                      <div className='text-2xl font-bold'>SELECT A PACKAGE BELOW & BECOME A MEMBER TODAY!</div>
                    </div>
                    <SignUpPlanPricing/>
                  </div>
                )}
              </div>

            ))}

          </div>
        </div>

      </div>

    </>
  )
}

export default SignUp;
