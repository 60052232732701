import { useContext, useEffect, useState } from "react";
import VoucherModal from "../../components/VoucherModal";
import { NavLink, useNavigate } from "react-router-dom";
import LeftMenu from "../../components/DiscountMenu";
import { AuthContext } from "../../context/AuthContext";
import { getRequest } from "../../utils/helper";
import { MemberContext } from "../../context/MemberContext";

const Discount = () => {
  const [current, setCurrent] = useState(0);

  const { language, user, setOpenLoginModal } = useContext(AuthContext);
  const { categories, discounts, setDiscounts } = useContext(MemberContext);
  const translations = require(`../../lang/common/common_${language}.json`);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [discountsPerPage] = useState(5);
  const navigate = useNavigate();

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [towns, setTowns] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedTown, setSelectedTown] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    getRequest('/states').then(response => {
      setStates(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedState) {
      getRequest(`/states/${selectedState}/cities`).then(response => {
        setCities(response.data);
        setTowns([]);
        setSelectedCity(null);
        setSelectedTown(null);
      });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity) {
      getRequest(`/cities/${selectedCity}/towns`).then(response => {
        setTowns(response.data);
        setSelectedTown(null);
      });
    }
  }, [selectedCity]);

  const openModal = (e) => {
    setCurrent(e);
    if (user) {
      setIsModalOpen(true);
    } else {
      // navigate("/login");
      setOpenLoginModal(true);
    }
  };

  useEffect(() => {
    getRequest("/discounts", {
      town_id: selectedTown,
      category_id: selectedCategory,
    }).then((response) => {
      setDiscounts(response.data);
    });
  }, [selectedTown, selectedCategory]);

  // Logic for pagination
  const indexOfLastDiscount = currentPage * discountsPerPage;
  const indexOfFirstDiscount = indexOfLastDiscount - discountsPerPage;
  const currentDiscounts = discounts.slice(
    indexOfFirstDiscount,
    indexOfLastDiscount
  ).filter((discount) => discount.is_expired === false);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div
        className={`flex h-auto w-full discount-page ${user ? "pt-0" : "pt-24"
          }`}
        style={{ minHeight: "100vh" }}
      >
        <div className="flex flex-col top-header">
          {!user && (
            <div className="px-6 py-10 text-center shadow-lg bg-primary md:py-20">
              <div className="text-center ">
                <h5 className="py-10 text-2xl font-bold text-white md:text-4xl md:py-12">
                  {translations.home.discount.membershipPrivileges}
                </h5>
              </div>
            </div>
          )}

          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/5 left-discount">
              <div className="sm:mt-10 md:mt-10">
                <div>
                  <LeftMenu
                    states={states}
                    cities={cities}
                    towns={towns}
                    categories={categories}
                    selectedState={selectedState}
                    selectedCity={selectedCity}
                    selectedTown={selectedTown}
                    selectedCategory={selectedCategory}
                    setSelectedState={setSelectedState}
                    setSelectedCity={setSelectedCity}
                    setSelectedTown={setSelectedTown}
                    setSelectedCategory={setSelectedCategory}
                    language={language}
                  />
                </div>
              </div>
            </div>

            <div className="w-full md:w-4/5 right-discount">
              <div className="w-full">
                <div>
                  <div className="w-full px-0 mx-auto md:px-0 lg:px-0">
                    <div>
                      {currentDiscounts?.map((discount) => (
                        <div
                          className="p-10 m-10 bg-white main-content drop-shadow"
                          key={discount?.id}
                        >
                          <div className="flex flex-wrap items-center">
                            <div className="w-full md:w-1/5 md:order-1">
                              <p className="self-center text-center md:text-left color-primary">{discount?.user?.business_name ?? discount?.user?.name ?? ''}</p>
                              <p className="self-center text-center md:text-left color-primary">
                                {discount[`title_${language}`]}
                              </p>
                              <img
                                src={discount?.user?.image || "/assets/discount/placeholder.png"}
                                alt="Logo"
                                className="w-20 h-auto pt-4 mx-auto md:mx-0 md:pt-0"
                              />
                            </div>
                            <div className="w-full mt-4 md:w-3/5 md:order-2 md:text-left md:mt-0">
                              <p className="mb-2 text-sm text-gray-600">
                                Exclusive | Expires On {discount?.end_date}
                              </p>
                              <p className="mb-2 text-lg text-black">
                                {discount[`short_description_${language}`]}
                              </p>
                              <details>
                                <summary className="cursor-pointer text-black-600">
                                  More Details
                                </summary>
                                <p className="mt-2 mb-4">
                                  {discount[`long_description_${language}`]}
                                </p>
                              </details>
                              <div className="mt-4 mb-4">
                                <NavLink
                                  to={`/discount-details/${discount.user.id}`}
                                  className="mt-6 mb-6 color-primary"
                                >{`See all ${discount?.user?.name} vouchers >`}</NavLink>
                              </div>
                            </div>

                            {/* TODO:: is_expired === true then instead of button show expired message */}
                            <div className="flex justify-center w-full mt-4 md:w-1/5 md:justify-end md:order-3 md:mt-0">
                              <button
                                onClick={() => openModal(discount.id)}
                                className="px-10 py-3 text-white border-none bg-primary hover:bg-primary-dark rounded-2xl"
                              >
                                View Voucher
                              </button>
                            </div>
                            {/* TODO:: is_expired === true then instead of button show expired message */}
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* Pagination controls */}
                    <div className="flex justify-end pb-10 pr-10">
                      <ul
                        className="pagination"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#f5f5f5",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        {Array.from({
                          length: Math.ceil(
                            discounts.length / discountsPerPage
                          ),
                        }).map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                            style={{ margin: "5px" }}
                          >
                            <button
                              onClick={() => paginate(index + 1)}
                              className="page-link"
                              style={{
                                backgroundColor: "#e0e0e0",
                                border: "none",
                                padding: "5px 10px",
                                borderRadius: "3px",
                              }}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <VoucherModal
            disId={current}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            language={language}
          />
        )}
      </div>
    </>
  );
};

export default Discount;
