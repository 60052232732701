import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import SignUpPopUp from '../../components/SignUpPopUp';
import { AuthContext } from '../../context/AuthContext';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const UserSignup = (props) => {
  const { isUserSignupModalOpen, setIsUserSignupModalOpen, FacebookSignup, GoogleSignup, setOpenLoginModal } = useContext(AuthContext);
  const [showPopUp, setShowPopUp] = useState(false);

  const closeModal = () => {
    setIsUserSignupModalOpen(false);
  };

  const handleOpenPopUp = () => {
    setShowPopUp(true);
  };

  const handleLoginClick = () => {
    setIsUserSignupModalOpen(false);
    setOpenLoginModal(true); 
  };

  const handleFacebookProfileSuccess = async (response) => {
    try {
      await FacebookSignup({
        id: response.id,
        name: response.name,
        email: response.email
      });
    } catch (error) {
      console.error('Error signing up with Facebook:', error);
    }
  };

  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      try {
        const userInfo = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        if(userInfo?.data) {
          await GoogleSignup({
            id: userInfo?.data?.sub,
            name: userInfo?.data?.name,
            email: userInfo?.data?.email
          });
        }
      } catch (error) {
        console.error('Error signing up with Google:', error);
      }
    },
  });

  return (
    <>
      <div className="flex items-center justify-center h-screen">
        {isUserSignupModalOpen && (
          <div className="modal-container">
            <div className="modal-content md:max-w-[20%]" style={{ maxHeight: '95%', height: "auto" }}>
              <div className="absolute top-0 right-0 pt-2 pr-4">
                <button className="text-gray-400 hover:text-gray-800" onClick={closeModal}>
                  <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="p-8 font-style">
                <div className="text-center">
                  <div className="flex justify-center mb-8">
                    <img src="/assets/logo.png" alt="logo" className="h-12" />
                  </div>
                  <p className="mb-4">Login / Sign up to continue</p>
                </div>

                <div className="grid grid-cols-1 gap-4 mb-4">
                  <button onClick={() => login()} className="flex items-center justify-center px-4 py-2 space-x-2 border border-gray-300 rounded">
                    <FontAwesomeIcon icon={faGoogle} className="w-6 h-6" />
                    <span className="flex-grow">Sign in with Google</span>
                  </button>

                  {/* DV : 781035050674472 */}
                  {/* AV : 356334700858443 */}
                  <FacebookLogin
                    appId="356334700858443"
                    onSuccess={(response) => {
                      console.log('Login Success!', response);
                    }}
                    onFail={(error) => {
                      console.log('Login Failed!', error);
                    }}
                    onProfileSuccess={handleFacebookProfileSuccess}
                    style={{
                      backgroundColor: '#4267b2',
                      color: '#fff',
                      fontSize: '16px',
                      padding: '12px 24px',
                      border: 'none',
                      borderRadius: '4px',
                    }}
                  />

                  <button
                    className="flex items-center justify-center px-4 py-2 space-x-2 text-pink-500 bg-white border border-pink-500 rounded"
                    onClick={handleOpenPopUp}
                  >
                    <span>Continue with Phone Number</span>
                  </button>

                  <p>already registered? <span className='text-blue-500 underline' onClick={handleLoginClick}>login</span></p>
                </div>

              </div>
            </div>
          </div>
        )}
      </div>
      {showPopUp && (
        <SignUpPopUp showPopUp={showPopUp} onClose={() => setShowPopUp(false)} />
      )}
    </>
  );
};

export default UserSignup;
