import {useContext} from "react";
import QSlider from "../../components/QSlider";
import "../../styles/style.css"
import {AuthContext} from "../../context/AuthContext";

const AboutUs = () => {

  const {language} = useContext(AuthContext);
  const translations = require(`../../lang/common/common_${language}.json`);

  return (
    <>

      <div className="pt-24">
        <div className="h-auto w-full">
          <div
            className="bg-white flex flex-col justify-center items-center px-4 lg:px-0 py-8 lg:py-0 about-text mb-10">

            <div className=" flex justify-center mt-10">
              <h2 className="section-header">{translations.aboutUs.header}</h2>
            </div>
            <div className="flex justify-center mt-5 mb-5">
              <div className='text-s font-bold'>{translations.aboutUs.subtitle}</div>
            </div>

            <div className="max-w-2xl text-center mt-10">
              {translations.aboutUs.paragraphs?.map((paragraph, index) => (
                <p key={index} className="mb-4 lg:mb-6">{paragraph}</p>
              ))}
              {/* <p className="mb-4 lg:mb-6">
                                "REWARDCLUB.MY burst onto the scene in 2024 with an ambitious vision: to empower everyday consumers to save thousands through our extensive network while simultaneously making a POSITIVE IMPACT on lives. Today, our tireless team has delivered on that promise, and we are thrilled to announce that we have amassed over 300 partners across 1000+ stores, each offering exclusive deals and offers to our valued members. We have won the trust of Malaysian, and along the way, we have hosted Malaysia's most electrifying promotions, which have led to over 100 lucky winners to date!
                            </p>
                            <p className="mb-4 lg:mb-6">
                                With your unwavering support, we will continue to make a difference not only for our supporters but also for families and communities in need.
                            </p>
                            <p className="mb-4 lg:mb-6">
                                Join us on this journey towards savings and changing lives for the better. Thank you for your trust and continued support. Let's start saving and make a positive difference today!"
                            </p>
                            <p className="mb-4 lg:mb-6">
                                REWARDCLUB.MY
                            </p>
                            <p className="mb-4 lg:mb-6">
                                THE LOYALTY PROGRAM THAT TRULY REWARDS YOU!
                            </p> */}

            </div>
          </div>


        </div>
        <div className="h-auto w-full">
          <QSlider/>
        </div>
      </div>
    </>
  )
}

export default AboutUs