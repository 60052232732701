import React, { useContext, useState } from 'react';
import SignUpPopUp from "./SignUpPopUp";
import { AuthContext } from '../context/AuthContext';
import { MemberContext } from '../context/MemberContext';

const PlanPricing = () => {
  const { language, selectedPlan, setSelectedPlan, setIsUserSignupModalOpen } = useContext(AuthContext);
  const { plans } = useContext(MemberContext);
  const translations = require(`../lang/common/common_${language}.json`);
  const [showPopUp, setShowPopUp] = useState(false);

  const handleSelectButtonClick = (plan) => {
    setIsUserSignupModalOpen(true);
    // setSelectedPlan(plan);
    // setShowPopUp(true);
  };

  const handleClosePopUp = () => {
    setShowPopUp(false);
  };

  return (
    <div className="flex flex-col bg-white w-full h-100 p-10">
      <div>
        <div className="flex justify-center">
          <h2 className="section-header">{translations.home.planPricing.sectionHeader}</h2>
        </div>

        <div className="flex justify-center mt-5">
          <div className='text-s font-bold'>{translations.home.planPricing.subHeader}</div>
        </div>

        <div className="md:grid md:grid-cols-2 lg:grid-cols-5 gap-4 mt-10">
          {/* Plan Cards */}
          {plans?.map((plan, index) => {
            const nameKey = `name_${language}`;
            const priceKey = `price_${language}`;
            const featuresKey = `features_${language}`;
            const durationNumber = `duration_number_${language}`;
            const durationUnit = `duration_unit_${language}`;
            
            const featuresList = plan[featuresKey].split(',')?.map(feature => feature.trim());

            return (
              <div key={index} className="col-span-1 p-2 h-full">
                <div className="bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden plan-card">
                  <div className="border-t-4 border-blue-500 px-6 py-4">
                    <h2 className="color-primary text-4xl font-bold mb-4 mt-4">{plan[nameKey]}</h2>
                    <div className="flex justify-center">
                      <div className="border-b border-blue-500 w-8 mb-4 w-full"></div>
                    </div>
                    <p className="font-bold text-4xl">${plan[priceKey]}</p>
                    <p className="mt-4">{translations?.home?.planPricing?.paymentType}</p>
                  </div>
                  <div className="px-6 py-4 flex-grow">
                    <ul className="list-inside text-left">
                      {featuresList?.map((feature, index) => (
                        <span key={index} className='flex flex-row items-center'>
                          <i className="fa-solid fa-check color-primary mt-2 mr-2"></i>
                          <li className="list-check text-sm">{index === 0 ? `${plan[durationNumber]} ${plan[durationUnit]} ` : ''}{feature}</li>
                        </span>
                      ))}
                    </ul>
                  </div>
                  <div className="px-6 py-4 mb-5 text-center">
                    <button
                     onClick={() => handleSelectButtonClick(plan)}
                      className="bg-primary hover:bg-primary-dark text-white py-2 px-4 rounded">
                      {language === 'en' ? 'Select Plan' : language === 'ms' ? 'Pilih Pelan' : '选择计划'}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {showPopUp && (
        <SignUpPopUp showPopUp={showPopUp} onClose={handleClosePopUp} />
      )}
    </div>
  );
};

export default PlanPricing;
