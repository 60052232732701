import { useContext, useEffect, useState } from "react";
import VoucherModal from "../../components/VoucherModal"
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { getRequest } from "../../utils/helper";

const DiscountDetails = () => {
  const { id } = useParams();

  const [discountsDetails, setDiscountDetails] = useState([]);
  const [current, setCurrent] = useState(0);

  const { language } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getRequest('/discounts', { 'user_id': id }).then((response) => {
      setDiscountDetails(response.data);
    });
  }, []);

  const openModal = (e) => {
    setCurrent(e);
    setIsModalOpen(true);
  };

  return (
    <div className="w-full h-auto pt-20 mt-10 bg-gray-200 discount-page">
      <div className="flex min-h-screen py-10 bg-center bg-cover">
        <div className="container px-5 mx-auto md:px-0 lg:px-0">
          {discountsDetails?.map((discount) => (
            <div className="p-10 mb-10 bg-white main-content" key={discount.id}>
              <div className="flex flex-wrap items-center">
                <div className="w-full md:w-1/5 md:order-1">
                  <p className="self-center text-center md:text-left color-primary">{discount?.user?.business_name ?? discount?.user?.name ?? ''}</p>
                  <p className="self-center text-center md:text-left color-primary">{discount[`title_${language}`]}</p>
                  {/* TODO:: image take from user.image and if null show default */}
                  <img src={discount?.user?.image || "/assets/discount/placeholder.png"} alt="Logo"
                    className="w-20 h-auto pt-4 mx-auto md:mx-0 md:pt-0" />
                    {/* TODO:: image take from user.image and if null show default */}
                </div>
                <div className="w-full mt-4 md:w-3/5 md:order-2 md:text-left md:mt-0">
                  <p className="mb-2 text-sm text-gray-600">Exclusive | Expires On {discount?.end_date}</p>
                  <p className="mb-2 text-lg text-black">{discount[`short_description_${language}`]}</p>
                  <details>
                    <summary className="cursor-pointer text-black-600">More Details</summary>
                    <p className="mt-2 mb-4">{discount[`long_description_${language}`]}</p>
                  </details>
                </div>
                {/* TODO:: is_expired === true then instead of button show expired message */}
                <div
                  className="flex justify-center w-full mt-4 md:w-1/5 md:justify-end md:order-3 md:mt-0">
                  <button onClick={() => openModal(discount.id)} className="px-10 py-3 text-white border-none bg-primary hover:bg-primary-dark rounded-2xl">
                    View Voucher
                  </button>
                </div>
                {/* TODO:: is_expired === true then instead of button show expired message */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {isModalOpen && <VoucherModal disId={current} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} language={language} />}
    </div>
  )
}

export default DiscountDetails