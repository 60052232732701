import React, { createContext, useState, useEffect } from "react";
import { postRequest, getRequest } from "../utils/helper";
import { useNavigate } from "react-router-dom";
import Notification from "../components/Notificatio";
import { toast } from "react-toastify";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [language, setLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || "en"; // Set default language to 'en' if not found in localStorage
  });
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [registerInfo, setRegisterInfo] = useState([]);
  const [loginInfo, setLoginInfo] = useState([]);
  const [isUserSignupModalOpen, setIsUserSignupModalOpen] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [isProfileUpdating, setIsProfileUpdating] = useState(false);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const storedUser = localStorage.getItem("rewardClubUser");

    if (storedLanguage) {
      setLanguage(storedLanguage);
    } else {
      // If no language is stored in localStorage, set default language
      setLanguage("en");
    }
    if (storedUser === "undefined") {
      localStorage.removeItem("rewardClubUser");
    }
    if (storedUser !== "undefined") {
      setUser(JSON.parse(storedUser));
    } else {
      setUser("");
    }
  }, []);

  // Function to set language in localStorage
  const setLanguageInLocalStorage = (lang) => {
    localStorage.setItem("language", lang);
    setLanguage(lang);
  };

  // Function to get language from localStorage
  const getLanguageFromLocalStorage = () => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  };

  //  register member function
  const RegisterMember = async (data) => {
    try {
      const headers = {};
      const response = await postRequest("/register-member", data, headers);
      setUser(response.data);
      // Store user data in local storage
      localStorage.setItem("rewardClubUser", JSON.stringify(response.data));
      const role = response.data.role;
      if (role === "member") {
        navigate("/member/dashboard");
      } else if (role === "merchant") {
        navigate("/merchant/dashboard");
      } else {
        navigate("/home");
      }
    } catch (error) {
      console.error("Error registering member:", error);
    }
  };

  //  login member function
  const LoginMember = async (data) => {
    try {
      const headers = {};
      const response = await postRequest("/login", data, headers);
      setUser(response.data);
      // Store user data in local storage
      localStorage.setItem("rewardClubUser", JSON.stringify(response.data));
      const role = response?.data?.role;
      setOpenLoginModal(false);
      setIsUserSignupModalOpen(false);
      if (role === "member") {
        navigate("/member/dashboard");
      } else if (role === "merchant") {
        navigate("/merchant/dashboard");
      }
    } catch (error) {
      // Handle login errors
      console.log("error", error);
      if (error.errors) {
        const errorMessages = Object.values(error.errors).flat();
        setLoginError(errorMessages[0]); // Set the first error message
      } else {
        console.error("Unknown error:", error);
      }
    }
  };

  //  profile member function
  const ProfileMember = async (data) => {
    setIsProfileUpdating(true);
    try {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      const response = await postRequest("/profile-member", data, headers);
      if(response.message === "success"){
        setUser(response.user);
        toast.success("Profile updated successfully");
      }else{
        toast.error("Profile update failed");
      }
      setIsProfileUpdating(false);
    } catch (error) {
      setIsProfileUpdating(false);
      console.error("Error registering member:", error);
      toast.error("Profile update failed");
    }
  };

  //  register member function
  const RegisterMerchant = async (data) => {
    try {
      const headers = {};
      const response = await postRequest("/register-merchant", data, headers);
      setUser(response.data);
      // Store user data in local storage
      localStorage.setItem("rewardClubUser", JSON.stringify(response.data));
      navigate("/merchant/dashboard");
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
      console.error("Error registering member:", error);
    }
  };

  //  login member function
  const LoginMerchant = async (data) => {
    try {
      const headers = {};
      const response = await postRequest("/login", data, headers);
    } catch (error) {
      console.error("Error registering member:", error);
    }
  };

  //  profile merchant function
  const ProfileMerchant = async (data) => {
    setIsProfileUpdating(true);

    try {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      const response = await postRequest("/profile-merchant", data, headers);
      if(response.message === "success"){
        setUser(response.user);
        toast.success("Profile updated successfully");
      }else{
        toast.error("Profile update failed");
      }
      setIsProfileUpdating(false);
    } catch (error) {
      setIsProfileUpdating(false);
      toast.error("Profile update failed");
      console.error("Error registering member:", error);
    }
  };

  // logout function
  const Logout = async () => {
    try {
      localStorage.removeItem("rewardClubUser");
      const headers = {
        Authorization: `Bearer ${user?.token}`,
      };
      setUser("");
      navigate("/");
      const response = await postRequest("/logout", null, headers);
      console.log(response?.data);
    } catch (error) {
      console.error("Error registering member:", error);
    }
  };

  const FacebookSignup = async (data) => {
    try {
      const headers = {};
      const response = await postRequest("/facebook-signup", data, headers);
      <Notification status="true" message="logged in successfully" />;
      setUser(response.data);
      // Store user data in local storage
      localStorage.setItem("rewardClubUser", JSON.stringify(response.data));
      const role = response.data.role;
      setOpenLoginModal(false);
      setIsUserSignupModalOpen(false);
      if (role === "member") {
        navigate("/member/dashboard");
      } else if (role === "merchant") {
        navigate("/merchant/dashboard");
      } else {
        navigate("/home");
      }
    } catch (error) {
      console.error("Error registering member:", error);
    }
  };

  const GoogleSignup = async (data) => {
    try {
      const headers = {};
      const response = await postRequest("/google-signup", data, headers);
      <Notification status="true" message="logged in successfully" />;
      setUser(response.data);
      // Store user data in local storage
      localStorage.setItem("rewardClubUser", JSON.stringify(response.data));
      const role = response.data.role;
      setOpenLoginModal(false);
      setIsUserSignupModalOpen(false);
      if (role === "member") {
        navigate("/member/dashboard");
      } else if (role === "merchant") {
        navigate("/merchant/dashboard");
      } else {
        navigate("/home");
      }
    } catch (error) {
      console.error("Error registering member:", error);
    }
  };

  const refreshUser = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      if (user) {
        const response = await getRequest("/me", undefined, headers);
        setUser(response.data);
        localStorage.setItem("rewardClubUser", JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("Error join giveaways:", error);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        language,
        setLanguage: setLanguageInLocalStorage,
        RegisterMember,
        setRegisterInfo,
        registerInfo,
        selectedPlan,
        setSelectedPlan,
        loginInfo,
        setLoginInfo,
        LoginMember,
        RegisterMerchant,
        Logout,
        ProfileMember,
        ProfileMerchant,
        isUserSignupModalOpen,
        setIsUserSignupModalOpen,
        FacebookSignup,
        GoogleSignup,
        openLoginModal,
        setOpenLoginModal,
        loginError,
        isProfileUpdating,
        refreshUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
