import React from "react";
import moment from "moment";

// {
//     "id": 4,
//     "rank": 1,
//     "price_won": "8000.00",
//     "giveaway_id": 4,
//     "user": {
//         "id": 4,
//         "name": "Member 3",
//         "image": null
//     },
//     "package_name_en": "Anne Pennington",
//     "package_name_ms": "Uma Taylor",
//     "package_name_zh": "Wynter Mcdonald",
//     "draw_date": "25/06/2024 10:51 AM"
// }

const PrizeCard = ({ prize, language }) => {
  return (
    <div className="max-w-sm bg-white border border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700 px-3 mb-5 mx-3 drop-shadow">
      <img
        className="rounded-t-lg w-30 h-30 mx-auto mb-4"
        src={prize?.image || "/assets/giveaway/placeholder.png"} // "/assets/member-icon.png"
        alt=""
      />
      <div className="p-5 text-center">
        <p className="mb-2 text-lg font-bold text-gray-900 dark:text-white">
          {prize?.price_won}
        </p>
        {/* <p className="mb-2 text-base font-medium text-gray-700 dark:text-gray-400">
          Package: {prize[`package_name_${language}`]}
        </p> */}
        <p className="mb-2 text-base font-medium text-gray-700 dark:text-gray-400">
          Name: {prize.user.name}
        </p>
        <p className="mb-3 text-base font-medium text-gray-700 dark:text-gray-400">
          Draw Date:{" "}
          {moment(prize.draw_date, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY")}
        </p>
      </div>
    </div>
  );
};

export default PrizeCard;
