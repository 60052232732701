const MembershipTermsAndConditions = () => {

    return (
        <>
            <div className="privacy-policy-page bg-white" >
                <div className="flex bg-cover bg-center privacy-policy-bg-inner">
                    <div className="container mx-auto flex privacy-policy-bg-inner-style">

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading text-center membership-rcm-header'>Keahlian RCM Malaysia</h2>
                            <h2 className='privacy-policy-p-heading'>Terma & Syarat</h2>
                            <ul className="list-text">
                                <li>Reward Club Malaysia (RCM, kami, kita, atau kami) mengendalikan platform RCM, menyediakan keahlian sekali gus dan kesetiaan kepada mana terma dan syarat ini terpakai.

                                </li>
                                <li>Anda mesti berumur 18 tahun atau lebih untuk mendaftar keahlian RCM.</li>
                                <li>Keahlian RCM tertakluk kepada <a href="#" className="underline text-blue-500">terma
                                    dan syarat</a> yang terpakai kepada penggunaan laman web RCM.
                                </li>
                                <li>Terma dan syarat ini adalah perjanjian yang mengikat antara kami dan setiap ahli platform RCM. Dengan mendaftar keahlian RCM, anda bersetuju dengan terma dan syarat ini serta <a href="#" className="underline text-blue-500">Dasar Privasi</a> kami. Sila baca terma dan syarat serta Dasar Privasi ini dengan teliti sebelum anda membeli keahlian RCM. Untuk maklumat lanjut mengenai penggunaan maklumat peribadi anda dan prosedur untuk tidak menerima, rujuk kepada <a href="#" className="underline text-blue-500">Dasar Privasi</a> kami.</li>
                            </ul>
                            <h2 className="privacy-policy-p-heading mt-6">KEAHLIAN SEKALI GUS</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>Keahlian sekali gus adalah cara yang sempurna untuk mengakses perkhidmatan kami dan platform RCM untuk tempoh yang lebih singkat mengikut keperluan anda.</li>
                                <li>Terdapat lima jenis keahlian sekali gus, seperti berikut:</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <table className="privacy-pocily-table w-full border-collapse">
                                <thead>
                                    <tr className="privacy-member-table-bg">
                                        <th className='terms-privacy-table'>Tier</th>
                                        <th className='terms-privacy-table'>Harga</th>
                                        <th className='terms-privacy-table'>Manfaat</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='terms-privacy-table'>Tahap Pemula</td>
                                        <td className='terms-privacy-table'>RM20</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>Akses 2 hari ke pangkalan data rakan kongsi RCM untuk kupon atau kadar diskaun dengan semua rakan kongsi kami
                                                </li>
                                                <li>Kod diskaun 5% untuk semua pembelian dari kedai dalam talian RCM
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    {/* Ulangi baris yang serupa untuk tahap-tahap lain */}
                                    <tr>
                                        <td className='terms-privacy-table'>Peringkat Perunggu</td>
                                        <td className='terms-privacy-table'>RM30</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>Akses 7 hari ke pangkalan data rakan kongsi RCM untuk kupon atau kadar diskaun dengan semua rakan kongsi kami
                                                </li>
                                                <li>Kod diskaun 10% untuk semua pembelian dari kedai dalam talian RCM
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    {/* Ulangi baris yang serupa untuk tahap-tahap lain */}
                                    <tr>
                                        <td className='terms-privacy-table'>Peringkat Perak</td>
                                        <td className='terms-privacy-table'>RM100</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>Akses 28 hari ke pangkalan data rakan kongsi RCM untuk kupon atau kadar diskaun dengan semua rakan kongsi kami
                                                </li>
                                                <li>Kod diskaun 10% untuk semua pembelian dari kedai dalam talian RCM
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>Peringkat Emas</td>
                                        <td className='terms-privacy-table'>RM250</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>Akses 186 hari ke pangkalan data rakan kongsi RCM untuk kupon atau kadar diskaun dengan semua rakan kongsi kami
                                                </li>
                                                <li>Kod diskaun 15% untuk semua pembelian dari kedai dalam talian RCM
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>Peringkat Platinum</td>
                                        <td className='terms-privacy-table'>RM500</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>Akses 365 hari ke pangkalan data rakan kongsi RCM untuk kupon atau kadar diskaun dengan semua rakan kongsi kami
                                                </li>
                                                <li>Kod diskaun 15% untuk semua pembelian dari kedai dalam talian RCM
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>Anda boleh membeli beberapa keahlian sekali-gus, yang akan mengumpulkan dan menggabungkan hari-hari akses anda ke pangkalan data rakan kongsi RCM.</li>
                                <li>Anda boleh membeli keahlian kesetiaan dan sekali-gus pada masa yang sama, namun hari akses dari keahlian sekali-gus tidak akan digunakan sehingga selepas keahlian kesetiaan anda dibatalkan atau ditamatkan.</li>
                            </ul>
                        </div>

                        <h2 className="privacy-policy-p-heading">Penyertaan dalam tarikan promosi</h2>

                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>Jika anda membeli keahlian sekali-gus melalui pautan yang diiklankan sebagai sebahagian daripada promosi RCM (pautan rasmi) semasa tempoh promosi, anda akan menerima jumlah penyertaan yang ditetapkan yang berkaitan dengan pakej tersebut sebagai sebahagian daripada promosi tertentu itu.</li>
                                <li>Untuk mengelakkan keraguan, anda tidak akan menerima penyertaan dalam lebih dari satu promosi jika anda membeli pakej sekali-gus menggunakan pautan rasmi. Jika anda ingin menerima akses ke semua promosi hanya untuk ahli RCM, maka anda harus memegang keahlian kesetiaan.</li>
                                <li>Penyertaan dalam tarikan promosi adalah tertakluk kepada syarat dan terma yang berkaitan dengan promosi yang berkenaan, yang perlu dibaca bersama-sama dengan terma dan syarat keahlian ini.</li>
                            </ul>
                        </div>

                        <h2 className="privacy-policy-p-heading">KEAHLIAN KESETIAAN</h2>

                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>Keahlian kesetiaan adalah langganan berterusan ke platform RCM, yang dipotong secara langsung sehingga dibatalkan oleh anda atau ditamatkan oleh kami mengikut terma dan syarat ini.</li>
                                <li>Terdapat enam jenis keahlian kesetiaan, tiga opsyen bulanan dan tiga opsyen tahunan, seperti berikut:</li>
                            </ul>
                        </div>



                        <div className="mb-6">
                            <table className="privacy-pocily-table w-full border-collapse">
                                <thead>
                                    <tr className="privacy-member-table-bg">
                                        <th className='terms-privacy-table'>Tahap</th>
                                        <th className='terms-privacy-table'>Kos(sebulan)</th>
                                        <th className='terms-privacy-table'>Faedah</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='terms-privacy-table'>Tahap Masuk</td>
                                        <td className='terms-privacy-table'>RM20</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>Akses ke pangkalan data rakan kongsi RCM untuk kupon dan kadar diskaun dengan 40% daripada rakan kongsi kami
                                                </li>
                                                <li>Diskaun tahap masuk di rakan tertentu, seperti yang ditunjukkan di portal RCM
                                                </li>
                                                <li>1 penyertaan yang terkumpul setiap bulan ke dalam semua tarikan promosi perdagangan kami semasa berada di tahap ini

                                                </li>
                                                <li>15% diskaun atas produk dan barangan rasmi RCM dari kedai atas talian RCM

                                                </li>
                                                <li>Jemputan ke acara dan permainan RCM di seluruh Malaysia</li>
                                                <li>Email promosi awal RCM</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    {/* Repeat similar rows for other tiers */}
                                    <tr>
                                        <td className='terms-privacy-table'>Premium</td>
                                        <td className='terms-privacy-table'>RM49.99</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>Akses ke pangkalan data rakan kongsi RCM untuk kupon atau kadar diskaun dengan 70% daripada rakan kongsi kami

                                                </li>
                                                <li>Diskaun eksklusif tahap premium di rakan tertentu, seperti yang ditunjukkan di portal RCM

                                                </li>
                                                <li>4 penyertaan yang terkumpul setiap bulan ke dalam semua tarikan promosi perdagangan kami semasa berada di tahap ini

                                                </li>
                                                <li>20% diskaun atas produk dan barangan rasmi RCM dari kedai atas talian RCM
                                                </li>
                                                <li>Jemputan VIP ke acara dan permainan RCM di seluruh Malaysia</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    {/* Repeat similar rows for other tiers */}
                                    <tr>
                                        <td className='terms-privacy-table'>Elite</td>
                                        <td className='terms-privacy-table'>RM99.99</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>Akses ke pangkalan data rakan kongsi RCM untuk kupon atau kadar diskaun dengan semua rakan kongsi kami

                                                </li>
                                                <li>Diskaun eksklusif tahap elit di rakan tertentu, seperti yang ditunjukkan di portal RCM

                                                </li>
                                                <li>10 penyertaan yang terkumpul setiap bulan ke dalam semua tarikan promosi perdagangan kami semasa berada di tahap ini

                                                </li>
                                                <li>25% diskaun atas produk dan barangan rasmi RCM dari kedai atas talian RCM

                                                </li>
                                                <li>Jemputan VIP ke acara dan permainan RCM di seluruh Malaysia
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>Diskaun kesetiaan yang disediakan pada mana-mana peringkat keahlian kesetiaan tertakluk kepada syarat dan terma yang berkaitan dengan diskaun tersebut yang disediakan oleh rakan kongsi yang berkaitan. RCM tidak bertanggungjawab jika tawaran diskaun oleh rakan kongsi berubah pada bila-bila masa, atau untuk sebarang pengecualian yang mungkin terpakai.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>Penyertaan dalam
                                tarikan promosi</h2>

                            <ul className="list-text-digit list-decimal">
                                <li>Ahli kesetiaan mengumpulkan penyertaan untuk setiap bulan berterusan yang mereka miliki keahlian. Penyertaan dijana berdasarkan tahap keahlian kesetiaan semasa anda. Ini bermakna jika anda telah memiliki keahlian kesetiaan selama enam bulan dan telah menukar status keahlian anda dari satu peringkat keahlian ke yang lain semasa itu, jumlah keseluruhan penyertaan yang anda terima dalam satu tarikan akan dikira mengikut tahap keahlian yang anda pegang pada masa penyertaan dijana untuk satu promosi tertentu dan peringkat yang anda miliki sebelum ini. Penyertaan tahunan keahlian dikumpulkan setiap tahun bil. Penyertaan disediakan di hadapan dan sah untuk tahun tersebut, apabila tahun seterusnya bermula dan bil itu dibayar anda akan menerima jumlah penyertaan itu lagi.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <p className='privacy-policy-p-text ml-4'>
                                Contoh: Jika anda memiliki keahlian premium selama empat bulan, anda akan menerima 16 penyertaan ke dalam tarikan promosi. Walau bagaimanapun, jika anda kemudian memutuskan untuk menurunkan taraf keahlian anda ke keahlian peringkat masuk untuk bulan kelima anda dan kami menghasilkan penyertaan untuk promosi semasa anda berada di peringkat masuk, anda hanya akan menerima lima penyertaan. Jika anda kemudian meningkatkan semula ke keahlian premium untuk bulan keenam anda, dan kami menghasilkan penyertaan untuk promosi anda akan menerima 21 penyertaan untuk promosi tersebut. Jika anda kemudian memutuskan untuk meningkatkan ke keahlian elit pada bulan berikutnya, ini tidak meningkatkan bulan-bulan sebelumnya pada tahap yang lebih rendah, mereka akan dijana dengan jumlah penyertaan yang disebutkan dengan penyertaan elit yang ditambah kepada jumlah tersebut, selagi anda kekal pada peringkat tersebut. Sila ambil perhatian bahawa jika anda berada pada keahlian elit dan mempunyai bulan-bulan sebelumnya pada peringkat masuk atau premium, bulan-bulan itu akan dijana berdasarkan jumlah penyertaan yang dinyatakan. Jika anda berada pada keahlian premium dan memiliki bulan elit dan bulan premium yang dibayar, mereka akan dijana pada 4 penyertaan setiap bulan. Jika anda berada pada peringkat masuk, semua penyertaan akan dijana pada 1 penyertaan setiap bulan bil.
                            </p>
                        </div>

                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>Penyertaan keahlian kesetiaan dalam tarikan promosi dijana sebelum tempoh promosi yang berkaitan bermula. Sila semak portal ahli RCM atau aplikasi mudah alih RCM untuk melihat penyertaan anda. Jika penyertaan anda tidak dipaparkan di dalam portal anda atau di aplikasi, sila semak terlebih dahulu untuk memastikan aplikasi anda dikemaskini (jika berkaitan), kemudian hubungi kami.</li>
                                <li>Penyertaan dalam tarikan promosi sebaliknya tertakluk kepada syarat dan terma yang berkaitan dengan promosi yang berkenaan, yang perlu dibaca bersama dengan syarat dan terma keahlian ini.</li>
                                <li>Penyertaan bonus tambahan ke dalam tarikan promosi disediakan kepada semua ahli kesetiaan setiap kali mencapai mercu keahlian setiap enam bulan, tanpa mengira peringkat keahlian yang mereka pegang. Penyertaan bonus dikira seperti berikut:</li>
                            </ul>
                        </div>



                        <div className="mb-6">
                            <table className="w-full border-collapse">
                                <thead>
                                    <tr className="privacy-member-table-bg">
                                        <th className='terms-privacy-table'>Tempoh keahlian kesetiaan yang dipegang</th>
                                        <th className='terms-privacy-table'>Penyertaan bonus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='terms-privacy-table'>6 bulan</td>
                                        <td className='terms-privacy-table'>5 penyertaan bonus ke dalam 1 promosi pilihan mereka</td>
                                    </tr>
                                    {/* Ulang baris serupa untuk tier lain */}
                                    <tr>
                                        <td className='terms-privacy-table'>12 bulan</td>
                                        <td className='terms-privacy-table'>10 penyertaan bonus ke dalam 1 promosi pilihan mereka</td>
                                    </tr>
                                    {/* Ulang baris serupa untuk tier lain */}
                                    <tr>
                                        <td className='terms-privacy-table'>18 bulan</td>
                                        <td className='terms-privacy-table'>10 penyertaan bonus ke dalam 1 promosi pilihan mereka</td>
                                    </tr>

                                    <tr>
                                        <td className='terms-privacy-table'>24 bulan</td>
                                        <td className='terms-privacy-table'>10 penyertaan bonus ke dalam 1 promosi pilihan mereka</td>
                                    </tr>
                                    {/* Ulang baris serupa untuk tier lain */}
                                    <tr>
                                        <td className='terms-privacy-table'>30 bulan</td>
                                        <td className='terms-privacy-table'>10 penyertaan bonus ke dalam 1 promosi pilihan mereka</td>
                                    </tr>
                                    {/* Ulang baris serupa untuk tier lain */}
                                    <tr>
                                        <td className='terms-privacy-table'>36 bulan</td>
                                        <td className='terms-privacy-table'>10 penyertaan bonus ke dalam 1 promosi pilihan mereka</td>
                                    </tr>

                                    <tr>
                                        <td className='terms-privacy-table'>42 bulan</td>
                                        <td className='terms-privacy-table'>10 penyertaan bonus ke dalam 1 promosi pilihan mereka</td>
                                    </tr>
                                    {/* Ulang baris serupa untuk tier lain */}
                                    <tr>
                                        <td className='terms-privacy-table'>48 bulan</td>
                                        <td className='terms-privacy-table'>10 penyertaan bonus ke dalam 1 promosi pilihan mereka</td>
                                    </tr>
                                    {/* Ulang baris serupa untuk tier lain */}
                                    <tr>
                                        <td className='terms-privacy-table'>50 bulan</td>
                                        <td className='terms-privacy-table'>10 penyertaan bonus ke dalam 1 promosi pilihan mereka</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div className="mb-6">
                            <p className='privacy-policy-p-text'>
                                Untuk menerima penyertaan bonus seperti yang dinyatakan di atas, ahli kesetiaan perlu menghantar emel kepada kami dengan bukti keahlian mereka dan butiran promosi yang mereka ingin gunakan penyertaannya.
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>Naik taraf atau penurunan taraf Keahlian Kesetiaan</h2>

                            <ul className="list-text-digit list-decimal">
                                <li>Anda boleh meminta untuk menaik taraf atau menurunkan taraf keahlian kesetiaan anda pada bila-bila masa melalui portal RCM anda, namun ia akan berkuat kuasa pada kitaran bilan anda yang seterusnya. Tiada bayaran balik akan ditawarkan jika anda memilih untuk menurunkan taraf keahlian anda pertengahan kitaran bilan.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>Pengakhiran Keahlian</h2>

                            <ul className="list-text-digit list-decimal">
                                <li>Anda boleh membatalkan keahlian anda pada bila-bila masa dengan menghantar emel kepada kami. Kami kemudian akan menyediakan anda dengan pautan di mana anda akan diminta dengan langkah-langkah untuk membatalkan keahlian anda.</li>
                                <li>Setelah proses pembatalan selesai, keahlian anda akan dibatalkan dengan serta-merta dan anda akan menerima emel pengesahan. Tiada bayaran balik akan ditawarkan jika anda membatalkan keahlian anda pertengahan kitaran bilan atas sebab-sebab apa pun atau jika anda tidak menyelesaikan proses pembatalan sebelum bayaran dilakukan. Ini terpakai kepada kedua-dua keahlian bulanan dan tahunan.</li>
                                <li>Pembatalan keahlian anda tidak akan mengeluarkan anda dari pangkalan data pemasaran kami. Untuk mengeluarkan diri anda dari pangkalan data pemasaran kami, anda harus mengklik pautan berhenti langganan dalam emel promosi atau mesej SMS (jika berkaitan) dan ikuti arahan.</li>
                                <li>Jika anda memegang keahlian kesetiaan dan, atas sebab apa pun, yuran terus langsung untuk pembayaran keahlian kesetiaan anda gagal, kami akan cuba untuk mendebit pembayaran anda pada empat percubaan seterusnya setiap lima hari selama tempoh 15 hari selepas kegagalan pembayaran awal. Jika, selepas lima percubaan, kami tidak dapat mendebit pembayaran keahlian kesetiaan anda, keahlian kesetiaan anda dan semua faedah keahlian akan ditamatkan.</li>
                                <li>Anda boleh mendaftar semula ke keahlian kesetiaan pada bila-bila masa. Jika anda mendaftar semula dalam tempoh 30 hari selepas membatalkan keahlian kesetiaan anda atau keahlian kesetiaan anda dibatalkan oleh kami, anda akan mengekalkan semua faedah sebelumnya yang anda miliki di bawah keahlian kesetiaan anda. Walau bagaimanapun, jika anda tidak mendaftar semula ke keahlian kesetiaan dalam tempoh 30 hari selepas pembatalan atau pengakhiran, faedah sebelumnya yang anda miliki sebagai ahli kesetiaan tidak akan dikekalkan.</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>Bonus Pendaftaran Keahlian</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>Dari semasa ke semasa, kami mungkin menawarkan bonus pendaftaran, tawaran istimewa, atau tempoh percubaan percuma. Bonus, tawaran istimewa, dan tempoh percubaan percuma ini hanya sah mengikut syarat dan terma yang terpakai kepada bonus, tawaran istimewa, atau tempoh percubaan percuma tersebut.</li>
                                <li>Jika anda mendaftar untuk tempoh percubaan percuma dan tidak membatalkan sebelum akhir tempoh percubaan percuma, kaedah pembayaran yang anda tetapkan akan dikenakan pemotongan secara automatik mengikut terma keahlian kesetiaan yang dinyatakan di atas sehingga anda membatalkan keahlian anda mengikut syarat dan terma ini.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>Perubahan Butiran</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>Anda perlu memberitahu kami tentang sebarang perubahan nama, alamat, nombor hubungan, alamat e-mel, atau maklumat bil anda dengan mengemas kini butiran anda dalam talian di com atau dengan menghantar emel kepada kami.</li>
                                <li>Kami tidak akan bertanggungjawab atas kegagalan anda untuk memberitahu kami tentang perubahan butiran anda, yang mungkin menyebabkan anda tidak memperoleh manfaat penuh keahlian RCM anda.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>Tanggungjawab</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>Kami tidak mengecualikan sebarang hak dan remedi dalam hal barang atau perkhidmatan di bawah Akta Persaingan dan Pengguna (2010) yang tidak boleh dikecualikan, dihadkan, atau diubahsuai. Walau bagaimanapun, bahagian yang selebihnya dari klausa ini akan terpakai sepenuhnya mengikut undang-undang dan kami serta rakan kongsi kami tidak akan bertanggungjawab (termasuk dalam kecuaian) atas sebarang kerugian (termasuk tetapi tidak terhad kepada kerugian tidak langsung, khas, atau bersampingan atau kehilangan keuntungan), perbelanjaan, kerosakan apa pun yang dialami atau untuk sebarang kecederaan peribadi, penyakit, atau kematian yang dialami atau ditanggung berkaitan dengan keahlian RCM, kecuali untuk sebarang tanggungjawab yang tidak boleh dikecualikan oleh undang-undang.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>TERMA DAN SYARAT AM</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>Untuk tujuan terma dan syarat ini, kupon atau kadar diskaun bermaksud kadar diskaun yang telah kami atur dengan setiap rakan kongsi dan pengedar kami. Senarai penuh rakan kongsi kami boleh didapati di kupon / kadar diskaun.</li>
                                <li>Kami tidak bertanggungjawab atas sebarang implikasi cukai yang timbul dari penggunaan platform RCM atau menerima sebarang faedah daripadanya.</li>
                                <li>Kami boleh mengecualikan atau menangguhkan anda dari platform RCM atau daripada menerima faedah daripada platform RCM jika anda:</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <ul className="list-text">
                                <li>terlibat dalam tindakan yang boleh mengancam kelancaran yang betul platform RCM;</li>
                                <li>bertindak dengan cara yang mengganggu, menjengkelkan, mengancam, menyakitkan hati, atau mengganggu;</li>
                                <li>melakukan apa-apa yang mungkin mengurangkan nama baik atau reputasi kami, rakan kongsi kami atau mana-mana entiti berkaitan atau kontraktor kami;</li>
                                <li>mengingkari mana-mana undang-undang berkaitan dengan platform RCM atau penyertaan anda dalam promosi kami; atau</li>
                                <li>berkelakuan dengan cara yang kami percayai secara munasabah adalah tidak sesuai</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>Mana-mana terma dalam terma dan syarat ini yang dinyatakan untuk terus hidup, atau secara semulajadi terus hidup, selepas penamatan atau tamat tempoh terma dan syarat ini akan terus hidup dan berkuatkuasa selepas penamatan atau tamat tempoh tersebut.</li>
                                <li>Semua rujukan kepada RM dalam terma dan syarat ini adalah dalam Ringgit Malaysia.</li>
                                <li>Kami boleh, dari semasa ke semasa, mengubahsuai terma dan syarat ini. Terma dan syarat yang dipinda akan berkuatkuasa dari masa mereka dipaparkan di laman web RCM atau diberitahu kepada ahli-ahli. Jika anda tidak ingin menerima sebarang pindaan kepada terma dan syarat ini, anda boleh membatalkan keahlian RCM anda.</li>
                                <li>Kami boleh menghentikan penawaran keahlian dengan memberitahu ahli-ahli di laman web RCM atau sebaliknya, dengan syarat kami memberikan notis yang mencukupi supaya penamatan tidak menyebabkan kerugian kewangan atau lain-lain yang tidak wajar kepada ahli-ahli.</li>
                                <li>Terma dan syarat ini tertakluk kepada undang-undang Malaysia.
                                    Dikemaskini terakhir: 11 April 2024.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MembershipTermsAndConditions