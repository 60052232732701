
const PrivacyPolicy = () => {

    return (
        <>
            <div className="privacy-policy-page privacy-policy-bg-main md:mt-16 sm:mt-16 lg:mt-20">
                <div className="flex bg-cover bg-center privacy-policy-page privacy-policy-bg-inner">
                    <div className="container mx-auto privacy-policy-bg-inner-style" >

                        <div className="mb-6">
                            <div className=" flex justify-center">
                                <h2 className="section-header mb-10">Dasar Privasi</h2>
                            </div>
                            <p className='privacy-policy-p-text'>
                                REWARD CLUB.MY having registered office in Kuala Lumpur, Malaysia, is committed to protecting your privacy. We have prepared this Privacy Policy to describe to you our practices regarding the Personal Information we collect from users of our website.
                            </p>

                            <p className='privacy-policy-p-text'>
                                Dengan menggunakan Perkhidmatan, pengguna bersetuju untuk pengumpulan dan penggunaan Maklumat Peribadi mereka oleh kami. Anda juga mewakili kepada kami bahawa anda mempunyai semua kebenaran yang diperlukan untuk menggunakan Perkhidmatan ini termasuk menggunakannya untuk memproses Maklumat Peribadi. Kami mengumpul dan menggunakan maklumat yang anda berikan kepada kami, termasuk maklumat yang diperoleh daripada penggunaan anda terhadap Perkhidmatan. Selain itu, kami mungkin menggunakan maklumat yang kami kumpulkan untuk tujuan dalaman kami untuk membangun, menala, meningkatkan, dan menyempurnakan Perkhidmatan kami, dan untuk pengiklanan dan pemasaran yang konsisten dengan Dasar Privasi ini.
                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='privacy-policy-p-text'>
                                Sila baca dengan teliti dasar privasi kami untuk memahami dengan jelas bagaimana laman web kami mengumpulkan, menggunakan, melindungi, atau mengendalikan Maklumat Peribadi pengguna.
                            </p>

                            <p className='privacy-policy-p-text'>
                                Dasar Privasi ini bertujuan untuk memberitahu pengguna tentang bagaimana laman web kami memproses Maklumat Peribadi pengguna. Sekiranya pengguna tidak bersetuju dengan sebahagian daripada Dasar Privasi ini, maka kami tidak dapat menyediakan Perkhidmatannya kepada pengguna dan pengguna perlu menghentikan akses ke perkhidmatan kami.
                            </p>

                            <p className='privacy-policy-p-text'>
                                Dengan menggunakan Perkhidmatan, Anda mengakui, memberikan persetujuan, dan bersetuju bahawa kami boleh mengumpul, memproses, dan menggunakan maklumat yang anda berikan kepada kami dan bahawa maklumat tersebut hanya akan digunakan oleh kami atau pihak ketiga yang bertindak di bawah arahan kami, selaras dengan perjanjian kerahsiaan, untuk membangun, menala, meningkatkan, dan menyempurnakan Perkhidmatan.

                            </p>

                            <p className='privacy-policy-p-text'>
                                Walaupun kami cuba memberi notis kepada anda apabila terdapat perubahan dalam Dasar Privasi ini, anda bertanggungjawab untuk secara berkala menyemak sebarang perubahan yang mungkin dibuat kepada Dasar ini. Kami boleh, atas budi bicara kami, mengubah atau menyemak semula Dasar pada bila-bila masa, dan anda bersetuju untuk terikat dengan pengubahsuaian atau penyemakan semula tersebut.

                            </p>


                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>DEFINISI</h2>
                            <ul className='list-text'>
                                <li>“Kutip” bermaksud mengumpulkan, mendapatkan, atau memperoleh dengan cara yang sah dan adil, maklumat dalam keadaan di mana individu boleh dikenal pasti atau sudah dikenal pasti.</li>

                                <li>“Akibat Persetujuan Terus” termasuk fakta bahawa kami tidak akan bertanggungjawab di bawah Akta Privasi dan anda tidak akan dapat mencari pembelaan di bawah Akta Privasi sekiranya anda memberikan persetujuan kepada pendedahan Maklumat Peribadi anda oleh kami kepada penerima luar negeri dan penerima luar negeri tersebut memproses Maklumat Peribadi anda dengan melanggar Akta Privasi.</li>

                                <li>“Diberitahu Secara Terus” bermaksud keadaan di mana kami telah memberikan anda kenyataan yang jelas (sama ada secara lisan atau bertulis) mengenai Akibat Persetujuan Terus.</li>

                                <li>“Opt Out” bermaksud permintaan yang dinyatakan oleh individu untuk tidak menerima lagi komunikasi Pemasaran Langsung.</li>

                                <li>“Maklumat Peribadi” bermaksud maklumat yang bukan Maklumat Sensitif, termasuk maklumat atau pendapat ( sama ada maklumat atau pendapat yang menjadi sebahagian daripada pangkalan data), sama ada benar atau tidak dan sama ada direkodkan dalam bentuk material atau tidak, tentang seorang individu yang identitinya jelas, atau boleh ditentukan dengan munasabah daripada maklumat atau pendapat tersebut. Ini termasuk, tetapi tidak terhad kepada, nama pertama individu, nama terakhir, alamat e-mel, nombor telefon, kata laluan, dan alamat.</li>

                                <li>“Tujuan Utama” adalah tujuan utama bagi Pengumpulan sebarang Maklumat Peribadi.</li>

                                <li>“Jangkaan Munasabah” bermaksud jangkaan munasabah individu bahawa maklumat peribadi mereka mungkin Digunakan atau Didedahkan untuk tujuan yang berkaitan.</li>

                                <li>“Tujuan Sekunder” bermaksud tujuan Penggunaan atau Pendedahan selain daripada Tujuan Utama.</li>

                                <li>“Maklumat Sensitif” diberikan maknanya dalam seksyen 6(1) Akta Privasi 1988 (Cth).</li>

                                <li>“Gunakan” bermaksud pemprosesan Maklumat Peribadi oleh Syarikat kami.</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>MAKLUMAT YANG KAMI KUTIP</h2>
                            <ul className='list-text'>
                                <li>Apabila anda mendaftar dengan kami, anda memberikan kami maklumat tentang diri anda - nama anda, alamat e-mel, nombor telefon, dan lain-lain. Sekiranya anda berhubung dengan kami melalui e-mel, kami mungkin menyimpan kandungan mesej e-mel anda, alamat e-mel anda, dan respons kami. Kami juga mengumpulkan maklumat umum tentang penggunaan anda terhadap perkhidmatan kami.</li>

                                <li>Dengan memasukkan alamat e-mel dan nombor telefon anda di laman web kami, anda mungkin menerima bahan pemasaran daripada kami. Ini termasuk, tetapi tidak terhad kepada, tawaran promosi, buletin, dan maklumat tentang produk atau perkhidmatan baru. Kami mungkin menggunakan maklumat yang anda berikan kepada kami untuk menghubungi anda melalui e-mel atau telefon, walaupun anda tidak mengemukakan sebarang borang. Sekiranya anda tidak ingin menerima bahan pemasaran daripada kami, sila beritahu kami dengan menghantar e-mel kepada kami atau dengan membatalkan langganan dari e-mel kami.</li>

                                <li>Semasa berinteraksi dengan pengguna lain di Forum atau Kawasan Interaktif, anda berkongsi maklumat hubungan anda, mesej yang anda kongsi tidak bersifat peribadi dan boleh dilihat oleh mana-mana ahli atau pengguna laman web.</li>

                                <li>Iklan dan butiran yang berkaitan yang anda poskan di laman web dan maklumat yang anda kongsi di dalamnya dikutip oleh kami.</li>

                                <li>MAKLUMAT YANG KAMI KUTIP SECARA AUTOMATIK APABILA ANDA MENGGUNAKAN PERKHIDMATAN KAMI</li>

                                <li>Apa bila anda mengakses atau menggunakan Perkhidmatan kami, kami secara automatik mengumpul maklumat tentang anda, termasuk:</li>

                                <li>Maklumat Log: Kami mencatat maklumat tentang penggunaan laman web kami, termasuk jenis dan bahasa pelayar anda, masa capaian, halaman yang dilihat, alamat IP anda, dan laman web yang anda lawati sebelum mengakses laman web kami.</li>

                                <li>Maklumat Peranti: Kami mungkin mengumpul maklumat tentang peranti yang anda gunakan untuk mengakses Perkhidmatan kami, termasuk model peranti keras, sistem operasi dan versi, pengenal unik peranti, nombor telefon, International Mobile Equipment Identity (“IMEI”) dan maklumat rangkaian mudah alih.</li>

                                <li>Maklumat Lokasi: Dengan persetujuan anda, kami mungkin mengumpul maklumat tentang lokasi peranti anda untuk memudahkan penggunaan ciri-ciri tertentu Perkhidmatan kami, menentukan kelajuan pergerakan peranti anda, menambah penapis berdasarkan lokasi (seperti cuaca tempatan), dan untuk tujuan lain.</li>

                                <li>Maklumat yang Dikumpulkan oleh Cookies dan Teknologi Pengesan Lain: Kami menggunakan pelbagai teknologi untuk mengumpul maklumat, dan ini mungkin termasuk menghantar kuki kepada anda. Sebuah "kuki" adalah fail data kecil yang dipindahkan ke cakera keras komputer anda yang membolehkan laman web untuk bertindak balas kepada anda sebagai individu, mengumpul dan mengingati maklumat tentang keutamaan anda untuk menyesuaikan operasinya kepada keperluan, suka dan tidak suka anda. Secara keseluruhannya, kuki adalah selamat, kerana mereka hanya mengenal pasti komputer anda untuk menyesuaikan pengalaman Web anda. Menerima kuki tidak memberi kami akses kepada komputer anda atau sebarang Maklumat Peribadi tentang anda, selain daripada maklumat yang anda pilih untuk berkongsi. Pelayan lain tidak boleh membacanya, dan mereka juga tidak dapat digunakan untuk menyampaikan virus. Kebanyakan pelayar secara automatik menerima kuki, tetapi anda biasanya boleh menyesuaikan pengaturan anda (Microsoft Internet Explorer, Firefox atau Google Chrome) untuk memberi anda notis permintaan penempatan kuki, menolak kuki tertentu, atau menolak kuki sepenuhnya. Sekiranya anda mematikan kuki sepenuhnya, mungkin terdapat beberapa ciri laman web yang tidak akan tersedia untuk anda, dan beberapa halaman Web mungkin tidak dipaparkan dengan betul. Untuk menyokong ciri-ciri peribadi laman web kami (seperti kod negara dan bahasa anda dan fungsi pelayaran), kami mesti menghantar kuki ke cakera keras komputer anda dan/atau menggunakan pengesahan berasaskan kuki untuk mengenal pasti anda sebagai pengguna laman web yang berdaftar.</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>MAKLUMAT YANG DIBERKONGSI SEMASA MENGEMUKA DI
                                PELBAGAI PLATFORM LAMAN WEB
                            </h2>
                            <ul className='list-text'>
                                <li>Semasa berinteraksi dengan pengguna lain di Forum/Kawasan interaktif, anda berkongsi maklumat hubungan anda; mesej yang anda kongsi tidak bersifat peribadi dan boleh dilihat oleh mana-mana ahli atau pengguna laman web.</li>

                                <li>Iklan dan bahan berkaitan yang anda poskan di laman web dan maklumat yang anda kongsi di dalamnya dikutip oleh kami.</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>MAKLUMAT YANG DIBERKONGSI SEMASA TRANSAKSI
                            </h2>
                            <ul className='list-text'>
                                <li>Anda bersetuju bahawa anda akan menjalankan transaksi dengan pihak ketiga melalui laman web kami dan akan berkongsi Maklumat Peribadi anda dengan mereka untuk penyelesaian transaksi yang mudah. Dengan ini, anda dengan jelas bersetuju bahawa kami tidak akan terlibat atau bertanggungjawab atas sebarang pelanggaran privasi atau keselamatan data tersebut. Pelanggaran tersebut, jika ada, adalah perkara perselisihan antara anda dan pihak ketiga dan kami tidak akan bertanggungjawab atau diberikan notis untuk perkara yang sama.</li>

                                <li>Kami SANGAT menasihatkan agar anda berhati-hati dan berwaspada semasa mendedahkan Maklumat Peribadi anda kepada rakan transaksi anda. Sila jangan mendedahkan butiran bank dan akaun anda kepada sesiapa melalui laman web kami atau kepada mana-mana individu yang anda jumpai melalui laman web kami.</li>

                                <li>Kami tidak mengambil butiran kad kredit/kad debit atau butiran bank lain di laman web kami. Untuk pembayaran, anda akan diarahkan untuk log masuk melalui gerbang pembayaran yang berkaitan yang terintegrasi di laman web kami dan transaksi akan diselesaikan di sana. Perlu diingatkan bahawa kami tidak akan menyimpan sebarang maklumat berkaitan Bank dalam rekod kami dan tidak ada staf kami yang akan memegang atau terdedah kepada maklumat ini.</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>BAGAIMANA KAMI MENGGUNAKAN MAKLUMAT ANDA
                            </h2>
                            <ul className='list-text'>
                                <li>Kami menggunakan maklumat peribadi yang kami kumpulkan untuk memenuhi permintaan anda untuk perkhidmatan, meningkatkan perkhidmatan kami, dan menghubungi anda.</li>

                                <li>Dengan menaip dalam mana-mana borang dan memberikan kepada kami alamat e-mel anda dan/atau nombor telefon bimbit anda, anda memberi persetujuan kepada kami untuk menggunakan alamat e-mel dan nombor telefon bimbit untuk menghantar kepada anda notis berkaitan dengan laman web dan perkhidmatan kami, termasuk apa-apa notis yang diperlukan oleh undang-undang, sebagai gantinya kepada komunikasi melalui mel surat. Anda juga bersetuju bahawa kami boleh menghantar pemberitahuan tentang aktiviti di laman web kami kepada alamat e-mel atau peranti mudah alih yang anda berikan kepada kami, mengikut tetapan privasi yang berkenaan. Kami mungkin menggunakan alamat e-mel anda untuk menghantar kepada anda mesej lain, seperti buletin, perubahan kepada ciri-ciri kami, atau maklumat lain. Jika anda tidak mahu menerima mesej e-mel pilihan, anda boleh mengubah tetapan anda untuk memilih keluar.</li>

                                <li>Tetapan kami juga mungkin membolehkan anda untuk menyesuaikan keutamaan komunikasi anda. Jika anda tidak ingin menerima mesej e-mel promosi dari kami, anda boleh memilih keluar dengan mengikuti arahan berhenti langganan dalam e-mel tersebut. Jika anda memilih keluar, anda masih akan menerima e-mel bukan promosi daripada kami tentang akaun anda dan Perkhidmatan kami.</li>

                                <li>Selepas penamatan atau penonaktifan perkhidmatan anda, kami mungkin (tetapi tidak terikat untuk) menyimpan maklumat anda untuk tujuan arkib. Kami tidak akan mendedahkan secara awam mana-mana Maklumat Peribadi anda selain seperti yang dinyatakan dalam Dasar Privasi ini.</li>

                                <li>Menurut budi bicara kami, atas sebab-sebab apa pun atau tanpa sebab langsung, kami berhak untuk mengeluarkan mana-mana kandungan atau mesej, jika kami percaya bahawa tindakan tersebut diperlukan (a) untuk mematuhi undang-undang, mematuhi proses undang-undang yang disampaikan kepada kami, atau menyiasat, mencegah, atau mengambil tindakan mengenai aktiviti haram yang disyaki atau sebenar; (b) untuk menguatkuasakan dasar ini, mengambil langkah berjaga-jaga terhadap liabiliti, menyiasat dan mempertahankan diri kami terhadap sebarang tuntutan atau dakwaan pihak ketiga, membantu agensi penguatkuasaan kerajaan, atau melindungi keselamatan atau integriti laman web kami; atau (c) untuk menegakkan atau melindungi hak, harta, atau keselamatan peribadi laman web, pengguna kami, atau orang lain.</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>BAGAIMANA KAMI MEMBAGI MAKLUMAT ANDA</h2>
                            <ul className='list-text'>
                                <li>Sebagai suatu dasar, kami tidak akan menjual atau menyewakan maklumat tentang anda dan kami tidak akan mendedahkan maklumat tentang anda dengan cara yang tidak konsisten dengan Dasar Privasi ini kecuali seperti yang diperlukan oleh undang-undang atau peraturan kerajaan. Kami bekerjasama dengan siasatan penguatkuasaan undang-undang, serta pihak ketiga lain, untuk menegakkan undang-undang seperti yang berkaitan dengan hak milik intelektual, penipuan, dan hak peribadi lain.

                                </li>
                                <li>Kami tidak akan berkongsi maklumat peribadi yang kami kumpulkan tentang anda dengan mana-mana pihak ketiga untuk tujuan pemasaran mereka sendiri tanpa persetujuan anda. Kami mempunyai kontrak dengan pihak ketiga yang menghalang mereka daripada berkongsi Maklumat Peribadi anda.

                                </li>
                                <li>Jika anda tidak bersetuju dengan Dasar Privasi atau Terma Perkhidmatan kami, sila berhenti menggunakan Perkhidmatan kami; penggunaan berterusan anda terhadap Perkhidmatan akan menandakan persetujuan dan penerimaan anda terhadap Dasar Privasi dan Terma Penggunaan kami.

                                </li>
                                <li className="font-bold">KAMI BOLEH (DAN ANDA MEMBERI KEBENARAN KEPADA KAMI UNTUK) MENDEDahkan SEBARANG MAKLUMAT TENTANG ANDA KEPADA PENGUATKUASA UNDANG-UNDANG, PEGAWAI KERAJAAN LAIN, SEBARANG SAMAN ATAU SEBARANG PIHAK KETIGA LAIN YANG KAMI, MENURUT BUDI BICARA KAMI, PERCAYA PERLU ATAU SESUAI DALAM HUBUNGKAIT DENGAN PENYIASATAN PENIPUAN, PELANGGARAN HAK CIPTA, ATAU AKTIVITI LAIN YANG MENYALAHKAN ATAU BOLEH MEMBEBANKAN KAMI, ATAU ANDA, KE ATAS LIABILITI.

                                </li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>MEMASTIKAN MAKLUMAT TELAH
                                TEPAT DAN TERKINI</h2>
                            <ul className='list-text'>
                                <li>Kami mengambil langkah berjaga-jaga yang munasabah untuk memastikan bahawa Maklumat Peribadi yang kami Kumpul, Guna, dan Dedahkan adalah lengkap, relevan, dan terkini. Walau bagaimanapun, ketepatan maklumat tersebut bergantung kepada sejauh mana maklumat yang anda berikan. Oleh itu, kami mencadangkan agar anda:

                                </li>
                                <li>Beritahu kami jika terdapat sebarang kesilapan dalam Maklumat Peribadi anda; dan</li>
                                <li>Menyampaikan kepada kami maklumat terkini mengenai perubahan dalam Maklumat Peribadi anda seperti nama atau alamat anda.

                                </li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>BAGAIMANA KAMI MEMPROTEKSI
                                MAKLUMAT ANDA</h2>
                            <ul className='list-text'>
                                <li>Kami sangat prihatin tentang menjaga kerahsiaan data peribadi anda. Kami menggunakan langkah-langkah pentadbiran, fizikal, dan elektronik yang direka untuk melindungi maklumat anda daripada capaian yang tidak dibenarkan.

                                </li>
                                <li>Dengan menggunakan laman web ini atau Perkhidmatan atau memberikan Maklumat Peribadi kepada kami, anda bersetuju bahawa kami boleh berkomunikasi dengan anda secara elektronik mengenai isu keselamatan, privasi, dan pentadbiran yang berkaitan dengan penggunaan laman web atau Perkhidmatan ini.

                                </li>
                                <li>Kami menggunakan langkah-langkah perlindungan fizikal, pengurusan, dan teknikal yang munasabah secara komersial untuk mengekalkan integriti dan keselamatan Maklumat Peribadi anda. Walau bagaimanapun, kami tidak dapat menjamin keselamatan mana-mana maklumat yang anda hantar kepada kami dan anda melakukannya atas risiko anda sendiri. Setelah kami menerima penghantaran maklumat anda, kami membuat usaha yang munasabah secara komersial untuk memastikan keselamatan sistem kami. Walau bagaimanapun, sila ambil perhatian bahawa ini bukan jaminan bahawa maklumat sedemikian tidak mungkin diakses, didedahkan, diubah, atau dimusnahkan melalui pelanggaran mana-mana perlindungan fizikal, teknikal, atau pengurusan kami. Jika kami mengetahui ada pelanggaran sistem keselamatan, kami mungkin cuba memberitahu anda secara elektronik agar anda dapat mengambil langkah perlindungan yang sesuai.

                                </li>
                                <li>Walau apapun yang bertentangan dengan Polisi ini, kami mungkin menyimpan atau mendedahkan maklumat anda jika kami percaya bahawa ia adalah munasabah untuk mematuhi undang-undang, peraturan atau permintaan undang-undang; untuk melindungi keselamatan mana-mana individu; untuk menangani penipuan, isu keselamatan atau teknikal; atau untuk melindungi hak atau harta kami. Walau bagaimanapun, apa-apa dalam Polisi ini tidak bertujuan untuk mengehadkan sebarang pertahanan atau bantahan undang-undang yang mungkin anda miliki terhadap permintaan dari pihak ketiga, termasuk kerajaan, untuk mendedahkan maklumat anda.

                                </li>
                                <li>Kami dengan tegas menasihati anda untuk tidak berkongsi sebarang maklumat peribadi mengenai butiran akaun bank anda atau maklumat sulit lain dengan mana-mana pengguna lain di laman web ini dan jika anda berbuat demikian, anda akan bertanggungjawab secara peribadi atasnya. Kami tidak akan bertanggungjawab atau dikenakan liabiliti dalam kes tersebut.

                                </li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>PILIHAN ANDA TENTANG
                                MAKLUMAT ANDA</h2>
                            <ul className='list-text'>
                                <li>Anda mempunyai beberapa pilihan mengenai penggunaan maklumat di Perkhidmatan kami:
                                </li>
                                <li>Komunikasi E-mel dan Nombor Telefon: Kami akan menghantar anda surat berita dan e-mel percuma secara berkala yang secara langsung mempromosikan penggunaan laman web kami atau Perkhidmatan. Apabila anda menerima surat berita atau komunikasi promosi dari kami, anda boleh menunjukkan pilihan untuk berhenti menerima komunikasi lanjut dari kami dan anda akan diberi peluang untuk "opt-out" dengan mengikuti arahan berhenti langganan yang diberikan dalam e-mel yang anda terima atau dengan menghubungi kami secara langsung. Walaupun dengan keutamaan e-mel yang anda tunjukkan, kami mungkin menghantar kepada anda komunikasi berkaitan dengan perkhidmatan, termasuk notis mengenai sebarang kemas kini Terma Penggunaan atau Dasar Privasi kami.

                                </li>
                                <li>Mengubah atau Memadam Data Peribadi Anda: Anda boleh mengubah mana-mana maklumat peribadi anda dengan melawat laman web dan mengikuti arahan di dalamnya atau dengan menghantar e-mel kepada kami. Anda boleh meminta pemadaman data peribadi anda oleh kami, dan kami akan menggunakan usaha yang munasabah untuk mematuhi permintaan anda, tetapi sila ambil perhatian bahawa kami mungkin diperlukan untuk menyimpan maklumat tersebut dan tidak memadamkannya (atau untuk menyimpan maklumat ini untuk tempoh tertentu, di mana kami akan mematuhi permintaan pemadaman anda hanya selepas kami telah memenuhi keperluan tersebut). Apabila kami memadam mana-mana maklumat, ia akan dipadam dari pangkalan data aktif, tetapi mungkin kekal dalam arkib kami. Kami juga mungkin mengekalkan maklumat anda untuk pencegahan penipuan atau tujuan serupa.

                                </li>
                                <li>Tentu saja, anda boleh menolak untuk menghantar data peribadi melalui laman web, di mana dalam kes itu, kami mungkin tidak dapat menyediakan perkhidmatan tertentu kepada anda. Anda boleh menyemak dan betulkan maklumat mengenai anda yang kami simpan dengan mengedit tetapan akaun anda atau dengan menghubungi kami secara langsung.

                                </li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>PRIVASI KANAK-KANAK</h2>
                            <p className='privacy-policy-p-text'>
                                Melindungi privasi kanak-kanak yang masih muda adalah sangat penting. Oleh itu, kami tidak sengaja mengumpul atau meminta maklumat peribadi dari sesiapa di bawah umur 18 tahun atau sengaja membenarkan individu-individu tersebut mendaftar. Jika anda berusia di bawah 18 tahun, sila jangan cuba mendaftar untuk Perkhidmatan atau menghantar sebarang maklumat tentang diri anda kepada kami, termasuk nama, alamat, nombor telefon, atau alamat e-mel. Tiada seorang pun di bawah umur 18 tahun dibenarkan menyediakan sebarang data peribadi untuk mengakses Perkhidmatan. Sekiranya kami mengetahui bahawa kami telah mengumpul data peribadi dari kanak-kanak di bawah umur 18 tahun, kami akan memadamkan maklumat tersebut secepat mungkin. Sekiranya anda percaya bahawa kami mungkin mempunyai maklumat dari atau tentang seorang kanak-kanak di bawah umur 18 tahun, sila hubungi kami.

                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>PENGGABUNGAN DAN
                                PEMBELIAN</h2>
                            <p className='privacy-policy-p-text'>
                                Sekiranya berlaku penggabungan atau pembelian, kami berhak untuk memindahkan semua maklumat, termasuk data peribadi, yang disimpan bersama kami kepada entiti baru atau syarikat yang terbentuk. Sebarang perubahan dalam dasar dan status laman web akan dimaklumkan kepada anda melalui e-mel.

                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>PENGGALIAN KE LAMAN WEB PIHAK
                                KETIGA</h2>
                            <p className='privacy-policy-p-text'>
                                Laman web kami mengandungi pautan ke laman web lain. Fakta bahawa kami memautkan kepada laman web bukanlah satu pengesahan, kebenaran atau perwakilan tentang kaitan kami dengan pihak ketiga tersebut. Kami tidak mengawal laman web pihak ketiga. Laman web lain ini mungkin meletakkan kuki atau fail lain di komputer anda, mengumpul data atau meminta maklumat peribadi daripada anda. Laman web lain mengikut peraturan yang berbeza mengenai penggunaan atau pendedahan maklumat peribadi yang anda hantar kepada mereka. Kami menggalakkan anda untuk membaca dasar privasi atau kenyataan laman web lain yang anda lawati.

                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>PROSEDUR NOTIFIKASI</h2>
                            <p className='privacy-policy-p-text'>
                                Adalah dasar kami untuk menyediakan notifikasi, sama ada notifikasi tersebut diperlukan oleh undang-undang atau untuk tujuan pemasaran atau tujuan perniagaan lain, kepada anda melalui notis e-mel, notis bertulis atau cetakan keras, atau melalui pengeposan notis sedemikian di laman web, sebagaimana ditentukan oleh kami mengikut budi bicara tunggal kami. Kami berhak untuk menentukan bentuk dan cara memberikan notifikasi kepada anda, dengan syarat bahawa anda boleh memilih untuk tidak menerima certain means of notification sebagaimana yang diterangkan dalam Dasar Privasi ini.

                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>JANGAN LACAK</h2>
                            <ul className='list-text'>
                                <li>Do Not Track (“DNT”) adalah pilihan privasi yang pengguna boleh tetapkan dalam beberapa pelayar web tertentu. DNT adalah cara bagi pengguna untuk memberitahu laman web dan perkhidmatan bahawa mereka tidak mahu maklumat tertentu tentang lawatan laman web mereka dikumpul dari semasa ke semasa dan merentasi laman web atau perkhidmatan dalam talian.

                                </li>
                                <li>Kami komited untuk memberikan anda pilihan bermakna tentang maklumat yang dikumpul di laman web kami untuk tujuan pihak ketiga, dan itulah sebabnya kami menyediakan pautan opt-out. Walau bagaimanapun, kami tidak mengiktiraf atau memberi respons kepada isyarat DNT yang diinisiatifkan oleh pelayar, kerana industri Internet pada masa ini masih dalam proses menentukan dengan tepat apa itu DNT, apa yang bermaksud mematuhi DNT, dan pendekatan yang biasa untuk memberi respons kepada DNT.

                                </li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>PEMBATALAN PEMBAHAGIAN MAKLUMAT</h2>
                            <ul className='list-text'>
                                <li>Kami memahami dan menghormati bahawa tidak semua pengguna mungkin mahu membenarkan kami untuk berkongsi maklumat mereka. Sekiranya anda tidak mahu kami berkongsi maklumat anda, sila hubungi kami melalui [kontak kami] dan kami akan mengeluarkan maklumat anda secepat yang praktikal. Apabila menghubungi kami, sila nyatakan dengan jelas permintaan anda, termasuk nama anda, alamat surat-menyurat, alamat e-mel, dan nombor telefon anda.

                                </li>
                                <li>Walau bagaimanapun, di bawah keadaan berikut, kami masih mungkin diperlukan untuk berkongsi maklumat peribadi anda:

                                </li>
                                <li>jika kami merespons kepada perintah mahkamah atau proses undang-undang, atau jika kami perlu menubuhkan atau menggunakan hak-hak undang-undang kami atau mempertahankan diri terhadap tuntutan undang-undang.

                                </li>
                                <li>Jika kami percaya bahawa adalah perlu untuk berkongsi maklumat bagi menyiasat, mencegah atau mengambil tindakan berkenaan aktiviti haram, penipuan yang disyaki, situasi yang melibatkan ancaman yang berpotensi terhadap keselamatan fizikal mana-mana individu, pelanggaran Terma Penggunaan kami atau seperti yang diperlukan oleh undang-undang.

                                </li>
                                <li>Jika kami percaya bahawa adalah perlu untuk menyekat atau menghalang mana-mana pengguna daripada menggunakan mana-mana Bahagian daripada Laman Web kami, termasuk, tanpa had, melalui "hacking" atau merosakkan sebahagian daripadanya.

                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>E-MEL PALSU ATAU PENIPUAN</h2>
                            <p className='privacy-policy-p-text'>
                                Jika anda menerima e-mel yang tidak diminta yang kelihatan dari kami atau salah satu ahli kami yang meminta data peribadi (seperti kad kredit, log masuk, atau kata laluan), atau yang meminta anda untuk mengesahkan atau mengesahkan akaun atau maklumat peribadi lain dengan mengklik pautan, e-mel tersebut kemungkinan telah dihantar oleh seseorang yang cuba untuk mendapatkan maklumat anda secara tidak sah, kadang-kadang dirujuk sebagai "phisher" atau "spoofer." Kami tidak meminta jenis maklumat ini dalam e-mel. Jangan berikan maklumat atau klik pada pautan. Sila hubungi kami menggunakan maklumat hubungan yang disediakan di laman web jika anda menerima e-mel seperti ini.
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>PERUBAHAN KEPADA POLISI PRIVASI KAMI</h2>
                            <p className='privacy-policy-p-text'>
                                Kami mungkin akan mengemaskini Polisi Privasi ini dan prosedur keselamatan maklumat dari semasa ke semasa. Sekiranya prosedur privasi dan/atau keselamatan maklumat ini berubah secara ketara pada masa akan datang, kami akan memaparkan perubahan baru secara jelas di laman web untuk memaklumkan anda dan memberi anda kemampuan untuk menolak mengikut peruntukan yang telah ditetapkan di atas. Penggunaan berterusan laman web dan Perkhidmatan kami, mengikut notis perubahan sedemikian akan menunjukkan pengakuan anda terhadap perubahan tersebut dan persetujuan untuk terikat dengan terma dan syarat perubahan tersebut.
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>PELANGGARAN POLISI PRIVASI</h2>
                            <p className='privacy-policy-p-text'>
                                Kami berhak untuk menamatkan atau menangguhkan mana-mana akaun atau memadamkan kandungan tertentu dari mana-mana profil atau domain awam dalam lingkungan laman web ini jika akaun atau kandungan tersebut didapati melanggar polisi privasi kami. Kami dengan ikhlas meminta anda untuk menghormati kebimbangan privasi dan kerahsiaan orang lain. Bidang kuasa mana-mana pelanggaran atau pertikaian akan ditentukan mengikut terma penggunaan laman web ini.
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>TANPA SEBARANG RESERVASI</h2>
                            <p className='privacy-policy-p-text'>
                                Kami tidak menerima sebarang tempahan atau sebarang jenis penerimaan terhad terhadap polisi privasi kami. Anda dengan nyata bersetuju dengan setiap terma dan syarat sebagaimana yang ditetapkan dalam polisi ini tanpa sebarang pengecualian sama sekali.
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>TIADA PERLUMBANGAN</h2>
                            <p className='privacy-policy-p-text'>
                                Polisi ini merupakan sebahagian daripada terma pengguna. Kami telah mengambil langkah berjaga-jaga yang paling besar untuk mengelakkan sebarang ketidakkonsistenan atau perbalahan polisi ini dengan sebarang terma, perjanjian atau garis panduan lain yang terdapat di laman web kami. Sekiranya terdapat perbalahan, kami meminta anda untuk menghubungi kami untuk penentuan dan penafsiran akhir.
                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>HUBUNGI KAMI</h2>
                            <p className='privacy-policy-p-text'>
                                Sekiranya anda mempunyai sebarang pertanyaan mengenai Polisi Privasi ini, amalan kami yang berkaitan dengan laman web, atau urusan anda dengan kami, sila hubungi kami di.
                            </p>
                        </div>


                        <div className="">
                            <p className='privacy-policy-p-text'>
                                © 2024 REWARD CLUB.MY. Semua Hak Cipta Terpelihara
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy