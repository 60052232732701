const MembershipTermsAndConditions = () => {

    return (
        <>
            <div className="privacy-policy-page bg-white" >
                <div className="flex bg-cover bg-center privacy-policy-bg-inner">
                    <div className="container mx-auto flex privacy-policy-bg-inner-style">

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading text-center membership-rcm-header'>RCM Malaysia Membership</h2>
                            <h2 className='privacy-policy-p-heading'>Terms &
                                Conditions</h2>
                            <ul className="list-text">
                                <li>Reward Club Malaysia (RCM, we, us or our) operates the RCM platform, providing one-off and loyalty memberships to which these terms and conditions apply.

                                </li>
                                <li>You must be 18 years of age or over to sign up to an RCM membership.</li>
                                <li>Membership of RCM is subject to the <a href="#" className="underline text-blue-500">terms
                                    and conditions</a> that apply to the use of the RCM website.
                                </li>
                                <li>These terms and conditions are a binding agreement between us and each member of the
                                    RCM platform. By signing up to an RCM membership, you agree to these
                                    terms and conditions and our <a href="#" className="underline text-blue-500">Privacy
                                        Policy.</a> Please read these terms and conditions and Privacy Policy carefully
                                    before you purchase an RCM membership. For more information regarding our
                                    use of your personal information and opt-out procedures, refer to our <a href="#"
                                        className="underline text-blue-500">Privacy
                                        Policy.</a></li>
                            </ul>
                            <h2 className="privacy-policy-p-heading mt-6">ONE-OFF MEMBERSHIPS</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>One-off memberships are the perfect way to access our services and the RCM platform for a shorter period to suit your needs.</li>
                                <li>There are five types of one-off memberships, as follows:</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <table className="privacy-pocily-table w-full border-collapse">
                                <thead>
                                    <tr className="privacy-member-table-bg">
                                        <th className='terms-privacy-table'>Tier</th>
                                        <th className='terms-privacy-table'>Cost</th>
                                        <th className='terms-privacy-table'>Benefits</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='terms-privacy-table'>Entry-level</td>
                                        <td className='terms-privacy-table'>RM20</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>2 days’ access to the RCM partner database for coupons or discount rates with all of our partners

                                                </li>
                                                <li>5% off discount code for all purchases from the RCM online store
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    {/* Repeat similar rows for other tiers */}
                                    <tr>
                                        <td className='terms-privacy-table'>Bronze</td>
                                        <td className='terms-privacy-table'>RM30</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>7 days’ access to the RCM partner database for coupons or discount rates with all of our partners
                                                </li>
                                                <li>10% off discount code for all purchases from the RCM online store
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    {/* Repeat similar rows for other tiers */}
                                    <tr>
                                        <td className='terms-privacy-table'>Silver</td>
                                        <td className='terms-privacy-table'>RM100</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li> 28 days’ access to the RCM partner database for coupons or discount rates with all of our partners
                                                </li>
                                                <li> 10% off discount code for all purchases from the RCM online store
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>Gold</td>
                                        <td className='terms-privacy-table'>RM250</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>186 days’ access to the RCM partner database for coupons or discount rates with all of our partners

                                                </li>
                                                <li>15% off discount code for all purchases from the RCM online store
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>Platinum</td>
                                        <td className='terms-privacy-table'>RM500</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>365 days’ access to the RCM partner database for coupons or discount rates with all of our partners
                                                </li>
                                                <li>15% off discount code for all purchases from the RCM online store
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>You may purchase multiple one-off memberships, which will accumulate and combine your days of access to the RCM partners database.</li>
                                <li>You may purchase both loyalty and one-off memberships at the same time, however the access days from the one-off membership will not be used until after your loyalty membership is cancelled or terminated.</li>
                            </ul>
                        </div>

                        <h2 className="privacy-policy-p-heading">Entries into promotional draws</h2>

                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>If you purchase a one-off membership via a link that is advertised as part of an RCM promotion (official link) during a promotion period, you will receive the set amount of entries associated with that package as a part of that particular promotion.</li>
                                <li>For the avoidance of doubt, you will not receive entries into more than one promotion if you purchase a once off package using an official link. If you would like to receive access to all RCM member only promotions, then you must hold a loyalty membership.</li>
                                <li>Entries into promotional draws are otherwise subject to the terms and conditions associated with the relevant promotion, which should be read in conjunction with these membership terms and conditions.</li>
                            </ul>
                        </div>

                        <h2 className="privacy-policy-p-heading">LOYALTY MEMBERSHIPS</h2>

                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>Loyalty memberships are an on-going subscription to the RCM platform, which are direct debited until cancelled by you or terminated by us in accordance with these terms and conditions.</li>
                                <li>There are six types of loyalty memberships, three monthly options and three yearly options, as follows:</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <table className="privacy-pocily-table w-full border-collapse">
                                <thead>
                                    <tr className="privacy-member-table-bg">
                                        <th className='terms-privacy-table'>Tier</th>
                                        <th className='terms-privacy-table'>Cost(per month)</th>
                                        <th className='terms-privacy-table'>Benefits</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='terms-privacy-table'>Entry-level</td>
                                        <td className='terms-privacy-table'>RM20</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>Access to the RCM partner database for coupons and discount rates with 40% of our partners
                                                </li>
                                                <li>Entry level discounts at selected partners, as shown in the RCM portal
                                                </li>
                                                <li>1 accumulating entry per month of membership into all of our trade promotion draws while at this tier

                                                </li>
                                                <li>15% off official RCM merchandise and products from the RCM online store

                                                </li>
                                                <li>Invites to RCM events and games across Malaysia</li>
                                                <li>RCM early bird promotion emails</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    {/* Repeat similar rows for other tiers */}
                                    <tr>
                                        <td className='terms-privacy-table'>Premium</td>
                                        <td className='terms-privacy-table'>$49.99</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>Access to the RCM partner database for coupons or discount rates with 70% of our partners

                                                </li>
                                                <li>Exclusive premium tier discounts at selected partners, as shown in the RCM portal

                                                </li>
                                                <li>4 accumulating entries per month of membership into all of our trade promotion draws while at this tier

                                                </li>
                                                <li>20% off official RCM merchandise and products from the RCM online store
                                                </li>
                                                <li>VIP invites to RCM events and games across Malaysia</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    {/* Repeat similar rows for other tiers */}
                                    <tr>
                                        <td className='terms-privacy-table'>Elite</td>
                                        <td className='terms-privacy-table'>$99.99</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>Access to the RCM partner database for coupons or discount rates with all of our partners
                                                </li>
                                                <li>Exclusive elite tier discounts at selected partners, as shown in the RCM portal
                                                </li>
                                                <li>10 accumulating entries per month of membership into all of our trade promotion draws while at this tier
                                                </li>
                                                <li>25% off official RCM merchandise and products from the RCM online store
                                                </li>
                                                <li>Priority VIP invites to RCM events and games across Malaysia
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>Loyalty discounts provided at any level of loyalty membership are subject to the terms and conditions associated with that discount provided by the relevant partner. RCM is not responsible if a discount offering by a partner changes at any time, or for any exclusions that may apply.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>Entries into
                                promotional draws</h2>

                            <ul className="list-text-digit list-decimal">
                                <li>Loyalty members accumulate entry/ies for each continuous month that they hold a membership. Entries are generated based on your current loyalty membership level. This means if you have held a loyalty membership for six months and have changed your membership status from one membership level to another during that time, the total number of entries you receive in a draw will be calculated in accordance with the membership level you hold at the time of the entries being generated for a specific giveaway and the tiers you have had previously. Yearly membership entries accumulate per billed year. Entries are provided upfront and are valid for the year, once the next year commences and the bill is paid you will receive that amount of entries onto again.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <p className='privacy-policy-p-text ml-4'>
                                Example: If you hold a premium membership for four months you will receive 16 entries into a promotional draw. However, if you then decide to downgrade your membership to the entry-level membership for your fifth month and we generate entries for a promotion while you’re on the entry-level tier, you’ll only receive five entries. If you then upgrade back to the premium membership for your sixth month, and we generate entries for a promotion you will receive 21 entries for that promotion. If you then decide to upgrade to an elite membership the following month, this does not upgrade your previous months at a lower tier, they will be generated with the entry amount stated with the elite entries going on top of that total, as long as you stay at that tier. Please note that If you are on the elite membership and have previous months at entry or premium those months will be generated based on the stated entry amounts.  If you are on a premium membership and have elite months and premium months paid, they’ll be generated at 4 entries per month. If you’re at entry level all entries will be generated at 1 entry per billed month.

                            </p>
                        </div>

                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>Loyalty membership entries into promotional draws are generated before the relevant promotion period commences. Please check the RCM member portal or the RCM mobile app to view your entries. If your entries are not showing inside your portal or on the app, please first check to make sure your app is up to date (if relevant), then contact us.</li>
                                <li>Entries into promotional draws are otherwise subject to the terms and conditions associated with the relevant promotion, which should be read in conjunction with these membership terms and conditions.</li>
                                <li>Additional bonus entries into promotional draws are available to all loyalty members upon achieving six-monthly membership milestones, regardless of the level of membership they hold. Bonus entries are calculated as follows:</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <table className="w-full border-collapse">
                                <thead>
                                    <tr className="privacy-member-table-bg">
                                        <th className='terms-privacy-table'>Length of time loyalty
                                            membership held
                                        </th>
                                        <th className='terms-privacy-table'>Bonus entries</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='terms-privacy-table'>6 months</td>
                                        <td className='terms-privacy-table'>5 bonus entries into 1
                                            promotion of their choice
                                        </td>
                                    </tr>
                                    {/* Repeat similar rows for other tiers */}
                                    <tr>
                                        <td className='terms-privacy-table'>12 months</td>
                                        <td className='terms-privacy-table'>10 bonus entries into 1
                                            promotion of their choice
                                        </td>
                                    </tr>
                                    {/* Repeat similar rows for other tiers */}
                                    <tr>
                                        <td className='terms-privacy-table'>18 months</td>
                                        <td className='terms-privacy-table'>10 bonus entries into 1
                                            promotion of their choice
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='terms-privacy-table'>24 months</td>
                                        <td className='terms-privacy-table'>10 bonus entries into 1
                                            promotion of their choice
                                        </td>
                                    </tr>
                                    {/* Repeat similar rows for other tiers */}
                                    <tr>
                                        <td className='terms-privacy-table'>30 months</td>
                                        <td className='terms-privacy-table'>10 bonus entries into 1
                                            promotion of their choice
                                        </td>
                                    </tr>
                                    {/* Repeat similar rows for other tiers */}
                                    <tr>
                                        <td className='terms-privacy-table'>36 months</td>
                                        <td className='terms-privacy-table'>10 bonus entries into 1
                                            promotion of their choice
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='terms-privacy-table'>42 months</td>
                                        <td className='terms-privacy-table'>10 bonus entries into 1
                                            promotion of their choice
                                        </td>
                                    </tr>
                                    {/* Repeat similar rows for other tiers */}
                                    <tr>
                                        <td className='terms-privacy-table'>48 months</td>
                                        <td className='terms-privacy-table'>10 bonus entries into 1
                                            promotion of their choice
                                        </td>
                                    </tr>
                                    {/* Repeat similar rows for other tiers */}
                                    <tr>
                                        <td className='terms-privacy-table'>50 months</td>
                                        <td className='terms-privacy-table'>10 bonus entries into 1
                                            promotion of their choice
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="mb-6">
                            <p className='privacy-policy-p-text'>
                                To receive the bonus entries as set out above, a loyalty member must email us with proof of their membership and details of the promotion that they wish to apply the entries to.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>Upgrade or downgrade
                                of Loyalty Membership</h2>

                            <ul className="list-text-digit list-decimal">
                                <li>You can request to upgrade or downgrade your loyalty membership at any time through you RCM portal, however it will come into effect on your next billing cycle. No refund will be offered if you opt to downgrade your membership mid-billing cycle.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>TERMINATION OF
                                MEMBERSHIP</h2>

                            <ul className="list-text-digit list-decimal">
                                <li>You may cancel your membership at any time by emailing to us. We will then provide you with a link where you will prompted with steps to cancel your membership.</li>
                                <li>Once the cancellation process is complete, your membership will be cancelled instantly and you will be sent a confirmation email. No refund will be offered if you cancel your membership mid-billing cycle for any reason or if you do not complete the cancellation process before the payment comes out. This applies to both monthly and yearly memberships.</li>
                                <li>Cancellation of your membership will not remove you from our marketing database. To remove yourself from our marketing database you must click the unsubscribe link in the promotional email or SMS message (as relevant) and follow the prompts.</li>
                                <li>If you hold a loyalty membership and, for any reason, a direct debit for payment of your loyalty membership fails, we will attempt to debit your payment on four further occasions every five days over a 15 day period after the initial payment failure. If, after five attempts, we are unable to debit your loyalty membership payment, your loyalty membership and all membership benefits will be terminated.</li>
                                <li>You may re-subscribe to a loyalty membership at any time. If you re-subscribe within 30 days of cancelling your loyalty membership or your loyalty membership is cancelled by us, you will retain all previous benefits you held under your loyalty membership. However, if you do not re-subscribe to a loyalty membership within 30 days of cancellation or termination, any previous benefits you had as a loyalty member will not carry over.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>MEMBERSHIP SIGN-UP
                                BONUSES</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>From time to time we may offer sign-up bonuses, special offers or free trial periods. These bonuses, special offers and free trial periods are only valid in accordance with the terms and conditions which apply to that bonus, special offer of free trial period.</li>
                                <li>If you sign up to a free trial period and do not cancel before the end of the free trial period, your nominated payment method will be automatically deducted in accordance with the loyalty membership terms set out above until you cancel your membership in accordance with these terms and conditions.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>CHANGE OF
                                DETAILS</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>You should notify us of any change of name, address, contact number, email address or billing information by updating your details online at com, by emailing us.</li>
                                <li>We will not be responsible for any failure by you to notify us of your change of details, which may result in you not obtaining the full benefit of your RCM membership.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>LIABILITY</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>We do not exclude any rights and remedies in respect of goods or services under the Competition and Consumer Act (2010) which cannot be excluded, restricted or modified. However, the remainder of this clause will apply to the fullest extent permitted by law and we and our partners are not be liable (including in negligence) for any loss (including but not limited to indirect, special or consequential loss or loss of profits), expense, damage whatsoever which is suffered or for any personal injury, illness or death suffered or sustained in connection with RCM membership, except for any liability which cannot be excluded by law.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>GENERAL TERMS AND
                                CONDITIONS</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>For the purpose of these terms and conditions, coupon or discount rates means the discounted rates we have arranged with each of our partners and distributors. A full list of our partners is available at coupon / discount rates.</li>
                                <li>We are not responsible for any tax implications arising from you using the RCM platform or receiving any benefit from it.</li>
                                <li>We may exclude or suspend you from the RCM platform or from receiving benefits from the RCM platform if you:</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <ul className="list-text">
                                <li>engage in conduct that may jeopardise the proper conduct of the RCM platform;

                                </li>
                                <li>act in a disruptive, annoying, threatening, abusive or harassing manner;
                                </li>
                                <li>do anything that may diminish the good name or reputation of us, our partners or any of our related entities or contractors;

                                </li>
                                <li>breach any law in connection with the RCM platform or your participation in our promotions; or
                                </li>
                                <li>behave in a way that we reasonably believe is otherwise inappropriat⦁	You must not assign or attempt to assign any right or liability under these terms and conditions without our prior written consentbehave in a way that we reasonably believe is otherwise inappropriate</li>
                            </ul>
                        </div>

                        <div className="mb-6">

                            <ul className="list-text-digit list-decimal">
                                <li>Any term of these terms and conditions that is expressed to survive, or by its nature survives, the termination or expiry of these terms and conditions will survive and continue in effect following such termination or expiry.</li>
                                <li>All references to RM in these terms and conditions are in Ringgit Malaysia.</li>
                                <li>We may, from time to time, amend these terms and conditions. The amended terms and conditions will apply from the time they are posted on the RCM website or are otherwise notified to members. If you do not wish to accept any amendments to these terms and conditions, you may cancel your RCM membership.</li>
                                <li>We may cease offering memberships by notifying members on the RCM website or otherwise, provided that we provide sufficient notice so that the termination does not cause unreasonable financial or other detriment to members.</li>
                                <li>These terms and conditions are governed by the laws of Malayisa.
                                Last updated: 11 April 2024.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MembershipTermsAndConditions