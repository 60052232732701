import { useContext } from "react";
import CountdownTimer from "../../components/Timer";
import { AuthContext } from "../../context/AuthContext";
import { MemberContext } from "../../context/MemberContext";

const GiveawaysGrid = () => {
  const { giveaways } = useContext(MemberContext);

  return (
    <div className="overflow-x-auto mt-4">
      <div className="flex flex-col items-center md:items-start md:flex-row md:flex-wrap p-4">
        {giveaways.map((card, index) => (
          <div key={index} className="p-4">
            <Card
              value={card.value}
              imageUrl={card.image || "/assets/giveaway/placeholder.png"}
              card={card}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const Card = ({ value, imageUrl, card }) => {
  const { user } = useContext(AuthContext);
  const { joinGiveaways } = useContext(MemberContext);
  const {
    setOpenLoginModal,
  } = useContext(AuthContext);
  const handleJoinGiveaways = async (card) => {
    if(!user){
      setOpenLoginModal(true);
    }else if (user && card?.is_join_able) {
      const data = {
        ga_id: card.id,
      };
      await joinGiveaways(data);
    }
  };
  return (
    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:border-gray-700 p-4">
      <div className="p-5 justify-center text-center">
        <p className="text-black text-xl font-bold">${value} CASH</p>
      </div>
      <div className="justify-center text-center">
        <p className="text-4xl color-primary font-bold">VALUED AT: ${value}</p>
      </div>
      <img className="rounded-t-lg scale-[0.8]" src={imageUrl} alt="" />
      {card?.is_join_able && (
        <div className="flex justify-center">
          <button
            onClick={() => handleJoinGiveaways(card)}
            className="bg-primary hover:bg-primary-dark text-white py-5 px-12 rounded font-bold justify-center"
          >
            JOIN NOW
          </button>
        </div>
      )}
      <div className="p-5 justify-center">
        <CountdownTimer date={card?.giveaway_date} />
      </div>
    </div>
  );
};

export default GiveawaysGrid;
