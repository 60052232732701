import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AppRouter from "./routes/router";
import { AuthProvider } from "./context/AuthContext";
import { MemberProvider } from "./context/MemberContext";
import { MerchantProvider } from "./context/MerchantContext";
import SignIn from "./pages/auth/SignIn";

function App() {
  return (
    <div className="">
      <Router>
        <AuthProvider>
          <MemberProvider>
            <MerchantProvider>
              <Header />
              <AppRouter />
              <Footer />
              <SignIn />
            </MerchantProvider>
          </MemberProvider>
        </AuthProvider>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
