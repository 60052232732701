import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const SignUpForm = () => {
  const { selectedPlan, setRegisterInfo, RegisterMember } = useContext(AuthContext);

  const formSchema = [
    { label: "Full Name:", name: "name", type: "text" },
    { label: "Email Address:", name: "email", type: "email" },
    { label: "Password:", name: "password", type: "password" }, // Changed type to "password"
    { label: "Phone Number:", name: "phone", type: "text" },
    { label: "Birth Date:", name: "birthdate", type: "date" }, // Changed type to "date"
    { label: "Location:", name: "location", type: "text" },
  ];

  const initialValues = {
    name: "",
    email: "",
    password: "",
    phone: "",
    birthdate: "",
    location: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // Format birthdate to desired format
    const formattedValues = {
      ...values,
      birthdate: formatBirthdate(values.birthdate),
      plan_id: selectedPlan?.id,
    };
    setRegisterInfo(formattedValues);
    await RegisterMember(formattedValues);
    setSubmitting(false);
  };

  // Function to format birthdate to "DD/MM/YYYY" format
  const formatBirthdate = (date) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    return formattedDate.replace(/\//g, '/'); // Replace slashes with dashes
  };

  return (
    <>
      <div className="border border-gray-300 m-4">
        <p className="mt-4 text-orange-500 px-6">* Denotes mandatory fields</p>
        <div
          style={{
            flex: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "5px",
            padding: "10px",
          }}
        >
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form className="w-full max-w-lg">
                {formSchema?.map((field) => (
                  <div key={field.label} className="mb-2">
                    {field.type === "heading" ? (
                      <h2 className="member-form-heading px-4 py-2 mb-2">
                        {field.label}
                      </h2>
                    ) : (
                      <>
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 text-left"
                          htmlFor={field.name}
                        >
                          {field.label}
                        </label>
                        <Field
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type={field.type}
                          name={field.name}
                          id={field.name}
                        />
                        <ErrorMessage
                          name={field.name}
                          component="div"
                          className="color-primary text-xs italic"
                        />
                      </>
                    )}
                  </div>
                ))}
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="bg-primary hover:bg-primary-dark text-white py-2 px-12 rounded mt-4 mb-4 font-bold justify-center"
                    disabled={isSubmitting}
                  >
                    SIGN ME UP
                  </button>
                </div>

                <p className="mt-4">
                  100% GUARANTEED SECURE & SAFE CHECKOUT
                </p>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default SignUpForm;
