import { useContext, useEffect, useState } from "react";
import PrizeCard from "../../components/PrizeCard";
import { AuthContext } from "../../context/AuthContext";
import { MemberContext } from "../../context/MemberContext";
import NearestGiveaway from "./NearestGiveaway";

const MemberDrawWinners = () => {
  const { language } = useContext(AuthContext);
  const { pastGiveawaysWinners } = useContext(MemberContext);
  const translations = require(`../../lang/common/common_${language}.json`);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    pastGiveawaysWinners &&
      pastGiveawaysWinners.length > 0 &&
      setWinners(() => {
        const _winners = [];
        pastGiveawaysWinners.forEach((pw) => {
          pw.winners.forEach((w) => {
            const _data = {
              ...w,
              package_name_en: pw.name_en,
              package_name_ms: pw.name_ms,
              package_name_zh: pw.name_zh,
              draw_date: pw.giveaway_date,
              image: pw.image
            };
            _winners.push(_data);
          });
        });
        return _winners;
      });
  }, [pastGiveawaysWinners]);

  useEffect(() => {
    // Check if the screen size is mobile
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768); // Adjust the threshold as needed
    };

    // Call handleResize initially and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="min-h-screen flex flex-col relative">
        <div className="min-h-[100vh] bg-primary flex-grow p-4 md:p-36">
          <div className="flex justify-center">
            <h2 className="section-header-white p-4 mb-10">
              {translations.home.memberDrawWinners.pastWinners}
            </h2>
          </div>
          <NearestGiveaway />
        </div>
        <div className="min-h-screen bg-white-200 flex-grow">
          <div className="p-5">
            <div className="flex justify-center">
              <h2 className="section-header">
                {translations.home.memberDrawWinners.pastWinners}
              </h2>
            </div>

            <div className="flex justify-center mt-5">
              <div className="text-s font-bold">
                {translations.home.upcomingGiveaways.content.joinNow}
              </div>
            </div>
          </div>

          <div style={{ height: "auto" }}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-4 my-4 justify-center container mx-auto">
              {winners?.map((prize, index) => (
                <PrizeCard key={index} prize={prize} language={language} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberDrawWinners;
