const TermsAndConditions = () => {

    return (
        <>
            <div className="terms-conditions-page terms-condition-bg-main mt-20">
                <div className="flex bg-cover bg-center terms-condition-bg-inner">
                    <div className="container mx-auto terms-condition-bg-inner-style">
                        <div className="mb-6">
                            <div className=" flex justify-center">
                                <h2 className="section-header mb-10">TERMS & CONDITIONS</h2>
                            </div>
                            <p className='terms-conditions-p-text'>
                                These terms and conditions sets forth the terms and conditions under which Reward club provides you access to giveaways, and governs your use of our website, and the RCM Members platform made available through the Site (the Software) and the information, content, features and services provided through the Site (together with the Software, collectively, the Service).

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className="font-bold terms-conditions-p-heading">
                                YOUR USE OF THE SITE AND/OR THE SERVICE, OR THE PURCHASE OF ONE OF OUR MEMBERSHIPS, SIGNIFIES YOUR AGREEMENT TO THESE TERMS AND CONDITIONS AND CONSTITUTES YOUR BINDING ACCEPTANCE OF THIS THEM, INCLUDING ANY MODIFICATIONS THAT RCM MAKES FROM TIME TO TIME.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                If you do not agree to these terms and conditions, you may not use the Site or the Service.
                                RCM may amend these terms and conditions at any time by posting the amended Terms and conditions on the Site, and you agree that you will be bound by any changes to these terms and conditions. RCM may make changes to the Site and/or the Service at any time. You understand that RCM may discontinue or restrict your use of the Site and/or the Service for any reason or no reason with or without notice.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>1.Privacy Policy</h2>
                            <p className='terms-conditions-p-text'>
                                By clicking purchasing a membership and using our platform or entering one of our giveaways, you represent that you have read and consent to our Privacy Policy in addition to these terms and conditions. RCM may revise the Privacy Policy at any time, and the new versions will be posted on the Site. If at any point you do not agree to any portion of the Privacy Policy, you must immediately stop using the Site and/or the Service.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>1.The Services.</h2>
                            <p className='terms-conditions-p-text'>
                                The RCM Site is a membership rewards platform that offers its members discounts at leading brands across Malaysia. Members get access to a member’s dashboard where they can utilise the discounts on offer and manage all their payments depending on what package they have and update details as needed.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                RCM reserves the right to add, change, modify, suspend or discontinue any portion of the Service, in its sole discretion, at any time. Your use of the Site and/or the Service, including the availability of new services through the Site, shall be subject to these terms and conditions. In addition, RCM may impose limits on any portion of the Service or restrict your access to portions of or the entire Site in its sole discretion without notice or liability.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>2.Support</h2>
                            <p className='terms-conditions-p-text'>
                                RCM supports all registered and official Business Partners of the RCM Network.

                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>3.Refunds, returns,
                                and exchanges</h2>
                            <p className='terms-conditions-p-text'>
                                By purchasing a membership or a one-time package for our rewards platform, you are providing support to the RCM brand. It is important to note that memberships and one-time packages purchased during a promotional period or giveaway are non-refundable.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Our goods and services come with guarantees that cannot be excluded under Malaysian Consumer Law. For major failures, you are entitled to

                            </p>
                        </div>

                        <div className="mb-6">
                            <ul className="list-text">
                                <li>cancel your service contract with us; and</li>
                                <li>a refund for the unused portion or compensation for its reduced value.</li>
                                <li>You are also entitled to choose a refund or replacement for major failures with goods.
                                </li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                If a failure with the goods or service does not amount to a major failure, you are entitled to have the failure rectified in a reasonable time. If this is not done, you are entitled to a refund for the goods and to cancel the contract for the service. Refunds or rectifications cannot be given for reasons such as change of mind or failure to not understand properly.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                To use certain portions of the Site and Service, you will be directed to register on the Site and create a user profile or account (Account). Eligibility is limited to persons equal to or over the age of 18.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                As part of the registration process, you may be asked to submit your name, address, email address and/or similar information and to select a password. You agree that all information you provide to RCM for purposes of creating an account (Registration Information) will be true, accurate, current, and complete and your failure to provide such information shall constitute a breach of these terms and conditions and may result in the immediate termination of your Account. You shall (i) promptly update your Registration Information to keep it true, accurate, current, and complete; and (ii) maintain the confidentiality of your password.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                In creating an Account, you shall not (a) select or use the email address or username of another person with the intent to impersonate that person, (b) use a name subject to the rights of any other person without authorization, (c) use an email address or username that is profane, offensive, or otherwise inappropriate or (d) allow any other party to use your Account and/or password except as set forth herein.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                You may not share or transfer any Account. You may not disclose your password to anyone. You agree to immediately notify RCM by sending an email to us of any known or suspected unauthorized use(s) of your Account or any known or suspected breach of security, including loss, theft or unauthorized disclosure of your password. You understand and agree that you shall be liable for all activities that occur under any Account created for your use, even if such activities were not committed by you. RCM is not responsible for any loss or damage arising from your failure to maintain the confidentiality of your password.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                RCM employees, directors, managers and their respective immediate family members (i.e., parents, spouses, domestic partners, siblings and children) or any other person residing in the same household as an RCM, employee, director or manager may not win the offered giveaways.

                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>4. Termination of
                                Account.</h2>
                            <p className='terms-conditions-p-text'>
                                You understand and agree that you have no ownership rights in your Account and RCM may stop offering the Service at any time. Further, RCM may cancel your Account, delete all of your Registration Information and any other information you have provided through the Site (collectively, User Content) associated with your Account at any time, without notice, for any reason or no reason including, without limitation, your violation of these terms and conditions. RCM will not be liable for any damages or loss resulting from the removal of any User Content from the Site and/or the Service.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                To cancel your account, simply contact us and request cancellation. Once the request has been made, a specific cancellation link will be sent to you within 24 hours. You will need to click on this link and follow the prompts to complete the cancellation process. There are no refunds on loyalty memberships. If a payment has recently been made, please cancel your membership at the end of the billed period.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>5. One-Off Membership
                                Packages Loyalty Memberships</h2>
                            <p className='terms-conditions-p-text'>
                                Customers have a choice of selecting a Once off Package or become a monthly Loyalty Member to access the RCM Platform.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>6. Termination of
                                Loyalty Membership</h2>
                            <p className='terms-conditions-p-text'>
                                Upon cancelling your loyalty membership which must be done via email and then following the link provided upon request, you will be removed from all future payments and forfeit the bonus accumulated entries from all future promotional giveaways. There are no refunds on loyalty memberships. If a payment has recently been made, please cancel your membership at the end of the billed period.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                To be removed from our mailing or text lists, please use the associated links as they are sent via third parties. Terminating your loyalty membership alone will not remove you from these lists.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                If your account fails to pay, there will be 4 automatic attempts made to bill it. The system will attempt to bill your account every 5 days (20-day grace period) from the initial payment failure. On the 4th and final attempt, if there are insufficient funds, your subscription will be terminated, and your loyalty streak will be lost.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                If you cancel your subscription, Stripe does not allow for its reactivation, and all accumulated history for that subscription will be lost. To continue your subscription, please follow the instructions in the notification emails provided or log in to the member’s dashboard and update your banking details.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                If your account is cancelled, you can sync up the entry history by creating a new subscription within 30 days of the cancellation. Only the most recent active subscription will be synced up, and if there are multiple cancellations, the previous entry history will not be included in the accumulation of entries moving forward since the cancellation date is beyond 30 days from the current active subscription.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Please note that the system only allows for 4 attempts, which includes any manual attempts made by the user to chaRCMe the account.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>7. Use of Site and
                                Service.</h2>
                            <p className='terms-conditions-p-text'>
                                You agree that you will not, in connection with your use of the Site, or Service, violate any applicable law or regulation. Without limiting the foregoing, you agree not to (i) make available through the Site any material or information that infringes any copyright, trademark, patent, trade secret, or other right of any party (including rights of privacy or publicity); (ii) institute, assist, or become involved in any type of attack including, without limitation, denial of service attacks, upon the Site and/or the Service or otherwise attempt to disrupt the Site and/or the Service or any other person’s use of the Site and/or the Service; or (iii) attempt to gain unauthorized access to the Site, Service, Accounts registered to other users, or the computer systems or networks connected to the Site and/or the Service.
                                Furthermore, you may not use the Site to develop, generate, transmit or store information that: (a) is defamatory, harmful, abusive, obscene or hateful; (b) in any way obstructs or otherwise interferes with the normal performance of another person’s use of the Site and/or the Service, (c) performs any unsolicited commercial communication not permitted by applicable law; (d) constitutes harassment or a violation of privacy or threatens other people or groups of people; (e) is harmful to children in any manner; (f) violates any applicable law, regulation or ordinance; (g) makes any false, misleading or deceptive statement or representation regarding RCM and/or the Site or Service or (h) constitutes phishing, pharming or impersonates any other person, or steals or assumes any person’s identity (whether a real identity or online nickname or alias).

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                You agree that you will not (i) obtain or attempt to obtain any information from the Site including, without limitation, email information of other Account holders or other Software data; (ii) intercept, examine or otherwise observe any proprietary communications protocol used by the Site or Software, whether through the use of a network analyzer, packet sniffer or other device; (iii) use any type of bot, spider, virus, clock, timer, counter, worm, software lock, drop dead device, Trojan-horse routing, trap door, time bomb or any other codes, instructions or third-party software that is designed to provide a means of surreptitious or unauthorized access to, or distort, delete, damage or disassemble the Site or Software.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                All comments, feedback, suggestions, ideas, and other submissions that you disclose, submit or offer to us in connection with your use of the Site or Service, such as your suggestions regarding improvements that we make to the Site or Service (collectively, Comments) will become our exclusive property. Such disclosure, submission or offer of any Comments shall constitute an assignment to us of all worldwide right, title and interest in all patent, copyright, trademark, and all other intellectual property and other rights whatsoever in and to the Comments and a waiver of any claim based on moral rights, unfair competition, breach of implied contract, breach of confidentiality, and any other legal theory relating to submissions. Thus, we will own exclusively all such right, title and interest and shall not be limited in any way in the use, commercial or otherwise, of any Comments. You should not submit any Comments to us if you do not wish to assign such rights to us. We are and will be under no obligation: (i) to maintain any Comments in confidence; (ii) to pay to you or any third party any compensation for any Comments; or (iii) to respond to any Comments. You are and shall remain solely responsible for the content of any Comments you make. By posting Comments or any other content, you warrant and represent that you own the rights to such or are otherwise authorized to post, distribute, display, perform, transmit, or otherwise distribute such Comments and content and grant us the right to do the same. You hereby irrevocably waive any claims based on moral rights and similar theories, if any.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                Your User Content, and the contents of all of your Comments and other online communications (including without limitation chat text, voice communications, IP addresses and your personal information) may be accessed and monitored as necessary to provide the Service and may be disclosed: (i) when we have a good faith belief that we are required to disclose the information in response to legal process (for example, a court order, search warrant or subpoena); (ii) to satisfy any applicable laws or regulations (iii) where we believe that the Site or Service is being used in the commission of a crime, including to report such criminal activity or to exchange information with other companies and oRCManizations for the purposes of fraud protection and credit card theft reduction; (iv) when we have a good faith belief that there is an emeRCMency that poses a threat to the health and/or safety of you, another person or the public generally; and (v) in order to protect the rights or property of RCM, including to enforce these terms and conditions. By entering into these terms and conditions, you hereby provide your irrevocable consent to such monitoring, access and disclosure.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                When accessing the Site you agree to obey the law and to respect the intellectual property rights of others. Your use of the Site is at all times governed by and subject to laws regarding copyright ownership and use of intellectual property. You agree not to upload, download, display, perform, transmit, or otherwise distribute any information or content in violation of any third party’s copyrights, trademarks, or other intellectual property or proprietary rights.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                                You shall be solely responsible for any violations of any relevant laws and for any infringements of third-party rights caused by any content you provide or transmit, or that is provided or transmitted using your account. The burden of proving that any content does not violate any laws or third-party rights rests solely with you.
                                The Site and the Service are operated by RCM in Malaysia. Those who choose to access the Site, and/or the Service from locations outside of Malaysia can do so on their own initiative and are responsible for compliance with applicable local laws.

                            </p>
                        </div>


                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>8. Third-Party
                                Products, Services + Discounts from Our Partners.</h2>
                            <p className='terms-conditions-p-text'>
                            Third party services, content, information, and products may be made available by RCM on or through the Site. Third Party Products, services and discounts are provided solely for the benefit of our paid members and RCM makes no representations or warranties regarding any Third-Party Products, services and discounts and takes no responsibility and assumes no liability for any Third-Party Products, services or discounts. Third Party Products, services and discounts are subject to the applicable terms and policies of the third parties that offer them.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>9. Hyperlinks.</h2>
                            <p className='terms-conditions-p-text'>
                            Links from the Site. The Site may contain links to websites operated by other parties. RCM provides these links to other websites as a convenience and use of these sites is at your own risk. The linked sites are not under the control of RCM, and RCM is not responsible for the content available on the other sites. Such links do not imply RCM endorsement of information or material on any other site and RCM disclaims all liability with regard to your access to and use of such linked websites.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                            Links to the Site. You may not place a link to the Site on another website without our prior written consent and, without limiting the foregoing, you must adhere to RCM inking policy as follows: (i) the appearance, position and other aspects of the link may not be such as to damage or dilute the goodwill associated with RCM and/or its licensors’ names and trademarks, (ii) the appearance, position and other attributes of the link may not create the false appearance that your oRCManization or entity is sponsored by, affiliated with, or associated with RCM (iii) when selected by a user, the link must display the Site on full-screen and not within a frame on the linking site, and (iv) RCM reserves the right to revoke its consent to the link at any time and in its sole discretion.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>RCM Intellectual Property and Intellectual Property Infringement.
</h2>
                            <p className='terms-conditions-p-text'>
                            The interfaces, content, arrangement and layout of the Site including, but not limited to, the RCM trademark and logo, designs, text, artwork, graphics, images, buttons, user interfaces, information and other content, and any compilation of the foregoing (RCM Intellectual Property) are the property of RCM, except where otherwise noted, and are protected from copying, imitation, communication, or simulation under Malaysian. and international laws and may not be reproduced, modified, communicated, displayed, distributed, or transmitted without the prior written permission of RCM.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                            You understand and acknowledge that, by visiting the Site, you do not acquire or obtain by implication or otherwise, any license or right to use or make additional copies of any materials or information displayed on the Site. You agree not to display or use any RCM Intellectual Property or third party content located on the Site in any manner not expressly permitted under these terms and conditions.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>11. Representation
                                and Warranties of Members.</h2>
                            <p className='terms-conditions-p-text'>
                            The Participant represents and warrants that it shall not contact the any affiliate, employee, sponsor or partner of RCM in order to manipulate the Contest results.

                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='terms-conditions-p-heading'>12. Giveaway
                                Disclaimers.</h2>
                            <p className='terms-conditions-p-text'>
                            By becoming a member or Customer of RCM the Participant agrees to enter the Contest and Agrees that:

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                            Any and all disputes, claims and causes of action arising out of or in connection with the Contest and the promotional gifts shall be resolved individually with any form of class action;

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                            Any claims judgments and awards shall be limited to actual out of pocket costs incurred in entering the Contest, but under no circumstances will RCM be responsible for any legal fees;

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                            Participants hereby waive all rights to claim punitive, incidental or consequential damage and any rights to have damages multiplied or otherwise increased and any other damages, other than damages for actual out-of-pocket expenses incurred in entering the contest.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                            In no event will RCM, their licensees, parents, affiliates, subsidiaries and related companies, their advertising or promotional agencies or their respective officers, directors, employees, representatives and agents, be responsible or liable for any damages or losses of any kind, including direct, indirect, incidental, consequential or punitive damages arising from access to, or use of, this website, electronic or computer malfunctions, or entrant participation in this contest, even RCM was advised of the possibility of such damages.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                            RCM reserves the right to disqualify any Participant found to be tampering with or otherwise abusing any aspect of this Contest as solely determined by RCM.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                            In the event the Contest is compromised by non-authorised human intervention, tampering or other causes beyond the reasonable control of RCM, that corrupt or impair the administration, security, fairness or proper operation of the Contest, RCM reserves the right to suspend, modify or terminate the Contest.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                            Any attempt by a Participant to deliberately damage the website to undermine the legitimate operation of this contest is a violation of criminal and civil laws, and should such an attempt be made, RCM reserves the right to seek damages from any such Participant to the fullest extent permitted by Law.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                            assumes no responsibility for any problems or technical malfunction of computer systems, servers, software, internet service provider, or e-mail systems, failure of any entry to be received on account of technical problems or incomplete, late, lost, damaged, illegible or misdirected electronic communications, or any combination thereof.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                            The Contest is void where prohibited or restricted by law.

                            </p>
                        </div>

                        <div className="mb-6">
                            <p className='terms-conditions-p-text'>
                            the administration, security, fairness or proper operation of the Contest, RCM reserves the right to suspend, modify or terminate the Contest.

                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsAndConditions