const MembershipTermsAndConditions = () => {

    return (
        <>
            <div className="privacy-policy-page bg-white" >
                <div className="flex bg-cover bg-center privacy-policy-bg-inner">
                    <div className="container mx-auto flex privacy-policy-bg-inner-style">

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading text-center membership-rcm-header'>RCM Malaysia Membership</h2>
                            <h2 className='privacy-policy-p-heading'>條款與條件</h2>
                            <ul className="list-text">
                                <li>Reward Club Malaysia (RCM，我们，我们或我们的) 运营RCM平台，提供一次性和忠诚度会员资格，适用于这些条款和条件。

                                </li>
                                <li>您必须年满18岁或以上才能注册成为RCM会员。</li>
                                <li>RCM会员资格受RCM网站使用的<a href="#" className="underline text-blue-500">条款和条件</a>的约束。</li>
                                <li>这些条款和条件是我们与RCM平台的每个会员之间的约束性协议。通过注册成为RCM会员，您同意遵守这些条款和条件以及我们的<a href="#" className="underline text-blue-500">隐私政策。</a> 请在购买RCM会员资格之前仔细阅读这些条款和条件和隐私政策。有关我们使用您的个人信息和选择退出程序的更多信息，请参阅我们的<a href="#" className="underline text-blue-500">隐私政策。</a></li>
                            </ul>
                            <h2 className="privacy-policy-p-heading mt-6">一次性会员资格</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>一次性会员资格是访问我们的服务和RCM平台以适应您需求的较短时间的完美方式。</li>
                                <li>一次性会员资格有五种类型，如下：</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <table className="privacy-pocily-table w-full border-collapse">
                                <thead>
                                    <tr className="privacy-member-table-bg">
                                        <th className='terms-privacy-table'>等级</th>
                                        <th className='terms-privacy-table'>费用</th>
                                        <th className='terms-privacy-table'>好处</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='terms-privacy-table'>入门级</td>
                                        <td className='terms-privacy-table'>RM20</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>2天访问RCM合作伙伴数据库，以获取所有合作伙伴的优惠券或折扣率

                                                </li>
                                                <li>所有购物从RCM在线商店购买的95折折扣代码
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    {/* 为其他级别重复类似的行 */}
                                    <tr>
                                        <td className='terms-privacy-table'>青铜级</td>
                                        <td className='terms-privacy-table'>RM30</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>7天访问RCM合作伙伴数据库，以获取所有合作伙伴的优惠券或折扣率
                                                </li>
                                                <li>所有购物从RCM在线商店购买的9折折扣代码
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    {/* 为其他级别重复类似的行 */}
                                    <tr>
                                        <td className='terms-privacy-table'>白银级</td>
                                        <td className='terms-privacy-table'>RM100</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>28天访问RCM合作伙伴数据库，以获取所有合作伙伴的优惠券或折扣率
                                                </li>
                                                <li>所有购物从RCM在线商店购买的9折折扣代码
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>黄金级</td>
                                        <td className='terms-privacy-table'>RM250</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>186天访问RCM合作伙伴数据库，以获取所有合作伙伴的优惠券或折扣率

                                                </li>
                                                <li>所有购物从RCM在线商店购买的85折折扣代码
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>白金级</td>
                                        <td className='terms-privacy-table'>RM500</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>365天访问RCM合作伙伴数据库，以获取所有合作伙伴的优惠券或折扣率
                                                </li>
                                                <li>所有购物从RCM在线商店购买的85折折扣代码
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>您可以购买多个一次性会员资格，这将累积并结合您访问RCM合作伙伴数据库的天数。</li>
                                <li>您可以同时购买忠诚会员和一次性会员资格，但是一次性会员资格的访问天数将在您的忠诚会员资格被取消或终止后才会使用。</li>
                            </ul>
                        </div>

                        <h2 className="privacy-policy-p-heading">参加促销抽奖活动</h2>

                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>如果您在促销期间通过作为RCM促销一部分的链接（官方链接）购买一次性会员资格，则作为该特定促销的一部分，您将获得与该套餐相关联的设定数量的抽奖参与机会。</li>
                                <li>为了避免疑问，如果您使用官方链接购买一次性套餐，则不会获得多个促销的抽奖参与机会。如果您希望获得所有RCM会员专属促销的访问权限，则必须持有忠诚会员资格。</li>
                                <li>参加促销抽奖活动的条款和条件受相关促销的条款和条件的约束，应与这些会员条款和条件一起阅读。</li>
                            </ul>
                        </div>

                        <h2 className="privacy-policy-p-heading">忠诚会员资格</h2>

                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>忠诚会员资格是对RCM平台的持续订阅，直到您取消或我们根据这些条款和条件终止为止。</li>
                                <li>有六种类型的忠诚会员资格，分别是三种月度选项和三种年度选项，如下：</li>
                            </ul>
                        </div>



                        <div className="mb-6">
                            <table className="privacy-pocily-table w-full border-collapse">
                                <thead>
                                    <tr className="privacy-member-table-bg">
                                        <th className='terms-privacy-table'>等级</th>
                                        <th className='terms-privacy-table'>费用（每月）</th>
                                        <th className='terms-privacy-table'>优惠</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='terms-privacy-table'>入门级</td>
                                        <td className='terms-privacy-table'>RM20</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>访问RCM合作伙伴数据库，获取40%合作伙伴的优惠券和折扣率</li>
                                                <li>在RCM门户网站上显示的选定合作伙伴的入门级折扣</li>
                                                <li>每月一个累积的抽奖参与机会，参与我们所有贸易促销抽奖活动</li>
                                                <li>RCM官方商品和RCM在线商店商品85折</li>
                                                <li>邀请参加全马尼西亚的RCM活动和游戏</li>
                                                <li>RCM提前预订促销电子邮件</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>高级</td>
                                        <td className='terms-privacy-table'>$49.99</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>访问RCM合作伙伴数据库，获取70%合作伙伴的优惠券或折扣率</li>
                                                <li>在RCM门户网站上显示的高级别合作伙伴的专享折扣</li>
                                                <li>每月四个累积的抽奖参与机会，参与我们所有贸易促销抽奖活动</li>
                                                <li>RCM官方商品和RCM在线商店商品8折</li>
                                                <li>邀请参加全马尼西亚的RCM活动和游戏</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>精英</td>
                                        <td className='terms-privacy-table'>$99.99</td>
                                        <td className='terms-privacy-table'>
                                            <ul className='list-text'>
                                                <li>访问RCM合作伙伴数据库，获取所有合作伙伴的优惠券或折扣率</li>
                                                <li>在RCM门户网站上显示的精英级合作伙伴的专享折扣</li>
                                                <li>每月十个累积的抽奖参与机会，参与我们所有贸易促销抽奖活动</li>
                                                <li>RCM官方商品和RCM在线商店商品7.5折</li>
                                                <li>优先邀请参加全马尼西亚的RCM活动和游戏</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>忠诚度会员在任何忠诚度会员级别上提供的折扣均受到与该折扣相关的合作伙伴提供的条款和条件的约束。如果合作伙伴提供的折扣随时更改，或者适用任何排除规定，RCM将不承担责任。</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>参加促销抽奖活动</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>忠诚度会员每持有一个月的会员资格，就会累积一次抽奖机会。抽奖机会是根据您当前的忠诚度会员级别生成的。这意味着，如果您已经持有了六个月的忠诚度会员资格，并且在此期间将会员资格级别从一个级别更改为另一个级别，则您在抽奖活动中获得的总抽奖机会数量将根据您在为特定赠品生成条目时持有的会员资格级别和您先前拥有的级别进行计算。每年会员资格的抽奖机会每年累积一次。抽奖机会是提前提供的，有效期为一年，一旦新的一年开始并支付了账单，您将再次获得该数量的抽奖机会。</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <p className='privacy-policy-p-text ml-4'>
                                例如：如果您持有高级会员资格四个月，您将获得16次参加促销抽奖活动的机会。但是，如果您决定在第五个月将会员资格降级为入门级会员资格，并且在您处于入门级会员资格时我们生成了一次促销抽奖活动的机会，您只会获得五次机会。如果您在第六个月升级回高级会员资格，并且我们为促销抽奖活动生成了条目，您将获得该促销活动的21次机会。如果您随后决定在下个月升级为精英会员资格，这不会升级您之前低级别的月份，它们将按照规定的入门级别进行生成，而精英级别的条目将在此总数之上进行累加，只要您保持在该级别。请注意，如果您拥有入门或高级会员资格的精英会员资格的月份，那些月份将根据规定的条目数量进行生成。如果您拥有精英会员资格和高级会员资格的月份和高级会员资格的月份，它们将以每月4次的速度生成。如果您处于入门级别，所有条目将以每个计费月1次的速度生成。
                            </p>
                        </div>

                        <div className="mb-6">
                            <ul className="list-text-digit list-decimal">
                                <li>忠诚度会员进入促销抽奖活动的条目是在相关促销活动开始之前生成的。请检查RCM会员门户网站或RCM移动应用程序以查看您的条目。如果您的条目没有显示在您的门户网站或应用程序中，请首先检查您的应用程序是否是最新版本（如果适用），然后联系我们。</li>
                                <li>除非另有规定，否则参加促销抽奖活动的条目将受到与相关促销相关的条款和条件的约束，这些条款和条件应与这些会员条款和条件一并阅读。</li>
                                <li>所有忠诚度会员在实现每六个月的会员资格里程碑时都可以获得额外的奖励条目，无论他们持有的会员资格级别如何。额外的奖励条目计算如下：</li>
                            </ul>
                        </div>



                        <div className="mb-6">
                            <table className="w-full border-collapse">
                                <thead>
                                    <tr className="privacy-member-table-bg">
                                        <th className='terms-privacy-table'>持有忠诚度会员时间</th>
                                        <th className='terms-privacy-table'>奖励条目</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='terms-privacy-table'>6个月</td>
                                        <td className='terms-privacy-table'>5个奖励条目，可用于1个他们选择的促销活动</td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>12个月</td>
                                        <td className='terms-privacy-table'>10个奖励条目，可用于1个他们选择的促销活动</td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>18个月</td>
                                        <td className='terms-privacy-table'>10个奖励条目，可用于1个他们选择的促销活动</td>
                                    </tr>

                                    <tr>
                                        <td className='terms-privacy-table'>24个月</td>
                                        <td className='terms-privacy-table'>10个奖励条目，可用于1个他们选择的促销活动</td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>30个月</td>
                                        <td className='terms-privacy-table'>10个奖励条目，可用于1个他们选择的促销活动</td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>36个月</td>
                                        <td className='terms-privacy-table'>10个奖励条目，可用于1个他们选择的促销活动</td>
                                    </tr>

                                    <tr>
                                        <td className='terms-privacy-table'>42个月</td>
                                        <td className='terms-privacy-table'>10个奖励条目，可用于1个他们选择的促销活动</td>
                                    </tr>
                                    <tr>
                                        <td className='terms-privacy-table'>48个月</td>
                                        <td className='terms-privacy-table'>10个奖励条目，可用于1个他们选择的促销活动</td>
                                    </tr>
                                    {/* <!-- Repeat similar rows for other tiers --> */}
                                    <tr>
                                        <td className='terms-privacy-table'>50个月</td>
                                        <td className='terms-privacy-table'>10个奖励条目，可用于1个他们选择的促销活动</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div className="mb-6">
                            <p className='privacy-policy-p-text'>
                                要获得上述奖励条目，忠诚度会员必须通过电子邮件向我们发送会员资格证明和他们希望将条目应用到的促销活动的详细信息。
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>升级或降级忠诚度会员资格</h2>

                            <ul className="list-text-digit list-decimal">
                                <li>您可以随时通过您的 RCM 门户网站请求升级或降级您的忠诚度会员资格，但这将在下一个计费周期生效。如果您选择在计费周期中途降级会员资格，将不提供退款。</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>会员资格终止</h2>

                            <ul className="list-text-digit list-decimal">
                                <li>您可以随时通过电子邮件取消您的会员资格。然后，我们将向您提供一个链接，您将被提示完成取消会员资格的步骤。</li>
                                <li>取消过程完成后，您的会员资格将立即被取消，并将收到确认电子邮件。如果由于任何原因您在计费周期中途取消会员资格，或者您在支付完成之前未完成取消流程，则不提供退款。这适用于月度和年度会员资格。</li>
                                <li>取消您的会员资格将不会将您从我们的营销数据库中删除。要从我们的营销数据库中删除自己，您必须单击推广电子邮件或短信消息中的退订链接，并按照提示操作。</li>
                                <li>如果您持有忠诚度会员资格，并且因任何原因您的忠诚度会员资格的直接付款未能成功，我们将尝试在初始付款失败后的 15 天内每隔五天再尝试四次扣款。如果在五次尝试后我们仍然无法扣款成功，您的忠诚度会员资格和所有会员福利将被终止。</li>
                                <li>您可以随时重新订阅忠诚度会员资格。如果您在取消忠诚度会员资格后的 30 天内重新订阅，或者您的忠诚度会员资格由我们取消，您将保留您在忠诚度会员资格下拥有的所有先前权益。但是，如果您在取消或终止后的 30 天内没有重新订阅忠诚度会员资格，则您以前作为忠诚度会员所拥有的任何先前权益将不会继续有效。
                                </li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>会员注册奖励</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>我们不时可能提供注册奖励、特别优惠或免费试用期。这些奖励、特别优惠和免费试用期仅在适用于该奖励、特别优惠或免费试用期的条款和条件下有效。</li>
                                <li>如果您在免费试用期结束前未取消免费试用期的注册，则您指定的支付方式将根据上述忠诚度会员条款自动扣除费用，直到您根据这些条款和条件取消会员资格。</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>变更详细信息</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>您应该在更改姓名、地址、联系电话、电子邮件地址或账单信息时通过在线更新您的详细信息通知我们，或通过电子邮件通知我们。</li>
                                <li>如果您未通知我们您的详细信息变更，我们将不承担任何责任，这可能导致您无法获得您的 RCM 会员资格的全部利益。</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>责任</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>我们不排除根据《竞争和消费者法》（2010年）中的商品或服务的任何权利和补救措施，这些权利和补救措施不可被排除、限制或修改。然而，本条款和条件的其余部分将适用于法律允许的最大程度，我们和我们的合作伙伴对于因与 RCM 会员资格相关而遭受的任何损失（包括但不限于间接、特殊或间接损失或利润损失）、费用、损害以及任何个人受伤、疾病或死亡，均不承担责任（包括过失），除非法律无法排除任何责任。</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className='privacy-policy-p-heading'>一般条款和条件</h2>
                            <ul className="list-text-digit list-decimal">
                                <li>根据这些条款和条件，优惠券或折扣率是指我们与每个合作伙伴和经销商安排的折扣率。我们合作伙伴的完整列表可在优惠券/折扣率处获得。</li>
                                <li>我们不对您使用 RCM 平台或从中获得任何利益而产生的任何税务影响负责。</li>
                                <li>如果您：</li>
                            </ul>
                        </div>


                        <div className="mb-6">
                            <ul className="list-text">
                                <li>参与可能危及 RCM 平台正常运作的行为；</li>
                                <li>采取扰乱、讨厌、威胁、滥用或骚扰的方式行事；</li>
                                <li>做出可能损害我们、我们的合作伙伴或任何相关实体或承包商的声誉或名誉的行为；</li>
                                <li>违反与 RCM 平台或您参与我们促销活动相关的任何法律；或</li>
                                <li>以我们合理认为不恰当的方式行事⦁您不得未经我们事先书面同意转让或试图转让这些条款和条件下的任何权利或责任以我们合理认为不恰当的方式行事</li>
                            </ul>
                        </div>

                        <div className="mb-6">

                            <ul className="list-text-digit list-decimal">
                                <li>这些条款和条件中任何明确或因其性质而生存的条款，在这些条款和条件终止或到期后将继续存在并生效。</li>
                                <li>这些条款和条件中对 RM 的所有引用均指马来西亚林吉特。</li>
                                <li>我们可能不时修订这些条款和条件。修订后的条款和条件将自发布在 RCM 网站上或以其他方式通知会员的时间起生效。如果您不愿接受这些条款和条件的任何修订，您可以取消您的 RCM 会员资格。</li>
                                <li>我们可能通过在 RCM 网站上或其他方式通知会员来停止提供会员资格，前提是我们提供足够的通知，以使终止不会给会员带来不合理的财务或其他损害。</li>
                                <li>这些条款和条件受马来西亚法律管辖。最近更新日期：2024年4月11日。</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MembershipTermsAndConditions