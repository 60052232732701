import React from "react";
import "./payment.css";
import { useNavigate } from "react-router-dom";

const PaymentFail = () => {
  const navigate = useNavigate();
  return (
    <div class="w-full h-[80vh] flex justify-center items-center">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div class="message-box _success _failed">
            <i class="fa fa-times-circle" aria-hidden="true"></i>
            <h2> Your payment failed </h2>
            <p> Try again later </p>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            onClick={() => navigate("/member/dashboard")}
            className="bg-primary hover:bg-primary-dark text-white px-10 py-3 rounded-2xl border-none"
          >
            Go Back to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFail;
