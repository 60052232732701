import SignUpForm from "../pages/auth/SignUpForm";

const SignUpPopUp = (props) => {
    const { onClose } = props;

    return (
        <div
            className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
            style={{ zIndex: 9999 }}  // Ensure the z-index is higher than the header's z-index
        >
            <div className="bg-white rounded-lg shadow-md px-5 overflow-auto max-h-full"
                 style={{ maxWidth: '90%', maxHeight: '95%' }}>
                <div className="m-4">
                    <div className="flex justify-end p-2">
                        <button onClick={onClose} className="bg-white text-black font-bold">X</button>
                    </div>
                    <div className="bg-white flex justify-center items-center py-4">
                        <img src="/assets/logo.png" alt="Logo" className="h-12" />
                    </div>
                    <h2 className="text-2xl font-bold mb-4">Become an REWARD CLUB Loyalty Member</h2>
                    <p>Gain Your Name In EVERY Draw Automatically</p>
                </div>
                <div>
                    <SignUpForm />
                </div>
            </div>
        </div>
    );
};

export default SignUpPopUp;
