import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { MerchantContext } from "../../../context/MerchantContext";
import { getRequest } from "../../../utils/helper";
import Datepicker from "react-tailwindcss-datepicker"; // Import Datepicker component
import { toast } from "react-toastify";
import { MemberContext } from "../../../context/MemberContext";
// Utility function to check if two objects are equal
const isEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const EditDiscountModal = ({ id, open, onClose }) => {
  const { user } = useContext(AuthContext);
  const { setSingleDiscountData, updateDiscount } = useContext(MerchantContext);
  const { towns, categories } = useContext(MemberContext);
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(true);

  const getSingleDiscount = async (id) => {
    try {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      const params = {};
      const response = await getRequest(
        `/merchant-discounts/${id}`,
        params,
        headers
      );
      if (response?.data) {
        setLoading(false);
      }
      setSingleDiscountData(response?.data);

      const startDate = new Date(response.data.start_date);
      const endDate = new Date(response.data.end_date);
      setInitialValues({
        ...response.data,
        date_range: {
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
        },
        towns: response.data.towns.map((c) => "" + c.town_id) || [], // Set initial values for towns
        categories:
          response.data.categories.map((c) => "" + c.category_id) || [], // Set initial values for categories
      });
    } catch (error) {
      setLoading(false);
      console.error("Error fetching discount data:", error);
    }
  };

  useEffect(() => {
    open && id && getSingleDiscount(id);
  }, [open, id]);

  const handleSubmit = async (values, { setSubmitting }) => {
    if (!isEqual(values, initialValues)) {
      const formData1 = { ...values };
        
      // Transform date_range value before submission
      const formattedDateRange = `${new Date(
        values.date_range.startDate
      ).toLocaleDateString("en-GB")} - ${new Date(
        values.date_range.endDate
      ).toLocaleDateString("en-GB")}`;
      formData1.date_range = formattedDateRange;
      // console.log("formData1", formData1);

      const formData = new FormData();
        formData.append("date_range", formattedDateRange);
        formData.append("url", values.url);
        formData.append("coupon_code", values.coupon_code);
        formData.append("title_en", values.title_en);
        formData.append("short_description_en", values.short_description_en);
        formData.append("long_description_en", values.long_description_en);
        formData.append("terms_en", values.terms_en);
        formData.append("towns", values.towns);
        formData.append("categories", values.categories);
        formData.append("qr_pic", values.qr_pic);
        formData.append("promo_pic", values.promo_pic);
      try {
        await updateDiscount(id, formData);
        onClose();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
    setSubmitting(false);
  };

  if (open && id && loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg h-auto overflow-y-auto max-h-screen w-full max-w-3xl">
          <div className="flex items-center justify-center h-screen font-bold text-lg">
            Loading...
          </div>
        </div>
      </div>
    );
  }

  const formSchema = [
    { label: "Title (EN):", name: "title_en", type: "text" },
    { label: "Date Range:", name: "date_range", type: "range" }, // Change to single range field
    {
      label: "Short Description (EN):",
      name: "short_description_en",
      type: "text",
    },
    {
      label: "Long Description (EN):",
      name: "long_description_en",
      type: "text",
    },
    { label: "Terms (EN):", name: "terms_en", type: "text" },
    { label: "Coupon Code:", name: "coupon_code", type: "text" },
    { label: "URL:", name: "url", type: "text" },
    {
      label: "Towns:",
      name: "towns",
      type: "select",
      options: towns,
      isMultiple: true,
    },
    {
      label: "Categories:",
      name: "categories",
      type: "select",
      options: categories,
      isMultiple: true,
    },
    {
      label: "QR picture",
      name: "qr_pic",
      type: "file",
    },
    {
      label: "Promo Picture",
      name: "promo_pic",
      type: "file",
    },
  ];

  return (
    open && (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg h-auto overflow-y-auto max-h-screen w-full max-w-3xl">
          <div>
            <div className="flex justify-end p-2">
              <button
                onClick={() => onClose()}
                className="bg-white text-black font-bold"
              >
                X
              </button>
            </div>
          </div>
          <div className="flex justify-center text-center">
            <h2 className="text-xl font-semibold mb-4 ">Edit Discount</h2>
          </div>
          <div
            style={{
              flex: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "5px",
              padding: "10px",
            }}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form className="w-full max-w-3xl">
                  {formSchema?.map((field) => (
                    <div key={field.label} className="mb-2">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 text-left"
                        htmlFor={field.name}
                      >
                        {field.label}
                      </label>
                      {/* Render Datepicker for date_range */}
                      {field.name === "date_range" ? (
                        <Datepicker
                          name={field.name}
                          value={values[field.name]}
                          onChange={(value) => setFieldValue(field.name, value)}
                          showShortcuts={false}
                          configs={{
                            format: "DD/MM/YYYY",
                            layout: "landscape",
                            weekdays: [
                              "Su",
                              "Mo",
                              "Tu",
                              "We",
                              "Th",
                              "Fr",
                              "Sa",
                            ],
                            months: [
                              "January",
                              "February",
                              "March",
                              "April",
                              "May",
                              "June",
                              "July",
                              "August",
                              "September",
                              "October",
                              "November",
                              "December",
                            ],
                          }}
                          containerClassName="!border relative rounded-md border-gray-300"
                          classNames={{
                            wrapper: "relative",
                            container:
                              "absolute top-12 left-0 bg-white shadow-lg rounded-lg p-2",
                            month: "text-lg font-semibold mb-2",
                            weekdayContainer:
                              "flex justify-between border-b border-gray-200 pb-2",
                            weekday: "text-sm text-gray-500 font-medium",
                            dayContainer: "grid grid-cols-7 gap-2",
                            day: "text-sm text-gray-900 rounded-full flex items-center justify-center border border-gray-300",
                            dayDisabled:
                              "text-sm text-gray-300 rounded-full flex items-center justify-center",
                            dayActive: "bg-blue-500 text-white",
                            dayHover: "bg-blue-100",
                            daySelected: "bg-blue-500 text-white",
                          }}
                        />
                      ) : field.isMultiple ? (
                        <Field
                          as="select"
                          name={field.name}
                          multiple
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          value={values[field.name]} // Set the initial selected values
                          onChange={(e) => {
                            const selectedOptions = Array.from(
                              e.target.selectedOptions,
                              (option) => option.value
                            );
                            setFieldValue(field.name, selectedOptions);
                          }}
                        >
                          {field.options?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name_en}
                            </option>
                          ))}
                        </Field>
                      ) : field.type === "file" ? (
                        <div className="mb-4">
                          {values[field.name] && <ImgViewer data={values[field.name]} />}
                          <input
                            type="file"
                            name={field.name}
                            onChange={(event) =>
                              setFieldValue(
                                field.name,
                                event.currentTarget.files[0] || null
                              )
                            }
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                        </div>
                      ) : (
                        <Field
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type={field.type}
                          name={field.name}
                          id={field.name}
                        />
                      )}
                      <ErrorMessage
                        name={field.name}
                        component="div"
                        className="color-primary text-xs italic"
                      />
                    </div>
                  ))}
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="bg-primary hover:bg-primary-dark text-white py-2 px-12 rounded mt-4 mb-4 font-bold justify-center"
                      disabled={isSubmitting || isEqual(values, initialValues)}
                    >
                      SUBMIT
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  );
};

const ImgViewer = ({ data }) => {
  const [url, seturl] = useState();
  if (typeof data === "object") {
    const reader = new FileReader();
    reader.onloadend = () => {
      seturl(reader.result);
    };
    reader.readAsDataURL(data);
    return <img src={url} className="h-[100px]" alt="img" />;
  } else {
    return <img src={data} className="h-[100px]" alt="img" />;
  }
};

export default EditDiscountModal;
