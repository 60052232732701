import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import UserSignup from "../pages/auth/UserSignup";
import { AuthContext } from "../context/AuthContext";
import "./masterdashboardstyle.css";
import { toast } from "react-toastify";
import { GoogleOAuthProvider } from '@react-oauth/google';

const MasterHeader = () => {
  const {
    setLanguage,
    language,
    user,
    Logout,
    isUserSignupModalOpen,
    setIsUserSignupModalOpen,
  } = useContext(AuthContext);
  const translations = require(`../lang/common/common_${language}.json`);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMemberDropdownOpen, setIsMemberDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const toggleProfileDropdown = () =>
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  const profileLink =
    user?.role === "member"
      ? "/member/profile"
      : user?.role === "merchant"
        ? "/merchant/profile"
        : "/";
  const [isOpen, setIsOpen] = useState(false);

  const toggleLangDropdown = () => {
    setIsOpen(!isOpen);
  };

  const openModal = () => {
    setIsUserSignupModalOpen(true);
  };

  const closeModal = () => {
    setIsUserSignupModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMemberDropdown = () => {
    setIsMemberDropdownOpen(!isMemberDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleLanguageChange = (language) => {
    setLanguage(language);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const onLogoHandler = () => {
    if (user?.role === "member") {
      navigate("/member/dashboard");
    } else if (user?.role === "merchant") {
      navigate("/merchant/dashboard");
    } else {
      navigate("/home");
    }
  };

  const [timeoutId, setTimeoutId] = useState(null);
  const dropdownRef = useRef(null);

  // Clear timeout if the mouse re-enters the dropdown area
  const handleMouseEnterDropdown = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsDropdownOpen(true);
  };

  // Clear timeout if the mouse re-enters the parent menu
  const handleMouseEnterMenu = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsDropdownOpen(true);
  };

  // Handle mouse leave on dropdown and menu
  const handleMouseLeave = () => {
    const id = setTimeout(() => setIsDropdownOpen(false), 200);
    setTimeoutId(id);
  };

  return (
    <>
      <header
        className={`${location.pathname.includes("/dashboard")
          ? "header px-5 md:px-5 lg:px-5"
          : "header auto-header border-b-2 px-5 md:px-5 lg:px-5"
          }`}
        style={{ zIndex: "999" }}
      >
        {/* Header content goes here */}
        <div className="container flex items-center justify-between py-4 mx-auto ">
          <div className="flex">
            <div className="mr-12">
              <img
                src="/assets/logo.png"
                onClick={() => onLogoHandler()}
                alt="Reward Club"
                className="w-auto h-12"
              />
            </div>

            {/* Member */}
            <nav className="hidden md:flex navbar-links">
              {user?.role === "member" && (
                <ul className="flex items-center space-x-4">
                  {/* <li><NavLink to="/home">{translations.header.home}</NavLink></li> */}
                  <li>
                    <NavLink to="/member/dashboard">Dashboard</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/member/mySubscription">
                      My Subscription
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/discount">
                      {translations.header.discount}
                    </NavLink>
                  </li>
                  <li className="relative">
                    <button
                      onMouseEnter={handleMouseEnterMenu}
                      onMouseLeave={handleMouseLeave}
                      className="hover:text-gray-400 focus:outline-none navbar-links-button"
                    >
                      {translations.header.giveaways}
                      <i className={`fa fa-chevron-down ml-1 ${isDropdownOpen ? "transform rotate-180" : ""}`}></i>
                    </button>
                    {isDropdownOpen && (
                      <div
                        ref={dropdownRef}
                        className="absolute left-0 z-10 w-56 mt-2 bg-white rounded-lg shadow-lg top-full navbar-dropdown-container"
                        onMouseEnter={handleMouseEnterDropdown}
                        onMouseLeave={handleMouseLeave}
                      >
                        <ul className="py-2">
                          <li>
                            <NavLink
                              to="/upcoming-members-giveaways"
                              className="block p-3 text-gray-800"
                            >
                              {translations.header.upcomingMembersGiveaways}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/member-draw-giveaways"
                              className="block p-3 text-gray-800"
                            >
                              {translations.header.memberDrawWinners}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                  {/* <li>
                    <NavLink to="/member/upcommingGiveaways">
                      Upcoming GA
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/member/myJoinedGiveaways">
                      My Joined GA
                    </NavLink>
                  </li>
                  <li>
                      <NavLink target="_blank" to="https://store.rewardclub.my/">
                        {translations.header.store}
                      </NavLink>
                  </li>
                  <li>
                    {user ? (
                      <>
                        {/* <button onClick={Logout}
                                                    className="px-4 py-2 text-white rounded bg-primary header-signup-button">{translations.header.logout}
                                                </button> */}
                      </>
                    ) : (
                      <>
                        <button
                          onClick={openModal}
                          className="px-4 py-2 text-white rounded bg-primary header-signup-button"
                        >
                          {translations.header.signUp}
                        </button>
                      </>
                    )}
                  </li>
                </ul>
              )}
              {user?.role === "merchant" && (
                <ul className="flex items-center space-x-4">
                  {/* <li><NavLink to="/home">{translations.header.home}</NavLink></li> */}
                  <li>
                    <NavLink to="/merchant/dashboard">Dashboard</NavLink>
                  </li>
                  <li>
                    <NavLink to="/discount">
                      {translations.header.discount}
                    </NavLink>
                  </li>
                  {/* <li className="relative">
                    <button
                      onClick={toggleDropdown}
                      className="hover:text-gray-400 focus:outline-none navbar-links-button"
                    >
                      {translations.header.giveaways}
                      <i className={`fa fa-chevron-down ml-1 ${isDropdownOpen ? "transform rotate-180" : ""}`}></i>
                    </button>
                    {isDropdownOpen && (
                      <div className="absolute left-0 z-10 w-56 mt-2 bg-white rounded-lg shadow-lg top-full navbar-dropdown-container">
                        <ul className="py-2">
                          <li>
                            <NavLink
                              to="/upcoming-members-giveaways"
                              className="block p-3 text-gray-800"
                            >
                              {translations.header.upcomingMembersGiveaways}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/member-draw-giveaways"
                              className="block p-3 text-gray-800"
                            >
                              {translations.header.memberDrawWinners}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li> */}
                  {/* <li>
                    <NavLink to="/merchant/myDiscount">My Discount</NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink to="/merchant/myJoinedGiveaways">
                      My Joined GA
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/merchant/mySubscription">
                      My Subscription
                    </NavLink>
                  </li>
                  <li>
                      <NavLink target="_blank" to="https://store.rewardclub.my/">
                        {translations.header.store}
                      </NavLink>
                  </li>
                  <li>
                    {user ? (
                      <>
                        {/* <button onClick={Logout}
                                                    className="px-4 py-2 text-white rounded bg-primary header-signup-button">{translations.header.logout}
                                                </button> */}
                      </>
                    ) : (
                      <>
                        <button
                          onClick={openModal}
                          className="px-4 py-2 text-white rounded bg-primary header-signup-button"
                        >
                          {translations.header.signUp}
                        </button>
                      </>
                    )}
                  </li>
                </ul>
              )}
            </nav>
          </div>

          <nav className="hidden md:flex navbar-links">
            <ul className="flex items-center space-x-6 user-icons-navbar">
              {/* <li>
                <i
                  className={`fa fa-search ml-4 text-white text-decoration-none`}
                ></i>
              </li> */}
              {/* User Icon */}
              <li className="relative">
                {user && (
                  <i
                    onClick={toggleProfileDropdown}
                    className={`${location.pathname.includes("/dashboard")
                      ? "text-white"
                      : "text-white"
                      } fa fa-user w-4 h-4 text-[1rem] ml-4 text-decoration-none cursor-pointer`}
                  ></i>
                )}
                {isProfileDropdownOpen && (
                  <div
                    className="absolute right-0 mt-2 min-w-80 bg-white border rounded shadow-lg z-[999]"
                    style={{ width: "max-content" }}
                  >
                    <div className="flex items-center justify-between px-4 py-2 border-b-2">
                      <div className="flex items-center space-x-4 overflow-hidden">
                        <img
                          className="w-10 h-10 rounded-full"
                          src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                            user?.name || ""
                          )}`}
                          alt=""
                        />
                        <div className="flex flex-col">
                          <span className="font-medium text-black">
                            Welcome {user?.name}
                          </span>
                          <span className="text-sm text-gray-500">
                            {user?.email}
                          </span>
                        </div>
                      </div>
                    </div>
                    <ul className="py-2">
                      <li>
                        <NavLink
                          to={profileLink}
                          onClick={toggleProfileDropdown}
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                        >
                          Edit Profile
                        </NavLink>
                      </li>
                      <li
                        onClick={() => {
                          Logout();
                          toggleProfileDropdown();
                        }}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                      >
                        {translations.header.logout}
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              <div className="relative items-center inline-block text-left">
                <img
                  onClick={toggleLangDropdown}
                  src={`/assets/flags/${language}.svg`}
                  alt=""
                  className="inline w-4 h-4 mb-1 cursor-pointer"
                />

                {isOpen && (
                  <div
                    className="absolute right-0 mt-2 origin-top-right bg-white rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div className="py-1" role="none">
                      <button
                        onClick={() => {
                          handleLanguageChange("en");
                          toggleLangDropdown();
                        }}
                        className={`block px-4 py-2 text-sm ${language === "en"
                          ? "bg-blue-800 text-white"
                          : "hover:bg-gray-100 text-gray-700 hover:text-gray-900"
                          } w-full text-left`}
                        role="menuitem"
                      >
                        <img
                          src="/assets/flags/en.svg"
                          alt=""
                          className="inline w-4 h-4 mr-4"
                        />
                        {translations.header.English}
                      </button>
                      <button
                        onClick={() => {
                          handleLanguageChange("ms");
                          toggleLangDropdown();
                        }}
                        className={`block px-4 py-2 text-sm ${language === "ms"
                          ? "bg-blue-800 text-white"
                          : "hover:bg-gray-100 text-gray-700 hover:text-gray-900"
                          } w-full text-left`}
                        role="menuitem"
                      >
                        <img
                          src="/assets/flags/ms.svg"
                          alt=""
                          className="inline w-4 h-4 mr-4"
                        />
                        {translations.header.Malay}
                      </button>
                      <button
                        onClick={() => {
                          handleLanguageChange("zh");
                          toggleLangDropdown();
                        }}
                        className={`block px-4 py-2 text-sm ${language === "zh"
                          ? "bg-blue-800 text-white"
                          : "hover:bg-gray-100 text-gray-700 hover:text-gray-900"
                          } w-full text-left`}
                        role="menuitem"
                      >
                        <img
                          src="/assets/flags/zh.svg"
                          alt=""
                          className="inline w-4 h-4 mr-4"
                        />
                        {translations.header.Chinese}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </ul>
          </nav>

          <div className="md:hidden">
            <button onClick={toggleMobileMenu} className="focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
          {isMobileMenuOpen && (
            <div className="fixed top-0 left-0 bg-black h-screen w-full z-[999] flex justify-center items-center">
              <div className="text-left md:hidden">
                <button
                  onClick={toggleMobileMenu}
                  className="absolute focus:outline-none top-4 right-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <ul className="px-3 py-2 text-white">
                  {/* <li><Link to="/home">Home</Link></li>
                            <hr className="w-full my-2 border-gray-400" /> Increased width */}
                  <li className="text-white hover:text-gray-400">
                    {translations.header.mobileHeaderText}
                  </li>
                  <li className="my-2">&nbsp;</li>
                  {/* Blank space */}
                  {/* <li>
                    <NavLink to="/home" onClick={closeMobileMenu}>
                      {translations.header.home}
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink
                      to={
                        user?.role === "member"
                          ? "/member/dashboard"
                          : "/merchant/dashboard"
                      }
                      onClick={closeMobileMenu}
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <hr className="w-full my-2 border-gray-400" />

                  {/* {user?.role === "member" && (
                    <li>
                      <NavLink
                        to="member/upcommingGiveaways"
                        onClick={closeMobileMenu}
                      >
                        Upcoming GA
                      </NavLink>
                    </li>
                  )}
                  {user?.role === "member" && (
                    <hr className="w-full my-2 border-gray-400" />
                  )} */}

                  {/* {user?.role === "merchant" && (
                    <li>
                      <NavLink
                        to="merchant/myDiscount"
                        onClick={closeMobileMenu}
                      >
                        My Discounts
                      </NavLink>
                    </li>
                  )}
                  {user?.role === "merchant" && (
                    <hr className="w-full my-2 border-gray-400" />
                  )} */}

                  <li>
                    <NavLink to="/discount" onClick={closeMobileMenu}>
                      {translations.header.discount}
                    </NavLink>
                  </li>
                  <hr className="w-full my-2 border-gray-400" />

                  <li
                    className="flex items-center justify-between cursor-pointer"
                    onClick={toggleDropdown}
                  >
                    <button className="hover:text-gray-400 focus:outline-none">
                      {translations.header.giveaways}
                    </button>
                    <i className={`fa fa-chevron-down ml-1 h-3 text-[12px] w-3 text-white ${isDropdownOpen ? "transform rotate-180" : ""}`}></i>
                  </li>
                  <hr className="w-full my-2 border-gray-400" />
                  {isDropdownOpen && (
                    <li>
                      <NavLink
                        to="/upcoming-members-giveaways"
                        onClick={closeMobileMenu}
                      >
                        &nbsp;&nbsp;&nbsp;{translations.header.upcomingMembersGiveaways}
                      </NavLink>
                    </li>
                  )}
                  {isDropdownOpen && (
                    <hr className="w-full my-2 border-gray-400" />
                  )}
                  {isDropdownOpen && (
                    <li>
                      <NavLink
                        to="/member-draw-giveaways"
                        onClick={closeMobileMenu}
                      >
                        &nbsp;&nbsp;&nbsp;{translations.header.memberDrawWinners}
                      </NavLink>
                    </li>
                  )}

                  {user?.role === "member" && (
                    <>
                    <li>
                      <NavLink
                        to="member/myJoinedGiveaways"
                        onClick={closeMobileMenu}
                      >
                        My Joined GA
                      </NavLink>
                    </li>
                    </>
                  )}
                  {user?.role === "member" && (
                    <hr className="w-full my-2 border-gray-400" />
                  )}
                  <li>
                      <NavLink target="_blank" to="https://store.rewardclub.my/">
                        {translations.header.store}
                      </NavLink>
                  </li>
                  <hr className="w-full my-2 border-gray-400" />
                  <li>
                    <button
                      onClick={() => {
                        Logout();
                        closeMobileMenu();
                      }}
                    >
                      Logout
                    </button>
                  </li>
                  <hr className="w-full my-2 border-gray-400" />

                  <li className="my-2">&nbsp;</li>
                  {/* Blank space */}
                  {/* Add social icons */}
                  <div className="flex items-center justify-end space-x-4">
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className="w-6 mr-2 text-white"
                      />
                    </a>
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        className="w-6 mr-2 text-white"
                      />
                    </a>
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faYoutube}
                        className="w-6 mr-2 text-white"
                      />
                    </a>
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="w-6 mr-2 text-white"
                      />
                    </a>
                  </div>
                  {/* End of social icons */}
                </ul>
              </div>
            </div>
          )}
        </div>
        {location.pathname.includes("/dashboard") && (
          <div className="row">
            <div className="container flex items-center justify-between py-4 mx-auto md:mt-2">
              <div className="flex items-center space-x-4">
                <img
                  className="w-12 h-12 font-bold rounded-full"
                  src={user?.image || `https://ui-avatars.com/api/?name=${encodeURIComponent(
                    user?.name || ""
                  )}`}
                  alt=""
                />
                <div className="flex flex-col">
                  <span className="font-large">Welcome {user?.name}</span>
                  <span className="text-sm text-white">{user?.email}</span>
                </div>
              </div>
            </div>

            {user?.role === "merchant" &&
              <div className="container flex items-center justify-between mx-auto mt-2 md:py-4">
                <div>
                  <div className="font-semibold">Referral Link</div>
                  <div className="flex flex-col items-start md:flex-row">
                    {/* <div className="cursor-pointer" onClick={() => {
                      navigator.clipboard.writeText(user?.referral_url);
                      toast.success("Referral link copied to clipboard");
                    }}>{user?.referral_url}</div> */}
                    <button onClick={() => {
                      navigator.clipboard.writeText(user?.referral_url);
                      toast.success("Referral link copied to clipboard");
                    }} className="px-2 py-1 text-black bg-white rounded">Copy Referral Link</button>
                  </div>
                </div>

                <div>
                  <div className="font-semibold">Referral Points</div>
                  <div className="flex flex-col items-end md:flex-row">{user?.referral_point}</div>
                </div>
              </div>
            }
          </div>
        )}
      </header>
      {isUserSignupModalOpen && (
        <GoogleOAuthProvider clientId="1066471456951-jvb9nma0ab3rglfv4v7vciam8qd8ker0.apps.googleusercontent.com">
          <UserSignup
            isUserSignupModalOpen={isUserSignupModalOpen}
            setIsUserSignupModalOpen={setIsUserSignupModalOpen}
          />
        </GoogleOAuthProvider>
      )}
    </>
  );
};

export default MasterHeader;
