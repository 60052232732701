import React, { useContext, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Datepicker from 'react-tailwindcss-datepicker';
import { MerchantContext } from "../../../context/MerchantContext";
import { AuthContext } from '../../../context/AuthContext';
import { MemberContext } from '../../../context/MemberContext';
import moment from 'moment';

// Custom Date Range Picker Component
const DateRangePicker = ({ name }) => {
    const { setFieldValue } = useFormikContext();
    const [value, setValue] = useState({
        startDate: null,
        endDate: null,
    });

    const handleValueChange = (newValue) => {
        setValue(newValue);
        // Convert startDate and endDate to moment.js objects and then format
        setFieldValue(
            name,
            [newValue.startDate, newValue.endDate].map(date => moment(date).format('DD/MM/YYYY'))
        );
    };

    return (
        <Datepicker
            value={value}
            onChange={handleValueChange}
            showShortcuts={false}
            configs={{
                format: "DD/MM/YYYY",
                layout: "landscape",
                weekdays: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
                months: [
                    "January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ]
            }}
            containerClassName="!border relative rounded-md border-gray-300"
            classNames={{
                wrapper: "relative",
                container: "absolute top-12 left-0 bg-white shadow-lg rounded-lg p-2",
                month: "text-lg font-semibold mb-2",
                weekdayContainer: "flex justify-between border-b border-gray-200 pb-2",
                weekday: "text-sm text-gray-500 font-medium",
                dayContainer: "grid grid-cols-7 gap-2",
                day: "text-sm text-gray-900 rounded-full flex items-center justify-center border border-gray-300", // Add border class here
                dayDisabled: "text-sm text-gray-300 rounded-full flex items-center justify-center",
                dayActive: "bg-blue-500 text-white",
                dayHover: "bg-blue-100",
                daySelected: "bg-blue-500 text-white",
            }}
        />

    );
};

const CreateDiscount = ({ isModalOpen, handleCloseModal, updateDiscountList, discountList }) => {
    const { createDiscount, getMerchantDiscount } = useContext(MerchantContext);
    const { language } = useContext(AuthContext);
    const { towns, categories } = useContext(MemberContext);
    const initialValues = {
        date_range: [],
        url: '',
        coupon_code: '',
        qr_pic: null,
        promo_pic: null,
        title_en: '',
        // title_ms: '',
        // title_zh: '',
        short_description_en: '',
        // short_description_ms: '',
        // short_description_zh: '',
        long_description_en: '',
        // long_description_ms: '',
        // long_description_zh: '',
        terms_en: '',
        // terms_ms: '',
        // terms_zh: '',
        towns: [],
        categories: [],
    };

    const validationSchema = Yup.object({
        date_range: Yup.array().min(1, 'Required').required('Required'),
        url: Yup.string().url('Invalid URL').required('Required'),
        coupon_code: Yup.string().required('Required'),
        qr_pic: Yup.mixed().nullable(),
        promo_pic: Yup.mixed().nullable(),
        title_en: Yup.string().required('Required'),
        // title_ms: Yup.string().required('Required'),
        // title_zh: Yup.string().required('Required'),
        short_description_en: Yup.string().required('Required'),
        // short_description_ms: Yup.string().required('Required'),
        // short_description_zh: Yup.string().required('Required'),
        long_description_en: Yup.string().required('Required'),
        // long_description_ms: Yup.string().required('Required'),
        // long_description_zh: Yup.string().required('Required'),
        terms_en: Yup.string().required('Required'),
        // terms_ms: Yup.string().required('Required'),
        // terms_zh: Yup.string().required('Required'),
        towns: Yup.array().min(1, 'At least one city must be selected').required('Required'),
        categories: Yup.array().min(1, 'At least one category must be selected').required('Required'),
    });

    const handleSubmit = async (values) => {
        try {
            // Format the date range as required
            const formattedDateRange = values.date_range.join(' - ');

            // Update the values with the formatted date range
            const updatedValues = { ...values, date_range: formattedDateRange };

            const formData = new FormData();
            Object.entries(updatedValues).forEach(([key, value]) => {
              formData.append(key, value);
            });

            const createdDiscount = await createDiscount(formData); 
            // await createDiscount(updatedValues); // Create the discount
            if (createdDiscount) {
                updateDiscountList(prevList => [...prevList, createdDiscount]); // Update the discount list in the component
                handleCloseModal(); // Close the modal
              }
        } catch (error) {
            console.error('Error creating discount:', error);
        }
    };

    const renderField = (name, label, className = "") => (
        <div className={`mb-4 ${className}`}>
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <Field type="text" name={name} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
            <ErrorMessage name={name} component="div" className="text-red-500 text-sm" />
        </div>
    );


    return (
        <>
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg h-auto overflow-y-auto max-h-screen w-full max-w-3xl">
                        <div>
                            <div className="flex justify-end p-2">
                                <button onClick={handleCloseModal} className="bg-white text-black font-bold">X</button>
                            </div>
                        </div>
                        <h2 className="text-xl font-semibold mb-4">Create Discount</h2>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ setFieldValue }) => (
                                <Form>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">Date Range</label>
                                        <DateRangePicker name="date_range" />
                                        <ErrorMessage name="date_range" component="div" className="text-red-500 text-sm" />
                                    </div>
                                    {renderField("url", "URL")}
                                    {renderField("coupon_code", "Coupon Code")}
                                    {renderField("title_en", "Title")}
                                    {renderField("short_description_en", "Short Description")}
                                    {renderField("long_description_en", "Long Description")}
                                    {renderField("terms_en", "Terms")}
                                    {/* <div className="flex flex-wrap -mx-2">
                                        <div className="w-1/3 px-2">
                                            {renderField("title_en", "Title (EN)")}
                                        </div>
                                        <div className="w-1/3 px-2">
                                            {renderField("title_ms", "Title (MS)")}
                                        </div>
                                        <div className="w-1/3 px-2">
                                            {renderField("title_zh", "Title (ZH)")}
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-2">
                                        <div className="w-1/3 px-2">
                                            {renderField("short_description_en", "Short Description (EN)")}
                                        </div>
                                        <div className="w-1/3 px-2">
                                            {renderField("short_description_ms", "Short Description (MS)")}
                                        </div>
                                        <div className="w-1/3 px-2">
                                            {renderField("short_description_zh", "Short Description (ZH)")}
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-2">
                                        <div className="w-1/3 px-2">
                                            {renderField("long_description_en", "Long Description (EN)")}
                                        </div>
                                        <div className="w-1/3 px-2">
                                            {renderField("long_description_ms", "Long Description (MS)")}
                                        </div>
                                        <div className="w-1/3 px-2">
                                            {renderField("long_description_zh", "Long Description (ZH)")}
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-2">
                                        <div className="w-1/3 px-2">
                                            {renderField("terms_en", "Terms (EN)")}
                                        </div>
                                        <div className="w-1/3 px-2">
                                            {renderField("terms_ms", "Terms (MS)")}
                                        </div>
                                        <div className="w-1/3 px-2">
                                            {renderField("terms_zh", "Terms (ZH)")}
                                        </div>
                                    </div> */}
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">QR Picture</label>
                                        <input type="file" name="qr_pic" onChange={(event) => setFieldValue("qr_pic", event.currentTarget.files[0] || null)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                                        <ErrorMessage name="qr_pic" component="div" className="text-red-500 text-sm" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">Promo Picture</label>
                                        <input type="file" name="promo_pic" onChange={(event) => setFieldValue("promo_pic", event.currentTarget.files[0] || null)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                                        <ErrorMessage name="promo_pic" component="div" className="text-red-500 text-sm" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">Towns</label>
                                        <Field as="select" name="towns" multiple className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                            {towns?.map(town => (
                                                <option key={town.id} value={town.id}>
                                                    {town[`name_${language}`]}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="towns" component="div" className="text-red-500 text-sm" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">Categories</label>
                                        <Field as="select" name="categories" multiple className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                            {categories?.map(category => (
                                                <option key={category.id} value={category.id}>
                                                    {category[`name_${language}`]}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="categories" component="div" className="text-red-500 text-sm" />
                                    </div>
                                    <div className="flex justify-end mb-4">
                                        <button
                                            type="button"
                                            className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                                            onClick={handleCloseModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateDiscount;
