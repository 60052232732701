import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';

const CurrentDateTime = (props) => {
    const { date } = props;
    const startDate = useMemo(() => {
        return date ? moment(date, "DD/MM/YYYY hh:mm A").toDate() : new Date();
    }, [date]);
    const getCurrentDateTime = (startTime) => {
        const now = new Date();
        const remainingTime = startTime - now;

        const day = Math.floor(remainingTime / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
        const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000).toString().padStart(2, '0');

        return { day, hours, minutes, seconds };
    };

    const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime(startDate));

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(getCurrentDateTime(startDate));
        }, 1000);

        return () => clearInterval(interval);
    }, [startDate]);

    return (
        <div className="bg-primary text-white text-xl font-bold p-4">
            <div className="flex justify-center">
                <div className="mr-4">Day</div>
                <div className="mr-4">Hrs</div>
                <div className="mr-4">Min</div>
                <div>Sec</div>
            </div>
            <div className="flex justify-center gap-4">
                <div className="mr-4">{currentDateTime.day}</div>
                <div className="mr-4">{currentDateTime.hours}</div>
                <div className="mr-4">{currentDateTime.minutes}</div>
                <div>{currentDateTime.seconds}</div>
            </div>
        </div>
    );
};

export default CurrentDateTime;
