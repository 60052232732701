import React, { createContext, useContext, useEffect, useState } from "react";
import { getRequest, postRequest } from "../utils/helper";
import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

export const MemberContext = createContext();

export const MemberProvider = ({ children }) => {
  const [member, setMember] = useState(null);
  const [faqsData, setFaqsData] = useState([]);
  const [towns, setTowns] = useState([]);
  const [categories, setCategories] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [giveaways, setGiveaways] = useState([]);
  const [myJoinedGiveaways, setMyJoinedGiveaways] = useState([]);
  const [pastGiveawaysWinners, setPastGiveawaysWinners] = useState([]);
  const { user, refreshUser } = useContext(AuthContext);
  const [plans, setPlans] = useState([]);
  const [articles, setArticles] = useState([]);
  
  // Function to fetch FAQs data
  const getFaqsData = async () => {
    try {
      const headers = {};
      const response = await getRequest("/faqs", {}, headers);
      setFaqsData(response.data); // Store the fetched FAQs data in state
    } catch (error) {
      console.error("Error fetching FAQs data:", error);
    }
  };
  
  // Function to fetch FAQs data
  const getArticles = async () => {
    try {
      const headers = {};
      const response = await getRequest("/articles", {}, headers);
      setArticles(response.data); // Store the fetched Articles data in state
    } catch (error) {
      console.error("Error fetching Articles data:", error);
    }
  };

  // Function to fetch Towns data
  const getTowns = async () => {
    try {
      const headers = {};
      const response = await getRequest("/towns", {}, headers);
      setTowns(response.data); // Store the fetched Towns data in state
    } catch (error) {
      console.error("Error fetching Towns data:", error);
    }
  };

  // Function to fetch Categories data
  const getCategories = async () => {
    try {
      const headers = {};
      const response = await getRequest("/categories", {}, headers);
      setCategories(response.data); // Store the fetched Categories data in state
    } catch (error) {
      console.error("Error fetching Categories data:", error);
    }
  };

  // Function to fetch Discounts data
  const getDiscount = async () => {
    try {
      const headers = {};
      const response = await getRequest("/discounts", {}, headers);
      setDiscounts(response.data); // Store the fetched Discounts data in state
    } catch (error) {
      console.error("Error fetching Discounts data:", error);
    }
  };

  // Function to fetch Giveaways data
  const getGiveaways = async () => {
    try {
      const headers = {};
      const response = await getRequest("/giveaways?type=upcoming", {}, headers);
      setGiveaways(response.data); // Store the fetched Giveaways data in state
    } catch (error) {
      console.error("Error fetching Giveaways data:", error);
    }
  };

  // Function to fetch Past Giveaways data
  const getPastGiveawaysWinners = async () => {
    try {
      const headers = {};
      const response = await getRequest("/past-giveaways", {}, headers);
      setPastGiveawaysWinners(response.data); // Store the fetched Past Giveaways data in state
    } catch (error) {
      console.error("Error fetching Past Giveaways data:", error);
    }
  };

  // Function to fetch Plans data
  const getPlans = async () => {
    try {
      const headers = {};
      const response = await getRequest("/plans", {}, headers);
      setPlans(response.data); // Store the fetched Plans data in state
    } catch (error) {
      console.error("Error fetching Plans data:", error);
    }
  };

  // Function to join giveaways
  const joinGiveaways = async (data) => {
    try {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      const response = await postRequest("/giveaway-join", data, headers);
      if(response.data.success === 'Joined Successfully!'){
        toast.success("Giveaway Joined Successfully!");
      }else{
        toast.error("Something went wrong, try again later!");
      }
    } catch (error) {
      console.error("Error join giveaways:", error);
      toast.error(error.data.errors);

    }
  };

  // Function to get joined giveaways
  const getjoinedGiveaways = async (data) => {
    try {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      if (user) {
        const response = await getRequest(
          "/my-joined-giveaways",
          data,
          headers
        );
        setMyJoinedGiveaways(response?.data);
      }
    } catch (error) {
      console.error("Error join giveaways:", error);
    }
  };

  const subScribePlan = async (data) => {
    // data -> planId
    try {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      if (user) {
        const response = await postRequest("/subscribe", data, headers);
        if(response.url){
          window.open(response.url, "_self");
        }else{
          refreshUser();
          toast.success("Plan Subscribed Successfully");
        }
      }
    } catch (error) {
      console.error("Error join giveaways:", error);
    }
  };

  const cancelPlan = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${user?.token}`,
        Accept: "application/json",
        ContentType: "application/json",
      };
      const response = await postRequest("/cancel", undefined, headers);
      response.status === "success" && refreshUser();
      response.status === "success" && toast.success("Plan Cancelled Successfully");
    } catch (error) {
      console.error("Error join giveaways:", error);
    }
  };

  useEffect(() => {
    getFaqsData();
    getTowns();
    getCategories();
    getDiscount();
    getGiveaways();
    getPastGiveawaysWinners();
    getPlans();
    getArticles();
  }, []);

  useEffect(() => {
    getjoinedGiveaways();
  }, [user]);

  return (
    <MemberContext.Provider
      value={{
        member,
        setMember,
        faqsData,
        towns,
        categories,
        discounts,
        setDiscounts,
        giveaways,
        pastGiveawaysWinners,
        plans,
        articles,
        joinGiveaways,
        myJoinedGiveaways,
        cancelPlan,
        subScribePlan,
        getjoinedGiveaways
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};
